import {  Button, Avatar, Tooltip, Select, Divider, Dropdown, Image } from "antd";
import {  Modal, notification } from "antd";
import { CiUser } from "react-icons/ci";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams, useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import _ from 'lodash';
import moment from 'moment-timezone';
import { GoogleLogin } from '@react-oauth/google';
import { CiMobile3 } from "react-icons/ci";

// import { googleLogout } from '@react-oauth/google';
import { BellFilled, BellOutlined, DownOutlined } from "@ant-design/icons";
import chatkitty from "../../core_units/chat-system/chatkitty";
import ChatHome from "../../core_units/chat-system/components/home/index";
import "./header.css";
import EmailLogin from "../../containers/email-login";
import LoginWithPhone from '../../containers/sticky-container/login-with-phone';

const { Option } = Select;
const PageHeader = (props) => {
    let history = useNavigate();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [menuVisible, setmenuVisible] = useState(false)
    const [userProfile, setuserProfile] = useState({})
    const [schoolDetailsView, setschoolDetailsView] = useState({})
    const [userProfileMeta, setuserProfileMeta] = useState({})
    const [school_photo, setschool_photo] = useState(null);
    const [getId, setId] = useState("");
    const location = useLocation();
    const [getPhoto, setPhoto] = useState();
    const [getSecondHost, setSecondHost] = useState();
    const [string, setString] = useState(null)
    const [hostDetails, setHostDetails] = useState({});
    const [getHostData, setHostData] = useState({});
    const [getExperienceList, setExperienceList] = useState([]);
    const [listItem, setlistItem] = useState([])
    const [getTempData, setTempData] = useState({});
    const [getHostVal, setHostVal] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [emailLoginOpen, setemailLoginOpen] = useState({visible:false,form:1})
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataFromChild, setDataFromChild] = useState(false);
    const [currentLocalISOString] = useState( moment.tz(new Date(),"America/Toronto").toISOString());
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Call handler right away so state gets updated with initial window size
  
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  

    const ToggleMenu = () => {
        setmenuVisible(!menuVisible)
    };

    useEffect(() => {
      // Get the current URL
      const currentUrl = window.location.href;
  
      // Check if the URL matches the expected pattern
      if (currentUrl.match(/\/[a-zA-Z0-9_]+$/)) {
        // Extract the last part of the URL (after the last '/')
        const lastPart = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);
  
        // Log the result to the console (you can use it as needed)
        
        localStorage.setItem('coach_handle', lastPart)
      } else {
        
      }
    }, []);
    const logout = () => {

      props.logout({
        callback: (response, data) => {
            // googleLogout();
            
            // if(searchParams.get("id")){
              history(`/${searchParams.get('handle')}`);
                localStorage.clear();
                window.location.reload(false);
            // }
            
        }
    })
        
    }
    // useEffect(() => {
    //     getProfile()
    // }, [props.hostProfileData])

    useEffect(() => {
        if (_.get(props, 'checkUpdate') == true) {
            if (_.get(props, 'islogindata.isLoggedIn') === true) {
                setuserProfile({})
                
                   getProfile()
               
            }

        }

    }, [_.get(props, 'checkUpdate')]);

    useEffect(() => {
        // Get the parameter value from the URL
        const url = new URL(window.location.href);
        const id = url.pathname.split('/').pop();
        setId(id)
        
        viewTemplate();
        viewExperience();
        setTimeout(() => {
          getHost();
        }, 2000);
       
        
      }, []);

    


    const getProfile = async () => {
        let conditionType = _.includes(_.get(props,'islogindata.types'),0)==true?'guestProfileData':'hostProfileData';
        props[conditionType]({
            params:{expand:'invite_code'},
           
            callback: async (res, data) => {
                let displayPictureUrl=`${_.get(data, 'data.meta.photo.path')}/${_.get(data, 'data.meta.photo.folder')}/${_.get(data, 'data.meta.photo.sizes.medium')}/${_.get(data, 'data.details.photo')}` 
                
                
               chatkitty.initialyze({},{..._.get(data, 'data.details'),displayPictureUrl})
                
                if (res == true && _.get(data, 'data.details.id')) {
                    await setuserProfile(_.get(data, 'data.details'))
                    await setuserProfileMeta(_.get(data, 'data.meta'))
                    setmenuVisible(true)
                }
            }
        })
    };

    const redirectSocialLogin = () => {
      
        // history(`/social-login/?id=${getId}`)
    }

    const viewTemplate = () => {

        const currentURLxcd = window.location.href;
        const partssdf = currentURLxcd.split('/');
        const extractedString = partssdf[partssdf.length - 1];
        
                if (extractedString) {
                  
                  setString(extractedString)
                props.experienceListAPI({
                  params:{
                    status: 1,
                    page: 1,
                    search_type: 0,
                    expand: 'host,template,template_photos',
                    group_by: 'template',
                    client_timezone: "America/Toronto",
                    is_slots_available:1,
                    is_price_porated:1,
                    host_handle: searchParams.get('handle') ? searchParams.get('handle') : extractedString,
                    min_experience_date: currentLocalISOString,
                    min_experience_time: _.chain(currentLocalISOString).split('T').last().value(),
                  },
                  callback: (r, v) => {
                    
                    if(_.size(_.get(v, 'data.items')) > 0){
                        // const text = <span>prompt text</span>;
                       setHostDetails(_.get(v, 'data.items[0].host'))
                       
                       setExperienceList(_.get(v, 'data.items'));
                       if(_.size(_.get(v, 'data.items'))>0){
                        let listExperience =[]
                        _.map(_.get(v, 'data.items'),(item,i)=>{
                          
                          listExperience.push({
                            key:   _.get(item, 'id'),
                            label: 
                            <div onClick={()=>{
                              // handleChange(_.get(item, 'id'),_.get(v, 'data.items'),searchParams.get('handle'))
                            }} className='date-select-class-option' style={{ borderBottom:i+1==_.size(_.get(v, 'data.items'))?"": '1px solid #EAECF0',paddingTop:'9px',paddingBottom:'9px' }}>
                            {_.get(item, 'template.title')}
                          </div>
                          
                          })

                        })
                        setlistItem(listExperience)
                       }
                    }
                  }
                }) 
              } else {
                
              }
        };

        const viewExperience = () => {
            const currentURLxcd = window.location.href;
        const partssdf = currentURLxcd.split('/');
        const extractedString = partssdf[partssdf.length - 1];
        
        props.viewSessionAPI({
            params: {
                id: searchParams.get('id'),
                expand: "template,template_photos,host",
                is_price_porated: 1
              },

              callback:(res, v) => {
                
                if(!_.isEmpty(_.get(v, 'data.details'))){
                  setTempData(v)
                    setHostDetails(_.get(v, 'data.details.host'));
                }
                if(!_.isEmpty(_.get(v, 'data.details.host'))){
                  props.getHostDetailsAPI({
                    params:{
                      handle: _.get(v, 'data.details.host.handle')
                    },
                    callback:(res, d) => {
                      
                      if(d){
                        const uniqueIdentifier = new Date().getTime(); 
                        setSecondHost(`${_.get(d, 'data.meta.photo.path')}/${_.get(d, 'data.meta.photo.folder')}/${_.get(d, 'data.meta.photo.sizes.medium')}/${_.get(d, 'data.details.meta_details.logo')}?v=${uniqueIdentifier}`);
                      }
                      
                    }
                  })
                }
              }
        })
        }

        
            const text = <span>{`Club/Coach: ${_.startCase(_.get(getHostData, 'details.first_name'))} ${_.startCase(_.get(getHostData, 'details.last_name'))}`}</span>

            const getHost = () => {
              const currentURLxcd = window.location.href;
              const partssdf = currentURLxcd.split('/');
              const extractedString = partssdf[partssdf.length - 1];
              props.getHostDetailsAPI({
                params :{
                  handle: _.get(getTempData, 'data.details.host.handle') ? _.get(getTempData, 'data.details.host.handle') : searchParams.get('handle') ? searchParams.get('handle') : extractedString
                },
                callback: (response, viewData) => {
                  console.log('akjshgdfhjkj', viewData);
                  if(!_.isEmpty(viewData)){
                    const uniqueIdentifier = new Date().getTime(); 
                    setHostVal(viewData)
                    setPhoto(`${_.get(viewData, 'data.meta.photo.path')}/${_.get(viewData, 'data.meta.photo.folder')}/${_.get(viewData, 'data.meta.photo.sizes.medium')}/${_.get(viewData, 'data.details.meta_details.logo')}?v=${uniqueIdentifier}`);
                    
                    setHostData(_.get(viewData, 'data'));
                  };
                }
              })
            };

            const handleChange = (value) => {
              const selectedItem = getExperienceList.find(item => item.id === value);
                  if (selectedItem) {
                    window.open(`/experience-list/${_.get(selectedItem, 'id')}?template=${_.get(selectedItem, 'template.id')}&handle=${searchParams.get('handle')}&id=${_.get(selectedItem, 'id')}`)
                  };
                  
            };

            const loadListing =()=>{
             return _.size(getExperienceList) >0 ?  getExperienceList.map(item => (

                {key: item.id,
              label: (<div className='date-select-class-option' style={{ borderBottom: '1px solid #EAECF0',paddingTop:'9px',paddingBottom:'9px' }}>
                {_.get(item, 'template.title')}
              </div>
                
              )})):[]
            }

            const SelectList = () => {
              
              return(
                <Dropdown
                
                rootClassName="dropdown-open-style"
                className="dropdown-open-style"
                openClassName="dropdown-open-style"
                // open={true}
                menu={
                  {items:listItem,onClick:(e)=>{
                    handleChange(e.key)
                    
                  }}
                }
                placement="bottom"
                arrow
                
                trigger={['click']}
                
              >
                <Button  style={{display:'flex',alignItems:'center',fontWeight:400}} className="all-listing-btn">All Listings <Image preview={false} style={{width:'20px',height:'20px'}} src={require('../../assets/img/chevron-down.png')} /></Button>
              </Dropdown>
    //             <Select 
    //             popupClassName="date-select-class-dropdown-dow"
    //             // placement="bottomLeft"
    //             rootClassName='date-select-class-dropdown'
    //                 className='date-select-class'
    //             onChange={handleChange}
    //             open={true}
    //             defaultValue="All listings"
    //             style={{ width: '140px',height:'50px', marginLeft: "30px" }}
    //             dropdownStyle={{
    //               width: "360px",
    //               marginLeft:'-50px'
                  
    //             }}
    //             >
    //   {_.size(getExperienceList) > 0 && getExperienceList.map(item => (
    //   <>  <Option key={item.id} className='date-select-class-option' value={item.id} style={{ borderBottom: '1px solid #EAECF0',paddingTop:'9px',paddingBottom:'9px' }}>
    //       {_.get(item, 'template.title')}
    //     </Option>
        
    //     </>
    //   ))}
    // </Select>
              )
            };

            const redirectPage = () => {
              // 
              // if(_.get(props, 'islogindata.isLoggedIn') === true){
              //   history(`/${localStorage.getItem('coach_handle')}`)
              // } else if(searchParams.get('handle')){
              //   history(`/${searchParams.get('handle')}`)
              // };
              if(searchParams.get('handle')){
                history(`/${searchParams.get('handle')}`)
              }
            };

            const modalTitle = (
              <div style={{display: "flex", flexDirection: "column"}}>
                <img src={require('../../assets/img/communiti-logo.png')} alt="Communiti Image" style={{ marginRight: '8px', marginBottom: '4px', width: "200px"}} />
                <div className='continue-with-email' style={{marginBottom: "5px"}}>Please sign-in to complete your purchase</div>
              </div>
            );

            const showLoginModal = () => {
              setIsModalOpen(true);
            };
            const handleLoginOk = () => {
              setIsModalOpen(false);
            };
            const handleLoginCancel = () => {
              setIsModalOpen(false);
            };

            const getGoogleToken = (response, signInType) => {
              if (response) {
                  let requestParams = {
                    type: 'google',
                    token: _.get(response, 'credential'),
                              scopes: ['profile', 'email']
                  }
                  props.login({
                    body:requestParams,
                    callback: (res,data)=>{
                                      
                      if(res==true && _.get(data,'isLoggedIn')==true){
                        setIsModalOpen(false)
            notification.success({
            message:"Success",
            description:'Login successful'
            })
            localStorage.setItem('login_methods', 'Google');
            // navigate(`/guest-profiles?id=${searchParams.get('id')}`);
            // window.location.reload(false);
                      }
                      
                    }
                });
                  
                }
              };
              
              const continue_with_phone_btn_text = {
                color: "var(--Gray-600, #475467)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
                cursor: "pointer"
              };
            
              const continue_with_email_text = {
                color: "var(--Gray-600, #475467)",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
                cursor: "pointer",
                textDecorationLine: "underline"
              };

              const showModal = () => {
                setIsModalVisible(true);
                setIsModalOpen(false)
              };

              const handleCancel = () => {
                setIsModalVisible(false);
              };

              const handleDataFromChild = (data) => {
                // Handle data received from child component
                setDataFromChild(data);
                setIsModalOpen(data);
              };
    
    const render = () => {
      
      let local_path = location.pathname.split('/')
      
      
        let photo =_.get(userProfile, 'photo')==null?require('../../assets/img/push-and-app-icon.png') :`${_.get(userProfileMeta, 'photo.path')}/${_.get(userProfileMeta, 'photo.folder')}/512/${_.get(userProfile, 'photo')}?time=${new Date().getTime()}`


        return (
        <header className="custom-header">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto" style={{display:'flex'}}>
                {
                        _.get(getHostVal, 'error') || _.isEmpty(_.get(getHostData, 'details.meta_details.logo')) ? 
                        <img className="header-avatar" style={{cursor: "pointer", height: "60px", width: "152px",borderRadius:'0px', objectFit: 'contain'}} 
                        onClick={() => redirectPage()} size="large" src={require('../../assets/img/header-logo1.png')}/> :
                         _.get(getHostVal, 'data.details') ? 
                         <img className="header-avatar" style={{cursor: "pointer", height: "60px", width: "152px",borderRadius:'0px', objectFit: 'contain'}} onClick={() => redirectPage()} size="large" src={getPhoto}/> :  
                        <h1 className="logo">COMMUNITI</h1>
                    }
                    {
                      local_path[1] === 'experience-list' && <SelectList/>
                    }
                    
                </div>
               
              {(windowSize.width>445 ||local_path[1] !== 'experience-list') &&  <div className="col-auto ">
                    
                    {
                      _.get(props, 'islogindata.isLoggedIn') === true ? <button className="login-sign-out-btn raise-btn" style={{fontSize: "1rem"}} onClick={() => logout()}>Logout</button> : <button onClick={() => showLoginModal()}
                      className="login-sign-out-btn raise-btn" 
                      style={{fontSize:'1rem',marginTop:'0px'}}>Login</button>
                    }
                  
                </div>}
              </div>
              {(windowSize.width<445 &&local_path[1] === 'experience-list') &&  <div className="col-auto mt-2">
                    
                    {
                      _.get(props, 'islogindata.isLoggedIn') === true ? <button className="login-sign-out-btn raise-btn" style={{fontSize: "1rem"}} onClick={() => logout()}>Logout</button> : <button onClick={() => showLoginModal()}
                      className="login-sign-out-btn raise-btn" 
                      style={{fontSize:'1rem',marginTop:'0px'}}>Login</button>
                    }
                  
                </div>}
            </div>
            {emailLoginOpen?.visible ==true &&<EmailLogin 
onChangeFormModal ={(values)=>setemailLoginOpen(values)}
onCloseModal={(isBack)=>{setemailLoginOpen({visible:false,form:1});

isBack ==true &&setIsModalOpen(true)
}} 
onOpenLoginModal ={()=>setemailLoginOpen({visible:true,form:1})}
isModalOpen={emailLoginOpen}/>}
            <Modal 
      title={modalTitle}
      open={isModalOpen} 
      onOk={handleLoginOk} 
      onCancel={handleLoginCancel}
      footer={null}
      >
        <div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "32px"}}>
                    <GoogleLogin
                      size='large'
                      onSuccess={credentialResponse => {
                          getGoogleToken(credentialResponse)
                      }}
                      onError={() => {
                          
                      }}
                      />
        </div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "24px"}}>
        <button onClick={showModal}  className='continue-with-phone-btn raise-btn'><CiMobile3 style={{width: "25px", height: "25px"}}/><div style={continue_with_phone_btn_text}>Continue with phone number</div></button>
        </div>
        
        <div onClick={()=>{
          handleLoginCancel()
          setemailLoginOpen({
            visible:true,form:1
          })
          localStorage.setItem('redirect_url',``)

        }} style={{display: "flex", justifyContent: "center", marginTop: "24px"}}>
        <div className='continue-with-email-style' style={{textDecorationLine: "none"}}>Continue with email</div>
        </div>
        <div className='login-popup-text'>
        By selecting “Continue with Google”, you acknowledge that you have read and understood, and agree to Communit’s <a href='https://www.communiti.app/terms-of-service' target='_blank' style={{textDecorationLine: "underline", color: "var(--Gray-600, #475467)"}}>Terms of Service</a> and <a href='https://www.communiti.app/privacy-policy' target='_blank' style={{textDecorationLine: "underline", color: "var(--Gray-600, #475467)"}}>Privacy Policy</a>
        </div>
        </div>
        
      </Modal>
      <LoginWithPhone sendDataToParent={handleDataFromChild} isModalVisible={isModalVisible} handleCancel={handleCancel}  proceed={true} props={props}/>
          </header>
  )



    }
    return render()


}
const mapStateToProps = state => {
   return {
        checkUpdate: _.get(state, 'app.account.isLogin.isLoggedIn'),
        islogindata: _.get(state, 'app.account.isLogin'),
        // userDetails: _.get(state, 'app.host.isLogin'),
        userProfile: _.get(state, 'app.host.profileLoadingInProgress'),
        guestUserProfile: _.get(state, 'app.guest.profileLoadingInProgress')
    }
}

const mapDispatchToProps = dispatch => ({
    isLogin: details => dispatch(global.redux.action.account.isLogin(details)),
    logout: details => dispatch(global.redux.action.account.logout(details)),
    hostProfileData: details => dispatch(global.redux.action.host.profile(details)),
   guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
   experienceListAPI: details => dispatch(global.redux.action.experience.list(details)),
   viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
   getHostDetailsAPI: details => dispatch(global.redux.action.host.getHostsDetails(details)),
   login: details => dispatch(global.redux.action.account.socialLogin(details)),
   otpLoginAPI: details => dispatch(global.redux.action.otpLogin.sendOtp(details)),
  checkProfileAPI: details => dispatch(global.redux.action.accountCheckProfile.checkProfile(details)),
  verifyOtpAPI: details => dispatch(global.redux.action.account.phoneLogin(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PageHeader)
