import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  useSearchParams,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Avatar, Skeleton } from "antd";
import { Rating } from "react-simple-star-rating";
import moment from "moment-timezone";

import CommonFooter from "../../components/Footer/footer";
import NotFoundPage from "./notFoundPage";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./style.css";

const ExperienceListHandle = (props) => {
  let navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [experiencePhotos, setexperiencePhotos] = useState([]);
  const { handleValue } = useParams();
  const [getRatingList, setRatingList] = useState([]);
  const [getRatingMeta, setRatingMeta] = useState({});
  const [getExperienceList, setExperienceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentLocalISOString] = useState(
    moment.tz(new Date(), "America/Toronto").toISOString()
  );
  const [getHostData, setHostData] = useState({});
  const [errMsg, setErrMsg] = useState();
  const location = useLocation();
  const [searchParams, setsearchParams] = useSearchParams();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(() => {
    
    if (handleValue) {
      
      hostDetails(handleValue);
    }
  }, []);


  const viewTemplate = (handleValue, hostphotos) => {
    props.experienceListAPI({
      params: {
        status: 1,
        page: 1,
        limit: 50,
        search_type: 0,
        expand: "host,template,template_photos,start_price",
        group_by: "template",
        client_timezone: "America/Toronto",
        min_experience_date: currentLocalISOString,
        min_experience_time: _.chain(currentLocalISOString)
          .split("T")
          .last()
          .value(),
        is_slots_available: 1,
        is_price_porated: 1,
        host_handle: handleValue,
      },
      callback: (r, v) => {
        

        if (v) {
          setLoading(false);
        }

        if (_.size(_.get(v, "data.items")) > 0) {
          setExperienceList(_.get(v, "data.items"));
          let expierenceItems = [..._.get(v, "data.items")];

          let filterTemplatePhotos = [];
          _.map(expierenceItems, (items) => {
            if (_.size(_.get(items, "template.photos")) > 0) {
              filterTemplatePhotos.push(_.get(items, "template.photos"));
            }
          });

          let filterPhotos = _.map(
            _.flatten(filterTemplatePhotos),
            (value) => ({
              ...value,
              url: `${_.get(v, "data.meta.photo.path")}/${_.get(
                v,
                "data.meta.photo.folders.template"
              )}/${_.get(v, "data.meta.photo.sizes.large")}/${_.get(
                value,
                "photo"
              )}`,
            })
          );
          setexperiencePhotos(
            _.get(hostphotos, "banner")
              ? [hostphotos, ...filterPhotos]
              : [...filterPhotos]
          );
        }else if(_.size(_.get(v, "data.items"))==0){
          setexperiencePhotos(
            [hostphotos]
          );

        }
      },
    });
  };

  const hostDetails = (handleValue) => {
    props.getHostDetailsAPI({
      params: {
        handle: handleValue,
      },
      callback: (response, viewData) => {
        
        if (_.get(viewData, "error.statusCode") === 404) {
          setErrMsg(_.get(viewData, "error.statusCode"));
        }

        if (!_.isEmpty(viewData)) {
          const uniqueIdentifier = new Date().getTime();
         
          setHostData(_.get(viewData, "data"));
          viewTemplate(handleValue, {
            id: _.get(viewData, "data.details.id"),
            url: `${_.get(viewData, "data.meta.photo.path")}/${_.get(
              viewData,
              "data.meta.photo.folder"
            )}/${_.get(viewData, "data.meta.photo.sizes.large")}/${_.get(
              viewData,
              "data.details.meta_details.banner"
            )}?v=${uniqueIdentifier}`,
            banner: _.get(viewData, "data.details.meta_details.banner"),
          });
          props.ratingListAPI({
            params: {
              expand: "top_tags,guest,host",
              limit: 3,
              host: _.get(viewData, "data.details.id"),
            },
            callback: (res, val) => {
              if (res) {
                if (_.get(val, "data.meta.count") > 0) {
                  setRatingList(_.get(val, "data.items"));
                  setRatingMeta(_.get(val, "data.meta"));
                } else {
                  setRatingList([]);
                }
              }
            },
          });
        }
      },
    });
  };

  const onClickSelect = (value) => {
    let local_path = location.pathname.split("/");

    if (_.get(props, "islogindata.isLoggedIn") === true) {
      navigate(
        `/experience-list/${_.get(value, "id")}?template=${_.get(
          value,
          "template.id"
        )}&handle=${local_path[1]}&id=${_.get(value, "id")}`
      );
    } else {
      navigate(
        `/experience-list/${_.get(value, "id")}?template=${_.get(
          value,
          "template.id"
        )}&handle=${local_path[1]}&id=${_.get(value, "id")}`
      );
    }
  };

  const handleClickSeeAllReviews = () => {
    let local_path = location.pathname.split("/");

    navigate(
      `/reviews?host=${_.get(getHostData, "details.id")}&handle=${
        local_path[1]
      }`
    );
  };

  const roundPrice = (price) => {
    return Math.round((parseFloat(price) + Number.EPSILON) * 100) / 100;
  };
  const goToSlide = (index) => {
    setCurrentSlide(index);
  };
  const indicators = (index, data) => {
    return (
      <div key={index} className="dot-indicator" style={{ marginRight: "6px" }}>
        <span
          key={index}
          className={
            index === currentSlide ? "dot-another active" : "dot-another"
          }
          onClick={() => goToSlide(index)}
        />
      </div>
    );
  };
  const responsiveSettings = [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    }
];
  const render = () => {
    
    return (
      <div>
        {errMsg === 404 && <NotFoundPage />}
        <div>
          <div className="banner-container">
            <div
              style={{ display: errMsg === 404 ? "none" : "block",width:windowSize.width<550?windowSize.width-30 :"100%" }}
              className="container"
            >
              {!loading ? (
                <Slide
                
                  duration={1000}
                  defaultIndex={currentSlide}
                  transitionDuration={500}
                  onChange={(from, to) => {
                    setCurrentSlide(to);
                  }}
                  cssClass="slider-image-container"
                  indicators={_.size(experiencePhotos) > 1 ? indicators : false}
                  autoplay={false}
                  arrows={_.size(experiencePhotos) > 1 ? true : false}
                  nextArrow={
                    <button className="nextArrow-class">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M9 18L15 12L9 6"
                          stroke="#344054"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  }
                  prevArrow={
                    <button className="preArrow-class">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M15 18L9 12L15 6"
                          stroke="#344054"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                  }
                >
                  {_.map(experiencePhotos, (value, i) => {
                    return (
                      <div
                        key={i}
                        style={{ height: "405px", borderRadius: "10px",width:"100%" }}
                        className="each-slide-effect"
                      >
                        <div
                          style={{
                            backgroundImage: `url(${value?.url})`,
                            height: "405px",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            borderRadius: "10px",
                          }}
                        ></div>
                      </div>
                    );
                  })}
                </Slide>
              ) : (
                <Skeleton
                  active
                  paragraph={{
                    rows: 7,
                  }}
                />
              )}
            </div>
          </div>
          <div
            style={{ display: errMsg === 404 ? "none" : "block" }}
            className="rating-container"
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div style={{ marginTop: "16px" }}>
                    {!loading ? (
                      <div className="rating-card">
                        <div style={{marginTop: "24px"}} className="experince-title">
                          {_.startCase(
                            _.get(getHostData, "details.display_name")
                          )}
                        </div>
                        <div className="experince-address">
                          {_.get(getHostData, "details.address")}
                        </div>
                        {_.get(getHostData, "details.average_ratings") !==
                          null &&
                        _.get(getHostData, "details.no_of_ratings") !== null ? (
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <Rating
                              initialValue={1}
                              readonly={true}
                              iconsCount={1}
                              size={20}
                              style={{ marginTop: "-8px", marginRight: "5px" }}
                            />
                            <div className="experince-rating">
                              {_.get(getHostData, "details.average_ratings") &&
                                Math.round(
                                  _.get(
                                    getHostData,
                                    "details.average_ratings"
                                  ) * 10
                                ) / 10}
                            </div>
                            <div className="dot"></div>
                            <div className="experience-reviews">{`${_.get(
                              getHostData,
                              "details.no_of_ratings"
                            )} Reviews`}</div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      <Skeleton
                        active
                        paragraph={{
                          rows: 3,
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-lg-8">
                  <div style={{ marginTop: "16px" }}>
                    {!loading ? (
                      <div></div>
                    ) : (
                      <Skeleton
                        active
                        paragraph={{
                          rows: 1,
                        }}
                      />
                    )}

                    <div>
                      {!loading ? (
                        <div className="description-container">
                          <div className="template-description-title">
                            About
                          </div>
                          <div className="template-description">
                            {!_.isEmpty(_.get(getHostData, "details.bio"))
                              ? _.get(getHostData, "details.bio")
                              : ""}
                          </div>
                        </div>
                      ) : (
                        <Skeleton
                          active
                          paragraph={{
                            rows: 10,
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {!loading ? (
                        <div>
                          {_.get(getHostData, "details.average_ratings") !==
                            null &&
                            _.get(getHostData, "details.no_of_ratings") !==
                              null && (
                              <div className="description-container">
                                <div
                                  className="template-description-title firstColor-style"
                                  style={{ fontSize: "1.25rem" }}
                                >
                                  Reviews
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div className="review-rating-star">
                                    <Rating
                                      initialValue={1}
                                      readonly={true}
                                      iconsCount={1}
                                      size={20}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      marginLeft: "6px",
                                      marginTop: "-4px",
                                    }}
                                    className="secondColor-style"
                                  >
                                    {_.get(
                                      getHostData,
                                      "details.average_ratings"
                                    ) &&
                                      Math.round(
                                        _.get(
                                          getHostData,
                                          "details.average_ratings"
                                        ) * 10
                                      ) / 10}
                                  </div>
                                  <p
                                    style={{ marginTop: "7px" }}
                                    className="dot secondColor-style"
                                  ></p>
                                  <div
                                    style={{
                                      marginLeft: "6px",
                                      marginTop: "-4px",
                                    }}
                                    className="secondColor-style"
                                  >{`${_.get(
                                    getHostData,
                                    "details.no_of_ratings"
                                  )} Reviews`}</div>
                                </div>
                                {_.size(getRatingList) > 0 &&
                                  getRatingList.map((v, i) => {
                                    let src = `${_.get(
                                      getRatingMeta,
                                      "photo.path"
                                    )}/${_.get(
                                      getRatingMeta,
                                      "photo.folders.guest"
                                    )}/${_.get(
                                      getRatingMeta,
                                      "photo.sizes.medium"
                                    )}/${_.get(v, "guest.photo")}`;

                                    const fullName = `${_.get(
                                      v,
                                      "guest.first_name"
                                    )} ${_.get(v, "guest.last_name")}`;
                                    const initials = fullName
                                      .split(" ")
                                      .map((name) => name[0])
                                      .join("");

                                    return (
                                      <div
                                        className="card"
                                        style={{
                                          boxShadow: "none",
                                          marginBottom: "10px",
                                          borderRadius: "12px",
                                          paddingRight: "24px"
                                        }}
                                      >
                                        <div>
                                          <div
                                            className="rating-container"
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                            }}
                                          >
                                            {!_.isEmpty(
                                              _.get(v, "guest.photo")
                                            ) ? (
                                              <Avatar src={src} size={40} />
                                            ) : (
                                              <Avatar size={40}>
                                                {_.upperCase(initials)}
                                              </Avatar>
                                            )}

                                            <div className="rating-name secondColor-style">
                                              {`${_.startCase(
                                                _.get(v, "guest.first_name")
                                              )} ${_.startCase(
                                                _.get(v, "guest.last_name")
                                              )}`}
                                            </div>
                                          </div>
                                          <div
                                            className="rating-date secondColor-style"
                                            style={{ fontSize: "0.875rem" }}
                                          >
                                            {!_.isEmpty(_.get(v, "created_at"))
                                              ? moment(
                                                  _.get(v, "created_at")
                                                ).format("MMMM DD, YYYY")
                                              : "-"}
                                          </div>
                                          <div className="rate-star-container">
                                            <Rating
                                              initialValue={_.get(
                                                v,
                                                "star_rating"
                                              )}
                                              readonly={true}
                                              iconsCount={_.get(
                                                v,
                                                "star_rating"
                                              )}
                                              size={20}
                                              className="experince-rating-star"
                                            />
                                          </div>
                                          <div className="rate-message secondColor-style">
                                            {_.get(v, "message")}
                                          </div>
                                          {_.size(_.get(v, 'tags'))>0 && <div style={{marginTop:'8px',display:'flex',flexWrap:'wrap', marginLeft: "24px"}}>
                            {_.map(_.get(v, 'tags'),(tag,i)=>{
                            return <div key={i} style={{marginRight:"8px",marginBottom:'8px', borderRadius: "6px", border: "1px solid var(--Gray-300, #D0D5DD)", background: "var(--Base-White, #FFF)", display: "flex", padding: "2px 9px", justifyContent: "center", alignItems: "center"}}>
                                   <div className="rating-container-tags-text">{tag}</div>

                            </div>
                            })}
                          </div>}
                                        </div>
                                      </div>
                                    );
                                  })}
                                <div>
                                  <button
                                    onClick={() => handleClickSeeAllReviews()}
                                    className="see-all-review-btn"
                                  >
                                    See all reviews
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                      ) : (
                        <Skeleton active paragraph={{ rows: 4 }} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4" style={{ marginTop: "16px" }}>
                  <div>
                    {!loading ? (
                       _.size(getExperienceList) > 0 ? (<div>
                      <div className="card right-card">
                        <p
                          className="register-title firstColor-style"
                          style={{
                            color: "var(--Gray-900, #101828)",
                            fontSize: "1.25rem",
                          }}
                        >
                          Listings
                        </p>
                        <div>
                          {
                            getExperienceList.map((value, index) => {
                              return (
                                <div
                                key={index}
                                  onClick={() => onClickSelect(value)}
                                  className="card custom-card"
                                >
                                  <div
                                    className="experience-title secondColor-style"
                                    style={{
                                      fontSize: "1rem",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {_.get(value, "template.title")}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <div
                                      className="experince-listing-amount secondColor-style"
                                      style={{ fontSize: "1rem" }}
                                    >{`From $${roundPrice(
                                      _.get(value, "start_price")
                                    )} ${_.get(
                                      value,
                                      "meta_details.currency"
                                    )}`}</div>
                                  </div>
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                      </div>) : <div className="empty-session-card">
                        <div className="empty-session-info-sub-card">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_8741_1969)">
                            <path d="M10 6.66675V10.0001M10 13.3334H10.0083M18.3333 10.0001C18.3333 14.6025 14.6024 18.3334 10 18.3334C5.39763 18.3334 1.66667 14.6025 1.66667 10.0001C1.66667 5.39771 5.39763 1.66675 10 1.66675C14.6024 1.66675 18.3333 5.39771 18.3333 10.0001Z" stroke="#475467" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_8741_1969">
                              <rect width="20" height="20" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                        </div>
                        <div className="empty-session-header-txt">
                        No active listings
                        </div>
                        <div className="empty-session-message">
                        There are currently no active listings available. Please check again later.
                        </div>
                      </div>
                    ) : (
                      <Skeleton active paragraph={{ rows: 7 }} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {errMsg !== 404 && <CommonFooter />}
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => {
  return {
    islogindata: _.get(state, "app.account.isLogin"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  ratingListAPI: (details) =>
    dispatch(global.redux.action.experience.experienceRatingList(details)),
  experienceListAPI: (details) =>
    dispatch(global.redux.action.experience.list(details)),
  getHostDetailsAPI: (details) =>
    dispatch(global.redux.action.host.getHostsDetails(details)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExperienceListHandle);