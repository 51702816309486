import React, {useState, useEffect} from 'react';
import { Button, Image, Modal, notification, Skeleton } from 'antd';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import {CiCircleInfo} from "react-icons/ci";
import {PiQuestionThin} from "react-icons/pi"
import { gapi } from 'gapi-script';

import "./style.css";
import appEnvirontVariables from "../../core_units/config/env-variables";
import { Elements } from '@stripe/react-stripe-js';
import CheckOutForm from '../payment/checkout';
import envVariables from '../../core_units/config/env-variables';
import {loadStripe} from '@stripe/stripe-js';

import AlreadyPaidError from './already-paid'
const PayInvoice = (props) => {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [getTempData, setTempData] = useState({});
    const [getScheduledDate, setScheduledDate] = useState([]);
    const [pastDateCount, setPastDateCount] = useState(0);
    const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);
    const [orderDetails, setorderDetails] = useState({})
    const [clientSecret, setClientSecret] = useState("");
const [loadingPage, setloadingPage] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false);

    const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
    const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');

    useEffect(() => {
		function start() {
			gapi.client.init({
			  clientId: appEnvirontVariables.GOOGLE.GOOGLE_CLIENT_ID,
			  scope: 'email',
			});
		}
		gapi.load('client:auth2', start);
	  }, []);

    useEffect(() => {
       
       if(id){
              
              getExperienceDetails(id);
       }
       
    }, []);

    useEffect(() => {
        let count = 0;
    
        getScheduledDate.forEach((dateString) => {
          const date = moment.tz(dateString, timeZone);
          if (date.isBefore(today)) {
            count++;
          }
        });
    
        setPastDateCount(count);
      }, [getScheduledDate, today, timeZone]);

    const getExperienceDetails = (id) => {
        props.viewPayRequestDetails({
            params: {
              id:id,
              expand: "orders,experience,host",
              // is_price_porated: 1
            },
            callback: (res, data) => {
              
              if(res){
                     setorderDetails(data?.data?.details)
                     handlePayment(data?.data?.details?.id)
                     let value = data?.data?.details?.experience
                     
                setTempData(value)
                setLoading(false);
                let start_date_item = _.get(value, 'data.details.experience_date');
                let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
                
                let concated_array = [start_date_item].concat(scheduled_date_items)
                setScheduledDate(concated_array.filter(item => item !== "" && item !== null))
                
              }
            }
          })
    };

    const showSessionDateModal = () => {
        setIsSessionDateModalOpen(true)
      };
      const scheduleHandleOk = () => {
        setIsSessionDateModalOpen(false)
      }
    
      const scheduleHandleCancel = () => {
        setIsSessionDateModalOpen(false)
      };

      const handlePayment = (id,details) => {
       props.createPaymentInvoice({
       //   body:{
       //     experience:searchParams.get("id"),
       //     registration_info: _.size(regInfoFinal) > 0 ? regInfoFinal : [],
       //     clauses: _.size(_.get(getTempData, 'data.details.template.clauses')) > 0 ? _.get(getTempData, 'data.details.template.clauses') : [],
       //     profile: searchParams.get("p_id")
       //   },
         params:{id:id},
         callback: (res, val) => {
           
           if(_.get(val, 'success') === false){
            setErrorMessage(true)
           }
       //     if(_.get(val, 'payment_intent.id')){
              setloadingPage(false)
             setClientSecret(_.get(val, 'payment_intent.client_secret'))
       //     }
         }
       })
   };


      const getGoogleToken = (response, signInType) => {
		if (response) {
				let requestParams = {
					type: 'google',
					token: _.get(response, 'credential'),
                    scopes: ['profile', 'email']
				}
				props.login({
					body:requestParams,
					callback: (res,data)=>{
                            
					  if(res==true && _.get(data,'isLoggedIn')==true){
notification.success({
	message:"Success",
	description:'Login successful'
})
// window.location.reload();
					  }
					  
					}
			});
				
			}
		} 

    const showProratedModal = () => {
      setIsProratedModalOpen(true);
    }
  
    const handleProratedOk = () => {
      setIsProratedModalOpen(false)
    }
  
    const handleProratedCancel = () => {
      setIsProratedModalOpen(false)
    }
    const render = () => {
       
        return (
            <div>
              {
                errorMessage ? <AlreadyPaidError/> : <div className="banner-container">
                <div className='container'>
                  {
                    errorMessage 
                  }
                  <div className='row'>
                      <div className='col-lg-8'>
                  {!loadingPage? <>
                      <div style={{display: "flex", flexDirection: "row",marginTop:'-12px'}}>
                            <p className='registration-profile-title'>Payment</p>
                        {/* <SlCheck style={{marginLeft: "620px", marginTop: "30px", color: "#45c68a"}}/> */}

                       
                        </div>
                        {/* <Image preview={false} style={{width:'155px',height:'34px',marginBottom:'12px'}} src={require('../../assets/img/stripe-banner.png')} /> */}
                           <div style={{marginTop: "10px"}}>
                 {
                  loadStripe(envVariables.STRIPE.PUBLISHABLE_KEY, {
                    stripeAccount: _.get(orderDetails, 'host.stripe_user_id')
                  }) && clientSecret && (
                    <Elements stripe={loadStripe(envVariables.STRIPE.PUBLISHABLE_KEY, {
                      stripeAccount: _.get(orderDetails, 'host.stripe_user_id')
                    })} options={{clientSecret}}>
                    <CheckOutForm redirectId={_.get(getTempData, 'id')}/>
                  </Elements>
                  )
                 }
                 </div>
                          {
                  _.isEmpty(clientSecret) && <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none", border: "none", padding: "0px" }}>
                  <Button onClick={handlePayment} className='proceed-payment-button'>Register and pay</Button>
                  
                  </div>
                 }
                      </>: <Skeleton active paragraph={{rows: 20}}/>}
                      </div>
                      <div className="col-lg-4">
          {/* <div style={{marginTop: "16px"}}> */}
            <div>
              {
                !loadingPage ? <div className="card" style={{boxShadow: "none"}}>
                <div className="card-body">
                <p className='register-title'>{_.get(getTempData, 'data.details.template.title')}</p>
                <p className='register-location'>Location</p>
                <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none" }}>
                <p className='register-address'>{_.get(getTempData, 'address')}</p>
                </div>
                <p className='register-location'>Start and end date</p>
                <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none" }}>
                  {/* moment.tz(_.get(getScheduleDates, "experience_date"), t).format("hh:mm A z") */}
                  <p className='register-address'>{`${moment.tz(_.get(getTempData, 'experience_date'), _.get(getTempData, 'meta_details.timezone')).format('MMMM DD, YYYY')}  to  ${moment.tz(_.get(getTempData, 'experience_end_date'), _.get(getTempData, 'meta_details.timezone')).format('MMMM DD, YYYY')}`}</p>
                </div>
                <p className='register-location'>Start and end time</p>
                <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none" }}>
                <p className='register-time'>{`${moment.tz(_.get(getTempData, 'experience_date'), _.get(getTempData, 'meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'experience_end_date'),_.get(getTempData, 'meta_details.timezone')).format("hh:mm A z")}`}</p>
                </div>
                <p className='register-location'>Session Schedule</p>
                <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none" }}>
                 
                <div>
                  {
                    _.get(getTempData, 'data.details.can_late_register') === true  && pastDateCount > 0 ? <div style={{display: "flex", flexDirection: "row"}}>
                      <div className='register-schedule' style={{textDecorationLine: "line-through"}}>
                      { _.size(getScheduledDate) > 1 ? `${_.size(getScheduledDate)} Sessions` : `${_.size(getScheduledDate)} Session`}
                      </div>
                      <div className='register-schedule' style={{marginLeft: "10px"}}>{ _.size(getScheduledDate)-pastDateCount > 1 ? `${_.size(getScheduledDate)-pastDateCount} Sessions` : `${_.size(getScheduledDate)-pastDateCount} Session`}</div><p style={{marginLeft: "8px", marginTop: "5px", cursor: "pointer"}} onClick={() => showProratedModal()}><PiQuestionThin/></p></div>:
                    <p className='register-schedule'>{ _.size(getScheduledDate) > 1 ? `${_.size(getScheduledDate)} Sessions` : `${_.size(getScheduledDate)} Session`}</p>
                  }
                  </div>
                
                <div style={{display: "flex", flexDirection: "row"}}>
                <p className='register-full-schedule' onClick={showSessionDateModal}>See full schedule</p> <div onClick={() => showSessionDateModal()} className='session-info-icon'><CiCircleInfo/></div>
                </div>
                </div>
                <p className='register-location'>Price Details</p>
                  <div style={{display: "flex", flexDirection: "column"}}>
                  {/* {
                   !_.isEmpty(_.get(getTempData, 'data.details')) ?  _.get(getTempData, 'data.details.can_late_register') === true && pastDateCount > 0 ? <div style={{display: "flex", flexDirection: "row"}}><p className='registration-price-strike'>${`${_.get(getTempData, 'data.details.original_price').toFixed(2)} ${_.get(getTempData, 'data.details.meta_details.currency')}`}</p> <p className='registration-price' style={{marginLeft: "10px"}}>${`${_.get(getTempData, 'data.details.price').toFixed(2)} ${_.get(getTempData, 'data.details.meta_details.currency')}`}</p> </div>: <p className='registration-price'>${`${_.get(getTempData, 'data.details.price').toFixed(2)} ${_.get(getTempData, 'data.details.meta_details.currency')}`}</p> : ""
                  } */}
                  <div className='price-detail'>
                    <p className='price-amount'>Price</p>
                   
                   {
                          
                          // <p className='price-amount' style={{marginLeft: "141px"}}>{`$${_.get(orderDetails, 'order.sub_total')}`}</p>
                          // :
                          <p className='price-amount' style={{marginLeft: "141px"}}>${parseFloat(_.get(orderDetails, 'order.sub_total'))+parseFloat(_.get(orderDetails, 'order.base_platform_amount'))}</p>
                   }
                    
                  </div>
                 { <div className='price-detail'>
                    <p className='price-amount'>Tax</p>
                    {
                   //  _.get(orderDetails, 'order.base_platform_amount_visible')==true?
                   // <p className='price-amount' style={{marginLeft: "153px"}}>{`$${_.get(orderDetails, 'order.tax_amount')}`}</p>
                   // :
                   <p className='price-amount' style={{marginLeft: "153px"}}>${parseFloat(parseFloat(_.get(orderDetails, 'order.tax_amount'))+parseFloat(_.get(orderDetails, 'order.base_platform_tax_amount'))).toFixed(2)}</p> 
                   }
                    
                  </div>}
                  {/* {
                   _.get(orderDetails, 'order.base_platform_amount_visible') === true && <div>
                    <div className='price-detail'>
                      <p className='price-amount'>Communiti Fee</p>
                      <p className='price-amount' style={{marginLeft: "81px"}}>{`$${_.get(orderDetails, 'order.base_platform_amount')}`}</p>
                    </div>
                    <div className='price-detail'>
                      <p className='price-amount'>Communiti Tax</p>
                      <p className='price-amount' style={{marginLeft: "83px"}}>{`$${_.get(orderDetails, 'order.base_platform_tax_amount')}`}</p>
                    </div>
                    </div>
                  } */}
                   {/* {_.get(orderDetails, 'order.discount_amount') !=0 &&_.get(orderDetails, 'order.discount_amount') !=null && <div className='price-detail'>
                    <p className='price-amount'>Promo Code</p>
                    <p className='price-amount' style={{marginLeft: "95px"}}>-${_.get(orderDetails, 'order.discount_amount')}</p>
                  </div>} */}
                  <div className='price-detail'>
                    <p className='total-amount'>{`Total(${_.get(getTempData, 'meta_details.currency')})`}</p>
                    <p className='total-amount' style={{marginLeft: "106px"}}>{`$${_.get(orderDetails, 'order.total')}`}</p>
                  </div>
                  </div>
                 
                  
                 
                 
                </div>
              </div> : <Skeleton active paragraph={{rows: 20}}/>
              }
              
            </div>
            {/* </div> */}
            </div>
                  </div>
                </div>
            
          </div>
              }
                
            <div>
    <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
    <p className='register-location'>Session Schedule</p>
                 
                  <div>
      {_.size(getScheduledDate) > 0 && getScheduledDate.map((item, index) => {
        const date = moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone'));
        const isPastDate = date.isBefore(today);
        const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  })}
    </div>
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session has already started and the price has been prorated. View the schedule to see the dates remaining
        </div>
      </Modal>
      </div>
            </div>
          )
    };
    return render();
  
}

const mapStateToProps = state => {
    return {
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
    login: details => dispatch(global.redux.action.account.socialLogin(details)),
    viewPayRequestDetails:details => dispatch(global.redux.action.experience.viewPayInvoiceDetails(details)),
    exprienceCreateOrderAPI: details => dispatch(global.redux.action.experience.experienceCreateOrder(details)),
    createPaymentInvoice: details => dispatch(global.redux.action.experience.createPaymentInvoice(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(PayInvoice)