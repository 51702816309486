import { Button as AntButton, Divider, Modal } from 'antd';
import { IoMdClose } from "react-icons/io";
import './style.css'
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { TbArrowBackUp } from 'react-icons/tb';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash'

import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import SuccessModal from './success-modal';
import ResetPasswordCreate from './reset-password-create';

const { useState, useEffect, useRef, default: React,createRef } = require('react');

const useStyles = makeStyles({
       helperText: {
              color: '#475467 !important',
              fontFamily: `Inter, sans-serif !important`,
              fontSize: '0.875rem !important',
              fontWeight: 400,
              lineHeight: '16px' /* 114.286% */
       },
       root: {
              // input label when focused
              "& label.Mui-focused": {
                     color: "#FF692E"
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              "& .MuiInputBase-input.Mui-disabled": {
                     WebkitTextFillColor: "#475467",

                     "& > fieldset": {
                            borderColor: "red"
                     }
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                     "&.Mui-focused fieldset": {
                            borderColor: "#FF692E"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#FF692E"
                     },

              },


              '& .MuiInputBase-root.Mui-disabled': {

                     "&:focus fieldset": {
                            borderColor: "#D0D5DD"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#D0D5DD"
                     },
                     backgroundColor: 'var(--Gray-50, #F9FAFB)', // Change to your desired background color
                     borderColor: '#c0c0c0', // Change to your desired border color
              },
              '& .MuiInputBase-root.Mui-disabled:hover': {
                     borderColor: '#c0c0c0', // Change to your desired focused border color
              },
       },


});

const ResetPasswordOtpVerification = (props) => {
       const classes = useStyles();
       
       const [isModalOpen, setIsModalOpen] = useState(false);

       const [formStep, setformStep] = useState(1)
       const [otp, setOtp] = useState(['', '', '', '', '', '']);
       const [errorOtp, seterrorOtp] = useState(false)
  const inputRefs = useRef([...Array(6)].map(() => createRef()));
  const [enableResendOtp, setenableResendOtp] = useState(false)
  const [seconds, setSeconds] = useState(60);
  const [successModal, setsuccessModal] = useState(false)
  const [usersDetails, setusersDetails] = useState({})
  const [passwordForm, setpasswordForm] = useState(1);

  useEffect(() => {
       setusersDetails({...props?.userDetails,id: props?.userDetails?.account})
  }, []);
       useEffect(() => {
              setIsModalOpen(props?.isModalOpen?.visible)
              setformStep(props?.isModalOpen?.form)
       }, [props?.isModalOpen])

       useEffect(() => {
              if (seconds > 0) {
                const intervalId = setInterval(() => {
                  setSeconds(prevSeconds => prevSeconds - 1);
                }, 1000);
          
                return () => clearInterval(intervalId);
              }
            }, [seconds]);
       

       const handleChange = (index, value) => {
              seterrorOtp(false)
              if (value !== '') {
                const newOtp = [...otp];
                newOtp[index] = value;
                setOtp(newOtp);
                if (index < 5 && value !== '') {
                  inputRefs.current[index + 1].current.focus();
                }
              }else if (value === '' && index >= 0) {
                     const newOtp = [...otp];
                     newOtp[index] = '';
                     setOtp(newOtp);
                     
                     
                     index!=0 &&inputRefs.current[index - 1].current.focus();
                   }
            };
          
            const handleKeyUp = (index, event) => {
              if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
                     inputRefs.current[index - 1].current.focus();
                   }
              // if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
              //   inputRefs.current[index - 1].current.focus();
              // } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '' && event.key !== 'Shift' &&event.key !== 'ArrowLeft') {
              //   inputRefs.current[index + 1].current.focus();
              // }
            };
          
            const handleKeyDown = (index, event) => {
              if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
                     inputRefs.current[index - 1].current.focus();
                   }
              // if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
              //   inputRefs.current[index - 1].current.focus();
              // } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '' && event.key !== 'Shift' &&event.key !== 'ArrowLeft') {
              //   inputRefs.current[index + 1].current.focus();
              // }
            };
       const accountsResendVerification = () => {
              props.requestResetPassword({
                     body: {
                            user_type: 0,
                            email: props?.userDetails?.email
                     },
                     callback: (res, data) => {
                            if(res==true && _.get(data, 'success')==true){
                                   setSeconds(60);
                                   seterrorOtp(false)
                                   setOtp(['', '', '', '', '', ''])

                            }
                           

                     }
              })
       }

       const  accountsVerification = () => {
              
              props.accountsVerify({
                     body: {
                            id: props?.userDetails?.account,
                            token: otp.join('')
                     },
                     callback: (res, data) => {
                            
                            if(res==true && _.get(data, 'success')==true){
                                   setpasswordForm(2)
                                   setusersDetails({...props?.userDetails,id: props?.userDetails?.account,
                                          token: otp.join('')})
                                   
                                   
                            }else if(_.get(data,'statusCode')==404){
seterrorOtp(true)
                            }
                            
                           

                     }
              })
       }
       const handlePaste = (event) => {
              event.preventDefault();
              const pastedData = event.clipboardData.getData('Text');
              if (pastedData.length === 6 ) {
                setOtp(pastedData.split('').slice(0, 6));
              }
            };

       
       return successModal==true? <SuccessModal onOpenLoginModal={()=>{
              props?.onOpenLoginModal()
       }} oncloseAllModal ={()=>props.oncloseAllModal()} userDetails={props?.userDetails} /> :
      <ResetPasswordCreate
       onOpenLoginModal={()=>{
              props?.onOpenLoginModal()
       }}
       onBackForm={()=>{
              // setpas(1)
       }} oncloseAllModal={() => props?.oncloseAllModal()} userDetails={usersDetails}  /> 


}
const mapStateToProps = state => {
       return {


       }
}

const mapDispatchToProps = dispatch => ({

       accountsResendVerification: details => dispatch(global.redux.action.guest.accountsResendVerification(details)),
       accountsVerify: details => dispatch(global.redux.action.guest.accountsVerify(details)),
       requestResetPassword: details => dispatch(global.redux.action.guest.requestResetPassword(details)),


});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordOtpVerification)
