import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { Button, Avatar, Skeleton } from 'antd';
import { Rating } from 'react-simple-star-rating';
import moment from 'moment-timezone';

import "./style.css";

const UserHandleExperience = (props) => {

  let navigate = useNavigate();
    const [getPhoto, setPhoto] = useState();
    const [getRatingList, setRatingList] = useState([]);
    const [getRatingMeta, setRatingMeta] = useState({});
    const [getExperienceList, setExperienceList] = useState([])
    const [loading, setLoading] = useState(true);
    const [string, setString] = useState(null)
    const [currentLocalISOString] = useState( moment.tz(new Date(),"America/Toronto").toISOString());
    useEffect(() => {
        viewTemplate();
      }, []);

    const viewTemplate = () => {

      const currentURLxcd = window.location.href;

      // Split the URL by '/'
      const partssdf = currentURLxcd.split('/');
    
      // Access the desired part (assuming it's always at the end of the URL)
      const extractedStringsfff = partssdf[partssdf.length - 1];
      
              const currentURL = window.location.href;
              // Use regex to extract the part you want
              const regex = /\/([^/]+)\/session\/\d+/;
              const match = currentURL.match(regex);
              if (match && match.length > 1) {
                const extractedString = match[1];
                
                setString(extractedString)
              props.experienceListAPI({
                params:{
                  status: 1,
                  page: 1,
                  limit: 50,
                  search_type: 0,
                  expand: 'host,template,template_photos',
                  group_by: 'template',
                  client_timezone: "America/Toronto",
                  min_experience_date: currentLocalISOString,
                  min_experience_time: _.chain(currentLocalISOString).split('T').last().value(),
                  is_slots_available:1,
                  is_price_porated:1,
                  host_handle: extractedString
                },
                callback: (r, v) => {
                  
                  if(_.size(_.get(v, 'data.items')) > 0){
                    setLoading(false)
                    setPhoto(`${_.get(v, 'data.meta.photo.path')}/${_.get(v, 'data.meta.photo.folders.host')}/${_.get(v, 'data.meta.photo.sizes.medium')}/${_.get(v, 'data.items[0].host.photo')}`);
                    setExperienceList(_.get(v, 'data.items'))
                    props.ratingListAPI({
                      params:{
                        expand:"top_tags,guest,host",
                        limit:1,
                        host: _.get(v, 'data.items[0].host.id')
                      },
                      callback: (res, val) => {
                        
                        if(res){
                          
                        if(_.get(val, 'data.meta.count') > 0){
                          setRatingList(_.get(val, 'data.items'));
                          setRatingMeta(_.get(val, 'data.meta'));
                        } else {
                          setRatingList([]);
                        }
                      }
                        
                      }
                    })
                  }
                }
              }) 
            } else {
              
            }
      };

      const inlineStyle = {
        backgroundImage: !_.isEmpty(_.get(getExperienceList[0], 'host.photo')) ? `url(${getPhoto})` : `url(${require("../../assets/img/communiti-static-image.png")})`,
        backgroundSize: 'cover', // Adjust as needed
        backgroundRepeat: 'no-repeat', // Adjust as needed
        backgroundColor: _.isEmpty(_.get(getExperienceList[0], 'host.photo')) ? "#ccc" : ""
      };

      const onClickSelect = (value) => {
        
        navigate(`/experience-list/${_.get(value, 'id')}?template=${_.get(value, 'template.id')}`)
      }

      const handleClickSeeAllReviews = () => {
        navigate(`/reviews?host=${_.get(getExperienceList[0], 'host.id')}`)
      }

  const render = () => {
    
  return (
    //_.get(getTempData, 'data.details.template.title') && _.get(getTempData, 'data.details.host.handle') === dynamicValue
      // <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px"}}>
      //    <h3>{_.get(getTempData, 'data.details.template.title') && _.get(getTempData, 'data.details.host.handle') === dynamicValue ? _.get(getTempData, 'data.details.template.title') : "Error: 404, session not found"}</h3>
      // </div>
      

      <div>
        {/* {
          !loading && string === _.get(getExperienceList[0], 'host.display_name')
        } */}
          <div className="banner-container">
          <div className='container'>
            {
              !loading ? <div className='image-background' style={inlineStyle}>
  
              </div>: <Skeleton 
              active
            paragraph={{
              rows:7
            }} />
            }
            
              
  
          </div>
      
    </div>
    <div className='rating-container'>
      <div className='container'>
      <div className="row">
        <div className='col-12'>
        {/* review and address */}
        <div style={{marginTop: "16px"}}>
        {
            !loading ?  <div className='rating-card11'>
          
            <div className='experince-title'>{ _.startCase(_.get(getExperienceList[0], 'host.display_name'))}</div>
            <div className='experince-address'>{_.get(getExperienceList[0], 'host.address')}</div>
            {
  _.get(getExperienceList[0], 'host.average_ratings') !== null && _.get(getExperienceList[0], 'host.no_of_ratings') !== null ? (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Rating initialValue={1} readonly={true} iconsCount={1} size={20} style={{marginTop: "-8px", marginRight: "5px"}}/>
      <div className='experience-rating'>{_.round(_.get(getExperienceList[0], 'host.average_ratings'),1)}</div>
      <div className='dot'></div>
      <div className='experience-reviews'>{`${_.get(getExperienceList[0], 'host.no_of_ratings')} Reviews`}</div>
    </div>
  ) : ""
}
          </div> : <Skeleton 
              active
            paragraph={{
              rows:3
            }} />
          }
       
        </div>
        </div>
          <div className='col-lg-8'>
              <div style={{marginTop: "16px"}}>
                {
                  !loading ? <div>
                  {/* <Button disabled className='message-chat-btn' style={{width: "100%"}}>Message</Button> */}
                  </div> : <Skeleton active paragraph={{
                    rows:1
                  }}/>
                }
                
                <div>
                  {
                    !loading ? <div className='description-container'>
                    <div className='template-description-title'>
                      About
                    </div>
                    <div className='template-description'>
                    {_.get(getExperienceList[0], 'host.bio') !== undefined ? _.get(getExperienceList[0], 'host.bio') : ""}
                    </div>
                    </div> : <Skeleton active paragraph={{
                    rows:10
                  }}/>
                  }
                  
                  </div>
                  <div>
                    {
                      !loading ? <div>
                      {
                         _.get(getExperienceList[0], 'host.average_ratings') !== null && _.get(getExperienceList[0], 'host.no_of_ratings') !== null &&  <div className='description-container'>
                         <div className='template-description-title'>
                           Reviews
                         </div>
                         <div style={{display: "flex", flexDirection: "row"}}>
                         <div className='review-rating-star'>
                         <Rating initialValue={1} readonly={true} iconsCount={1} size={20}/>
                         </div>
                         <div style={{marginLeft: "6px", marginTop: "-4px"}}>{_.round(_.get(getExperienceList[0], 'host.average_ratings'),1)}</div>
                         <p style={{marginTop: "7px"}} className='dot'></p>
                         <div style={{marginLeft: "6px", marginTop: "-4px"}}>{`${_.get(getExperienceList[0], 'host.no_of_ratings')} Reviews`}</div>
                         </div>
                         <div className='card' style={{boxShadow: "none"}}>
                           {
                            _.size(getRatingList) > 0 && getRatingList.map((v, i)=> {
                             let src = `${_.get(getRatingMeta, 'photo.path')}/${_.get(getRatingMeta, 'photo.folders.guest')}/${_.get(getRatingMeta, 'photo.sizes.medium')}/${_.get(v, 'guest.photo')}`
                            
                             const fullName = `${_.get(v, 'guest.first_name')} ${_.get(v, 'guest.last_name')}`;
                             const initials = fullName.split(' ').map(name => name[0]).join('');
                             
                             return(
                               <div>
                               <div >
                                 <div className='rating-container' style={{display: "flex", flexDirection: "row"}}>
                                   {
                                     !_.isEmpty(_.get(v, 'guest.photo')) ?  <Avatar src={src} size={40}/> :  <Avatar size={40}>{_.upperCase(initials)}</Avatar>
                                   }
                                 
                                  <div className='rating-name'>
                                 {
                                  
                                   `${_.startCase(_.get(v, 'guest.first_name'))} ${_.startCase(_.get(v, 'guest.last_name'))}`
                                 }
                                 </div>
                                 </div>
                                 <div className='rating-date'>{!_.isEmpty(_.get(v, 'guest.date_of_birth')) ? _.get(v, 'guest.date_of_birth') : "-"}</div>
                                 <div className='rate-star-container'>
                                 <Rating initialValue={_.get(v, 'star_rating')} readonly={true} iconsCount={_.get(v, 'star_rating')} size={20} className='experince-rating-star'/>
                                 </div>
                                 <div className='rate-message'>
                                   {_.get(v, 'message')}
                                 </div>
                                 </div>
                                 </div>
                             )
                            })
                           }
                         </div>
                         <div>
                           <Button onClick={() => handleClickSeeAllReviews()} className='see-all-reviews' style={{fontSize:'1rem'}}>See all reviews</Button>
                           </div>
                         </div>
                      }
                      </div> : <Skeleton active paragraph={{rows: 4}}/>
                    }
                  </div>
                  
                 
                  
              </div>
          </div>
          <div className="col-lg-4" style={{marginTop: "16px"}}>
            {/* <div style={{marginTop: "16px"}}> */}
              <div>
                {
                  !loading ? <div className="card" style={{boxShadow: "none"}}>
                  <div className="card-body">
                  <p className='register-title'>Listings</p>
                  <div>
                    {
                    _.size(getExperienceList) > 0 ? getExperienceList.map((value, index) => {
                       
                        return(
                          <div className="card" style={{boxShadow: "none", borderRadius: "10px", marginTop: "10px" }}>
                          <p className='experince-listing'>{_.get(value, 'template.title')}</p>
                          <div style={{display: "flex", flexDirection: "row"}}>
                          <p className='experince-listing-amount'>{`From $${_.round(_.get(value, 'price'),2)} ${_.get(value, 'meta_details.currency')}`}</p>
                          <p onClick={() => onClickSelect(value)} className='experince-listing-choose'>Select</p>
                          </div>
                          </div>
                        )
                      })
                      : <div>No experience found</div>
                    }
                    </div>
                 
                  </div>
                </div> : <Skeleton active paragraph={{rows: 5}}/>
                }
                
              </div>
              {/* </div> */}
              </div>
          </div>
      </div>
    </div>
      
    </div>
      
  )
  }
  return render();
}

const mapStateToProps = state => {
  return {
     

  }
}

const mapDispatchToProps = dispatch => ({
  ratingListAPI: details => dispatch(global.redux.action.experience.experienceRatingList(details)),
  experienceListAPI: details => dispatch(global.redux.action.experience.list(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(UserHandleExperience)