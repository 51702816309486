import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateFinancesState;
// host actions

export default {
    // get experience templates of logged in host
    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/orders/list`
                },
                actionType: actionType.finances.list,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    ratingList: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/experiences/rating-list`
                },
                actionType: actionType.finances.ratingList,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    view: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/orders/view`
                },
                actionType: actionType.finances.view,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}