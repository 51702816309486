import _ from 'lodash';

import chatKittyInstance from '../instance';

const start =async(criteria)=>{
    const userData =_.get(criteria, 'userData', {});
    const callback =_.get(criteria, 'callback', ()=>{});
    try {
        const result = await chatKittyInstance.startSession({
            username: _.get(userData, 'email', null),
            authParams: {
              password: _.get(userData, 'password', null),
            }
        });
        
        chatKittyInstance.updateCurrentUserDisplayPicture({
          file: {
            url:  _.get(userData, 'displayPictureUrl', null),
            name: _.get(userData, 'displayName', null),
            contentType: 'image/jpeg',
            size: 123115,
          },
        });
          chatKittyInstance.updateCurrentUser((user) => {
            
          user.properties = {
             ...user.properties,
             'id': _.get(userData, 'id', null),
          };
          return user;
       });
       
        callback(_.get(result, 'succeeded', false), result);
    } catch (e) {
        callback(false, e);
    }
};

const end =(criteria={})=>{
  return new Promise(async resolve => {
    const callback =_.get(criteria, 'callback', ()=>{});
    try {
        await chatKittyInstance.endSession();
        callback(true);
        resolve(true);
      } catch (e) {
        console.error(e);
        callback(false, e);
        resolve(false, e);
      }
  });
};

export default { start, end };