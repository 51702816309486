import React from 'react';
import { Skeleton } from 'antd';
import "./style.css"

const Loader = () => {
  return (
    <div>
        <div className="banner-container">
          <div className='container'>
            <Skeleton active paragraph={{
                rows: 7
              }}/>
          </div>
      
    </div>
    <div className='rating-container'>
    <div className='container'>
    <div className="row">
    <div className='col-12'>
    <div style={{marginTop: "16px"}}>
    <Skeleton active paragraph={{rows: 3}}/>
    </div>
    </div>
    <div className='col-lg-8'>
    <div style={{marginTop: "16px"}}>
    <Skeleton active paragraph={{rows: 30}}/>
    </div>
    </div>
    <div className="col-lg-4" style={{marginTop: "16px"}}>
    <Skeleton active paragraph={{rows: 20}}/>
    </div>
    </div>
    </div>
    </div>
    </div>
  )
}

export default Loader