import appPages from './app-pages';

export default [
    {
        key: "login",
        element: <appPages.loginPage />,
        path:'/communiti/login',
        exact:true
    },
    {
        key: "ViewSession",
        element: <appPages.ViewSession />,
        path:'/session/:id',
        exact:true
    },
    {
        key: "UserHandleExperience",
        element: <appPages.UserHandleExperience />,
        path:'/:dynamicValue/session/:id',
        exact:true
    },

    {
        key: "ExperienceListHandle",
        element: <appPages.ExperienceListHandle />,
        path:'/:handleValue',
        exact:true,
        hash:'#handleValue',
    },
    {
        key: "SocialAuthentication",
        element: <appPages.SocialAuthentication />,
        path:'/social-login',
        exact:true
    },
    {
        key: "SeeAllReviews",
        element: <appPages.SeeAllReviews />,
        path:'/reviews',
        exact:true
    },

    {
        key: "ExperienceListing",
        element: <appPages.ExperienceListing />,
        path:'/experience-list/:id',
        exact:true
    },
    {
        key: "PayInvoice",
        element: <appPages.PayInvoice />,
        path:'/pay/invoice/:id',
        exact:true
    },
    {
        key: "PaymentSuccess",
        element: <appPages.PaymentSuccess/>,
        path:'/success',
        exact:true
    },

   
];
