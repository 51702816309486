import { Button as AntButton, Divider, Modal } from 'antd';
import { IoMdClose } from "react-icons/io";
import './style.css'
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { TbArrowBackUp } from 'react-icons/tb';
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash'
import EmailSignUp from './signup'
import EmailSignIn from './signin'
import ResetPassword from './reset-password';
import VerifyAccountScreen from './verify-account';
const { useState, useEffect } = require('react');

const useStyles = makeStyles({
       root: {
              // input label when focused
              "& label.Mui-focused": {
                     color: "#FF692E"
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },
              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                     "&.Mui-focused fieldset": {
                            borderColor: "#FF692E"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#FF692E"
                     },
              }
       },
       focused: {
              '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                     borderColor: '#FF692E',  // Change this to the desired focused border color
              },
              "& label.Mui-focused": {
                     color: "#FF692E"
              },
       },

});

const EmailLogin = (props) => {
       const classes = useStyles();
       const [isModalOpen, setIsModalOpen] = useState(false);
       const [email, setEmail] = useState("");
       const [emailError, setEmailError] = useState(false);
       const [formStep, setformStep] = useState(1)
const [userDetails, setuserDetails] = useState({});
const [dotPulseBtnLoader, setDotPulseBtnLoader] = useState(false);
       useEffect(() => {
              setIsModalOpen(props?.isModalOpen?.visible)
              setformStep(props?.isModalOpen?.form)
       }, [props?.isModalOpen])



       const checkProfile = (email) => {
              setDotPulseBtnLoader(true);
              props.guestProfileCheck({
                     params: {
                            user_type: 0,
                            type: 'email',
                            data: email
                     },
                     callback: (res, data) => {
                            setDotPulseBtnLoader(false);
                            if(_.get(data, 'data.details.verified') === false){
                                   props?.onChangeFormModal({
                                   visible:true,
                                   form:5
                                   });
                                   setformStep(5)
                            }
                            if (_.get(data, 'data.details.id') && _.get(data, 'data.details.verified') === true) {
                                   setuserDetails({..._.get(data, 'data.details')})
                                   props?.onChangeFormModal({
                                          visible:true,
                                          form:3

                                   })
                                   setformStep(3)
                            } else if (_.get(data, 'error.error.message')) {
                                   props?.onChangeFormModal({
                                          visible:true,
                                          form:2

                                   })
                                   setformStep(2)
                            }

                     }
              })
       }
const titleModal =[
       '',
'Please login or signup to get started',
'Signup with your email',
'Login with your email',
'Reset your password',
'Verify your account'
]

       const modalTitle = (
              <div style={{ display: "flex", flexDirection: "column", paddingLeft: '24px', paddingRight: '24px' }}>
                     <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                            <img src={require('../../assets/img/communiti-logo.png')} alt="Your Image" style={{ width: "200px" }} />
                            {formStep==1 &&<IoMdClose style={{ height: '24px', width: '24px', cursor: 'pointer' }} onClick={() => {
                                   setEmail('')
                                   props?.onCloseModal()
                            }} color='#667085' />}

                     </div>
                     <div className='email-modal-title' >{titleModal[formStep]}</div>

              </div>
       );


       const handleEmailChange = e => {

              setEmail(e.target.value);


       };
       const validateEmail = (email) => {

              return email.match(
                     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
       };
       return <Modal
              closeIcon={null}
              title={modalTitle}
              open={isModalOpen}
              onOk={() => {
                     setIsModalOpen(false)
              }}
              // onCancel={() => {
              //        setIsModalOpen(false)
              // }}
              footer={null}
              rootClassName='email-modal-content'
       >

       {formStep==1?
       <Box component="form"  noValidate>
              <div >
                     <Divider style={{ color: '#EAECF0', width: 1 }} />
                     <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
                            <TextField
                            onKeyDown={(ev) => {
                                   if (ev.key === 'Enter') {
                                     // Do code here
                                     ev.preventDefault();
                                   }
                                 }}
                                   value={email}
                                   onChange={handleEmailChange}
                                   inputProps={{
                                          className: 'email-input-mui',

                                   }} required id="outlined-required" label="Email"
                                   className={classes.root}
                                   style={{ width: '100%' }}
                                   error={emailError}
                                   helperText={emailError ? "Please enter a valid email" : ""}
                            />
                     </div>
                     <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px', paddingLeft: '24px', paddingRight: '24px' }}>
                                   <AntButton
                                          className='ant-back-button'
                                          onClick={() => {
                                                 props?.onCloseModal(true);
                                                 setDotPulseBtnLoader(false);
                                          }}

                                          style={{
                                                 width: "50%",
                                                 height: "44px",
                                                 backgroundColor: "#FFF",
                                                 color: "#344054",
                                                 borderRadius: '12px',
                                                 fontWeight: 600,
                                                 fontSize: '1rem',
                                                 marginRight: '12px'

                                          }}
                                   ><TbArrowBackUp size={22} color={"#344054"} style={{ marginTop: -3 }} /> back</AntButton>
                                  {!dotPulseBtnLoader && <Button
                                          disabled={validateEmail(email) == null ? true : false}
                                          style={{
                                                 width: "50%",
                                                 height: "44px",
                                                 backgroundColor: validateEmail(email) == null ? "#D0D5DD" : "#FF692E",
                                                 color: "#FFFFFF",
                                                 borderRadius: '12px',
                                                 fontWeight: 600,
                                                 fontSize: '1rem',
                                                 textTransform: 'none'


                                          }}
                                          onClick={() => {
                                                 checkProfile(email)

                                          }}

                                          fullWidth

                                   >
                                          Next
                                   </Button>}

                                   { dotPulseBtnLoader && <div style={{
                                          width: "50%"
                                   }} className='otp-loading-btn-container'>
                                          <div className='dot-pulse'></div>
                                          </div>
                                          }
                            </div>
                     </div>


              </div>
       </Box>:formStep==2? 
       <EmailSignUp formStep={formStep} 
       onOpenLoginModal={()=>{
              props?.onOpenLoginModal();
              setEmail('')
       }}oncloseModal={()=>{
              props?.onCloseModal()
       }} onBackForm ={()=>{
              props?.onChangeFormModal({
                     visible:true,
                     form:1

              })
              setformStep(1)
       }}
       onNextForm ={(value)=>{
              props?.onChangeFormModal({
                     visible:true,
                     form:value

              })
       }}
       userDetails={{email:email}}/>:formStep==3?
       <EmailSignIn formStep={formStep} 
       onOpenLoginModal={()=>{
              props?.onOpenLoginModal();
              setEmail('')
       }}
       oncloseModal={()=>{
              props?.onCloseModal()
       }} onBackForm ={()=>{
              props?.onChangeFormModal({
                     visible:true,
                     form:1

              })
              setformStep(1)
       }}
       onNextForm ={(value)=>{
              props?.onChangeFormModal({
                     visible:true,
                     form:value

              })
       }}
       onResetPasswordModal ={(value)=>{
              props?.onChangeFormModal({
                     visible:true,
                     form:value

              })
              setformStep(value)
       }}

       userDetails={{email:email,...userDetails}}/>:formStep==4?
       <ResetPassword formStep={formStep}
       
       onOpenLoginModal={()=>{
              props?.onOpenLoginModal();
              setEmail('')
       }} oncloseModal={()=>{
              props?.onCloseModal()
       }} onBackForm ={(email)=>{
              props?.onChangeFormModal({
                     visible:true,
                     form:3

              })
              setformStep(3)
       }}
       onNextForm ={(value)=>{
              props?.onChangeFormModal({
                     visible:true,
                     form:value

              })
       }}
       userDetails={{email:email,...userDetails}}/>:formStep===5 ? <VerifyAccountScreen 
       onOpenLoginModal={()=>{
              props?.onOpenLoginModal();
              setEmail('')
       }}
       userDetails={{email:email,...userDetails}}
       /> : null}



       </Modal>

}
const mapStateToProps = state => {
       return {


       }
}

const mapDispatchToProps = dispatch => ({

       guestProfileCheck: details => dispatch(global.redux.action.guest.checkProfile(details)),


});
export default connect(mapStateToProps, mapDispatchToProps)(EmailLogin)
