import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateOtpLoginState;
// guest actions

export default {
    sendOtp: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/oauth/otp`
                },
                actionType: actionType.otpLogin.sendOtp,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}