import React, {useState, useEffect} from 'react';
import { Button, Modal } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import {CiCircleInfo} from "react-icons/ci";
import {PiQuestionThin} from "react-icons/pi"

import "./style.css";
import StickyBox from "react-sticky-box";
import CommonRightContainer from '../common-right-container';
import AuthFooter from '../../components/auth-footer'

const RegistrationProfile = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate();
    const [getTempData, setTempData] = useState({});
    const [getScheduledDate, setScheduledDate] = useState([]);
    const [pastDateCount, setPastDateCount] = useState(0);
    const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
    const [isCreation, setIsCreation] = useState(false)
    const [isSelectedProfileSelf, setIsSelectedProfileSelf] = useState(false);
    const [isSelectedProfileChild, setIsSelectedProfileChild] = useState(false);
    const [getProfileList, setProfileList] = useState([]);
    const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);

    const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
    const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');

    useEffect(() => {
        getExperienceDetails();
        listGuestProfile();
    }, []);
    useEffect(() => {
        let count = 0;
    
        getScheduledDate.forEach((dateString) => {
          const date = moment.tz(dateString, timeZone);
          if (date.isBefore(today)) {
            count++;
          }
        });
    
        setPastDateCount(count);
      }, [getScheduledDate, today, timeZone]);

    const getExperienceDetails = () => {
        props.viewSessionAPI({
            params: {
              id: searchParams.get("id"),
              expand: "template,template_photos,host",
              is_price_porated: 1
            },
            callback: (res, value) => {
              
              if(res){
                setTempData(value)
                  let start_date_item = _.get(value, 'data.details.experience_date');
                  let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
                  
                  let concated_array = scheduled_date_items
                  setScheduledDate(concated_array.filter(item => item !== "" && item !== null))
                
              }
            }
          })
    };

    const showSessionDateModal = () => {
        setIsSessionDateModalOpen(true)
      };
      const scheduleHandleOk = () => {
        setIsSessionDateModalOpen(false)
      }
    
      const scheduleHandleCancel = () => {
        setIsSessionDateModalOpen(false)
      };

      const listGuestProfile = () => {
        props.guestProfileListAPI({
            params:{
                status: 1
            },
            callback: (res, data) => {
                
                if(_.size(_.get(data, 'data.items')) > 0){
                  setProfileList(_.get(data, 'data'))
              } else {
                setProfileList([])
              }
            }
        })
      };

      const createProfile = () => {
        setIsCreation(true)
      }

      const discardProfile = () => {
        setIsCreation(false);
        if(isSelectedProfileSelf){
            setIsSelectedProfileSelf(false);
        } else if(isSelectedProfileChild) {
            setIsSelectedProfileChild(false);
        }
      }

      const selectProfileSelf = () => {
        setIsSelectedProfileSelf(true);
        if(isSelectedProfileChild){
            setIsSelectedProfileChild(false);
        };
      }
      const selectProfileChild = () => {
        if(isSelectedProfileSelf){
            setIsSelectedProfileSelf(false);
        };
        setIsSelectedProfileChild(true);
      };

      const saveProfile = () => {
          navigate(`/create-profiles?profile=${isSelectedProfileSelf ? "self": "child"}&id=${searchParams.get("id")}`)
        
        
      };

      const showProratedModal = () => {
        setIsProratedModalOpen(true);
      }
    
      const handleProratedOk = () => {
        setIsProratedModalOpen(false)
      }
    
      const handleProratedCancel = () => {
        setIsProratedModalOpen(false)
      }

      

    const render = () => {
        return(
            <div>
                <div className="banner-container">
                  <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div>
                            <p className='registration-title'>Registration</p>
                            <p className='registration-profile-title'>Registration Profile</p>
                            {
                              _.size(_.get(getProfileList, 'items')) > 0 ? "" : <p className='login-text'>You do not have any saved registration profiles. Create an athlete profile to register with.</p>
                            }
                            
                            <Button 
                            onClick={() => createProfile()} 
                            style={{width: "100%", 
                            height: "40px", 
                            display: !isCreation ? "block" : "none"}}
                            >Create an athlete profile</Button>
                            <Button
                            onClick={() => selectProfileSelf()}
                            style={{
                                width: "100%", 
                                height: "40px", 
                                marginTop: "8px", 
                                display: isCreation ? "block" : "none", 
                                borderColor: isSelectedProfileSelf ? "#EF6820" : "#EAECF0", 
                                color: isSelectedProfileSelf ? "#EF6820" : "#1D2939"
                            }}
                            >This profile is for me</Button>
                            <Button
                            onClick={() => selectProfileChild()}
                            style={{width: "100%", 
                            height: "40px", 
                            marginTop: "8px", 
                            display: isCreation ? "block" : "none",
                            borderColor: isSelectedProfileChild ? "#EF6820" : "#EAECF0", 
                            color: isSelectedProfileChild ? "#EF6820" : "#1D2939"
                        }}
                            >This profile is for my child</Button>

                           <div>
                            <Button
                            style={{width: "100%", 
                            height: "40px", 
                            marginTop: "40px",
                             backgroundColor: '#EAECF0',
                              color: "#1D2939", 
                              display: isCreation ? "block" : "none"
                            }}
                            onClick={() => discardProfile()}
                            >Discard profile</Button>
                           
                            <Button
                            onClick={() => saveProfile()}
                            disabled={!isSelectedProfileSelf && !isSelectedProfileChild ? true : false}
                            style={{
                                width: "100%", 
                            height: "40px", 
                            marginTop: "8px", 
                            backgroundColor: !isSelectedProfileSelf && !isSelectedProfileChild ? "#EAECF0" : '#EF6820',
                             color: !isSelectedProfileSelf && !isSelectedProfileChild ? "#1D2939" : "#FFFFFF", 
                             display: isCreation ? "block" : "none"
                            }}
                            >Save profile</Button>
                            </div>
                            
                            </div>
                            <p className='registration-profile-title'>Additional Information</p>
                            {
                             _.size( _.get(getTempData, 'data.details.template.clauses')) > 0 && <p className='registration-profile-title'>Clauses</p>
                            }
                            
                        </div>
                        <div className="col-lg-4">
            {/* <div style={{marginTop: "16px"}}> */}
            <StickyBox offsetTop={16} offsetBottom={20}>
            <CommonRightContainer/>
            </StickyBox>
              {/* </div> */}
              </div>
                    </div>
                  </div>
              
            </div>
            <div>
    <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
    <p className='register-location'>Session Schedule</p>
                 
                  <div>
      {_.size(getScheduledDate) > 0 && getScheduledDate.map((item, index) => {
        const date = moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone'));
        const isPastDate = date.isBefore(today);
        const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  })}
    </div>
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session has already started and the price has been prorated. View the schedule to see the dates remaining
        </div>
      </Modal>
      </div>
      <AuthFooter/>
            </div>
        )
    };
    return render();
 
}

const mapStateToProps = state => {
    return {
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
    experienceComputeOrderAPI: details => dispatch(global.redux.action.experience.computeOrder(details)),
    guestProfileListAPI: details => dispatch(global.redux.action.guest.profilesList(details)),
    guestProfileListAPI: details => dispatch(global.redux.action.guest.profilesList(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(RegistrationProfile)