import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateAccountState;
// account actions

export default {
    isLogin: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/isLoggedIn`
                },
                actionType: actionType.account.isLogin,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    // get profile data of the current logged in account
    socialLogin: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/login-auth`
                },
                actionType: actionType.account.socialLogin,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    phoneLogin: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/phone-login`
                },
                actionType: actionType.account.phoneLogin,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    coacheprofile: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/coaches/profile`
                },
                actionType: actionType.account.profile,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    token: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `oauth/token`
                },
                actionType: actionType.account.token,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
    stripeOnboardWeb: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `hosts/stripe-onboard-web`
                },
                actionType: actionType.account.stripeOnboardWeb,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true

            };
            global.api.requestAPI(requestOptions);
        }
    },
    logout: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/logout`
                },
                actionType: actionType.account.logout,
                reducerName,
                dispatch,
                componentRequest

            };
            global.api.requestAPI(requestOptions);
        }
    },
}