import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateCouponsState;
// host actions

export default {
    // checks whether the coupon can get applied or not for a specific coupon name
    apply: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/coupons/apply`
                },
                actionType: actionType.coupons.apply,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    }
}