const { default: coreUnitsRedux } = require("../../redux");

const { default: coreUnitsAPI } = require("../../api");

const { default: coreUnitsUtils } = require("../../utils");

const { default: coreUnitsChatSystem } = require("../../chat-system");

global.redux = {
    ...coreUnitsRedux
};

global.api = {
    ...coreUnitsAPI
};

global.utils = {
    ...coreUnitsUtils
};

global.chatSystem = {
    ...coreUnitsChatSystem
};


export default global