import { Button as AntButton, Divider, Modal, notification } from 'antd';
import { IoMdClose } from "react-icons/io";
import './style.css'
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { TbArrowBackUp } from 'react-icons/tb';
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import EmailOtpVerification from './email-otp'
import { useNavigate } from 'react-router';
const { useState, useEffect } = require('react');

const useStyles = makeStyles({
       helperTextError:{
              color: '#F04438 !important',
              fontFamily: `Inter, sans-serif !important`,
              fontSize: '0.875rem !important',
              fontWeight: 400,
              lineHeight: '16px' /* 114.286% */
       },
       helperText: {
              color: '#475467 !important',
              fontFamily: `Inter, sans-serif !important`,
              fontSize: '0.875rem !important',
              fontWeight: 400,
              lineHeight: '16px' /* 114.286% */
       },
       root: {
              '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                     borderColor: '#FB2424', // Error border color
                     border: '1px solid var(--Error-500, #F04438)'
                   },
              // input label when focused
              "& label.Mui-focused": {
                     color: "#FF692E"
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              "& .MuiInputBase-input.Mui-disabled": {
                     WebkitTextFillColor: "#475467",

                     "& > fieldset": {
                            borderColor: "red"
                     }
              },
              '& .MuiInputBase-input': {
                     
                     '&.Mui-error::placeholder': {
                       color: '#F04438', // Placeholder color in error state
                     },
                   },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                     "&.Mui-focused fieldset": {
                            borderColor: "#FF692E"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#FF692E"
                     },

              },


              '& .MuiInputBase-root.Mui-disabled': {

                     "&:focus fieldset": {
                            borderColor: "#D0D5DD"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#D0D5DD"
                     },
                     backgroundColor: 'var(--Gray-50, #F9FAFB)', // Change to your desired background color
                     borderColor: '#c0c0c0', // Change to your desired border color
              },
              '& .MuiInputBase-root.Mui-disabled:hover': {
                     borderColor: '#c0c0c0', // Change to your desired focused border color
              },
       },


});

const EmailSignIn = (props) => {
       const navigate = useNavigate()

       const [userDetailsData, setuserDetailsData] = useState({})
       const [showPassword, setShowPassword] = useState(false);
       const classes = useStyles();
       
       const [isModalOpen, setIsModalOpen] = useState(false);
       const [email, setEmail] = useState("");
       const [emailError, setEmailError] = useState(false);
       const [formStep, setformStep] = useState(1)
       const [password, setpassword] = useState('')
       const [first_name, setfirst_name] = useState('')
       const [last_name, setlast_name] = useState('')
       const [passwordError, setpasswordError] = useState(false)
       const [showOTP, setshowOTP] = useState(false)
       const [passwordCheckList, setpasswordCheckList] = useState([
              {
                     name: 'At least 1 uppercase character',
                     success: false
              },
              {
                     name: 'At least 1 number  ',
                     success: false
              },

              {
                     name: 'At least 1 special character - (!@#$%&*)',
                     success: false
              },
              {
                     name: 'At least 10 characters long',
                     success: false
              },

       ]);

       const [dotPulseBtnLoader, setDotPulseBtnLoader] = useState(false);

       useEffect(() => {
              setIsModalOpen(props?.isModalOpen?.visible)
              setformStep(props?.isModalOpen?.form)
       }, [props?.isModalOpen])

       const handleClickShowPassword = () => setShowPassword((show) => !show);

       const handleMouseDownPassword = (event) => {
              event.preventDefault();
       };

       const loginEmail = (email) => {
              setDotPulseBtnLoader(true);
              props.loginEmail({
                     body: {
                            grant_type: 'password',
                            password: password,
                            username: props?.userDetails?.email
                     },
                     callback: (res, data) => {
                            setDotPulseBtnLoader(false);
                            
                            if (_.get(data, 'isLoggedIn')==true) {
                                   localStorage.setItem('login_methods', 'Email');
                                   notification.success({
                                          message:"Success",
                                          description:'Login successful'
                                          })
                                          
                                          props?.oncloseModal()
                                          let url = localStorage.getItem('redirect_url')
                                          if(url){
                                                 setTimeout(() => {
                                                       
                                                        navigate(url) 
                                                 }, 400);
                                          }

                                          
                            } else  {
                                   setpasswordError(true)

                            }

                     }
              })
       }


       const modalTitle = (
              <div style={{ display: "flex", flexDirection: "column", paddingLeft: '24px', paddingRight: '24px' }}>
                     <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                            <img src={require('../../assets/img/communiti-logo.png')} alt="Your Image" style={{ width: "200px" }} />
                            <IoMdClose style={{ height: '24px', width: '24px', cursor: 'pointer' }} onClick={() => {

                            }} color='#667085' />

                     </div>
                     <div className='email-modal-title' >Please login or signup to get started</div>

              </div>
       );


       const handleEmailChange = e => {

              setEmail(e.target.value);


       };
       const validateEmail = (email) => {

              return email.match(
                     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
       };

      const passwordChange =(e)=>{
       
       let checkList =[...passwordCheckList]
       if (!/[A-Z]/.test(e)) {
              checkList[0].success=false
            }else{
              checkList[0].success=true
            }
            if (!/\d/.test(e)) {
              checkList[1].success=false
            }else{
              checkList[1].success=true
            }
            if (!/[!@#$%^&*]/.test(e)) {
              checkList[2].success=false
            }else{
              checkList[2].success=true
            }
       if (e?.length < 10) {
              checkList[3].success=false
            }else{
              checkList[3].success=true
            }
      
            
      return checkList
      
      } 

      const conditionNext =()=>{
let checkList =_.compact(_.map(passwordChange(password),'success'))

       if(!first_name){
              
return true
       }else if(!last_name){
              
return true
       } else if(checkList.length!=4){
              
              return true
       } else{
              return false
       }
      }

      
       return  showOTP==false? 
       <div >
              <Divider style={{ color: '#EAECF0', width: 1 }} />
              <div className='email-modal-des' style={{ padding: '24px', paddingTop: '0px' }}>
              Welcome back, {_.startCase(props?.userDetails?.first_name)}!
              </div>
              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
                     <TextField
                            disabled={true}
                            onKeyDown={(ev) => {
                                   if (ev.key === 'Enter') {
                                     // Do code here
                                     ev.preventDefault();
                                   }
                                 }}
                            value={props?.userDetails?.email}

                            inputProps={{
                                   className: 'email-input-mui',



                            }} required id="outlined-required" label="Email"
                            className={classes.root}
                            style={{ width: '100%' }}
                            // helperText="We’ll send you a verification code after this step"
                            InputProps={{ endAdornment: <div className='support-text-email'
                            onClick={()=>{
                                   setfirst_name('');
                                   setlast_name('');
                                   setpassword('');
                                   props?.onBackForm()}}
                            style={{ cursor: 'pointer' }}>Change</div>, }}
                            FormHelperTextProps={{
                                   classes: {
                                          root: classes.helperText,
                                   },
                            }}
                     />
              </div>
            
              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
                     <FormControl error={passwordError} className={classes.root} sx={{ width: '100%' }} variant="outlined" >
                            <InputLabel error={passwordError} className={passwordError?classes.helperTextError:''} required htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                             onKeyDown={(ev) => {
                                   if (ev.key === 'Enter') {
                                     // Do code here
                                     ev.preventDefault();
                                   }
                                 }}
                            error={passwordError}
                                   autoComplete="off"
                                   required
onChange={(e)=>{
       setpassword(e.target.value)
       setpasswordError(false)
}}
                                   id="outlined-adornment-password"
                                   type={showPassword ? 'text' : 'password'}
                                   inputProps={{ className: 'email-input-mui' }}
                                   endAdornment={
                                          <InputAdornment position="end">
                                                 <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                 >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                 </IconButton>
                                          </InputAdornment>
                                   }
                                   label="Password"
                            />
                            <FormHelperText className={classes.helperTextError} error={passwordError}>
        {passwordError ? 'Incorrect password. Please try again' : ''}
      </FormHelperText>
                     </FormControl>
              </div>
             
              <div>
                     <div>
                     <div className='email-resend-otp-text' style={{paddingLeft:'24px',paddingRight:'24px'}}>
                     Forgot your password? {<span 
              onClick={()=>{
                   props?.onResetPasswordModal(4)  
              }}
              style={{textDecorationLine:'underline',cursor:'pointer'}}>Reset password</span>}
              </div>
                     </div>
                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '24px', paddingLeft: '24px', paddingRight: '24px' }}>
                            <AntButton
                                   className='ant-back-button'
                                   onClick={() => {
                                          setEmail('');
                                          setpassword('');
                                          props.onBackForm(props?.userDetails?.email);
                                          setDotPulseBtnLoader(false);
                                   }}

                                   style={{
                                          width: "50%",
                                          height: "44px",
                                          backgroundColor: "#FFF",
                                          color: "#344054",
                                          borderRadius: '12px',
                                          fontWeight: 600,
                                          fontSize: '1rem',
                                          marginRight: '12px'

                                   }}
                            ><TbArrowBackUp size={22} color={"#344054"} style={{ marginTop: -3 }} /> back</AntButton>
                            { !dotPulseBtnLoader && <Button
                                   disabled={!password?true:false}
                                   style={{
                                          width: "50%",
                                          height: "44px",
                                          backgroundColor:!password?  "#D0D5DD": "#FF692E",
                                          color: "#FFFFFF",
                                          borderRadius: '12px',
                                          fontWeight: 600,
                                          fontSize: '1rem',
                                          textTransform: 'none'


                                   }}
                                   onClick={() => {
                                        
                                         loginEmail()
                                   }}

                                   fullWidth

                            >
                                   Login
                            </Button>}

                            { dotPulseBtnLoader && <div style={{
                                          width: "50%",
                                          height: '44px'
                                   }} className='otp-loading-btn-container'>
                                          <div className='dot-pulse'></div>
                                          </div>
                                          }
                     </div>
              </div>


       </div>:
       <EmailOtpVerification oncloseAllModal ={()=>props?.oncloseModal()} userDetails={userDetailsData} 
        
       />


}
const mapStateToProps = state => {
       return {


       }
}

const mapDispatchToProps = dispatch => ({

       guestProfileCheck: details => dispatch(global.redux.action.guest.checkProfile(details)),
       loginEmail: details => dispatch(global.redux.action.guest.loginEmail(details)),


});
export default connect(mapStateToProps, mapDispatchToProps)(EmailSignIn)
