import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateQuestionState;
// guest actions

export default {
    experienceQuestionList: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/experiences/registraton-info/list`
                },
                actionType: actionType.question.experienceQuestionList,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    guestQuestionList: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/experiences/guest/registraton-info/list`
                },
                actionType: actionType.question.guestQuestionList,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    questionList: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/registration-info/question/list`
                },
                actionType: actionType.question.guestQuestionList,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    experienceAnswerCreate: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/registraton-info/create`
                },
                actionType: actionType.question.experienceAnswerCreate,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    experienceAnswerUpdate: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/registraton-info/update`
                },
                actionType: actionType.question.experienceAnswerUpdate,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    guestAnswerCreate: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/guest/registraton-info/create`
                },
                actionType: actionType.question.guestAnswerCreate,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    guestAnswerUpdate: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/guest/registraton-info/update`
                },
                actionType: actionType.question.guestAnswerUpdate,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}