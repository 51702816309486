import _ from "lodash";

import appEnvirontVariables from "../../config/env-variables";
import sendMessageToMultipleRecipient from './send-message-to-multiple-recipient';

const getChatUserData=(profile_details)=>{
    const usernamePrefix =`guest_${_.get(profile_details, 'id', '')}`;
    const usernameSuffix =appEnvirontVariables.CHAT_SYSTEM.EMAIL_DOMAIN;
    const email =`${usernamePrefix}@${usernameSuffix}`;
    const password =`${appEnvirontVariables.CHAT_SYSTEM.CONSTANT_PASSWORD}${usernamePrefix}`;
    const displayName = _.get(profile_details,'display_name')? _.get(profile_details,'display_name'): _.chain(profile_details).pick(['first_name', 'last_name']).map(_.trim).compact().join(' ').startCase().value();
    let displayPictureUrl = _.get(profile_details,'displayPictureUrl')
    
    return { email, password, displayName,displayPictureUrl };
};

const getChannelDisplayName=(channel, chatKittyCurrentUserData)=>{
  
    if (channel.type === 'DIRECT') {
      return  _.chain(channel).get('members', []).filter(member=>!_.eq(member.name, chatKittyCurrentUserData.name)).map('displayName').join(', ').value();
    } else {
      return channel.displayName;
    }
}

export default { getChatUserData, getChannelDisplayName, sendMessageToMultipleRecipient};