import chatKitty from './chatkitty';
import tools from './tools';
import authentication from './authentication';
import components from './components';

// Initialyzing chat system variables
export default {
    chatKitty,
    tools,
    authentication,
    components
};