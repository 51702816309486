import React, {useState, useEffect} from 'react';
import { Button, Modal, notification, Skeleton } from 'antd';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import {CiCircleInfo} from "react-icons/ci";
import {PiQuestionThin} from "react-icons/pi"
import { gapi } from 'gapi-script';

import "./style.css";
import appEnvirontVariables from "../../core_units/config/env-variables";

const SocialAuthentication = (props) => {
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [getTempData, setTempData] = useState({});
    const [getScheduledDate, setScheduledDate] = useState([]);
    const [pastDateCount, setPastDateCount] = useState(0);
    const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);


    const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
    const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');

    useEffect(() => {
		function start() {
			gapi.client.init({
			  clientId: appEnvirontVariables.GOOGLE.GOOGLE_CLIENT_ID,
			  scope: 'email',
			});
		}
		gapi.load('client:auth2', start);
	  }, []);

    useEffect(() => {
        getExperienceDetails();
    }, []);

    useEffect(() => {
        let count = 0;
    
        getScheduledDate.forEach((dateString) => {
          const date = moment.tz(dateString, timeZone);
          if (date.isBefore(today)) {
            count++;
          }
        });
    
        setPastDateCount(count);
      }, [getScheduledDate, today, timeZone]);

    const getExperienceDetails = () => {
        props.viewSessionAPI({
            params: {
              id: searchParams.get("id"),
              expand: "template,template_photos,host",
              is_price_porated: 1
            },
            callback: (res, value) => {
              
              if(res){
                setTempData(value)
                setLoading(false);
                  let start_date_item = _.get(value, 'data.details.experience_date');
                  let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
                  
                  let concated_array = scheduled_date_items
                  setScheduledDate(concated_array.filter(item => item !== "" && item !== null))
                
              }
            }
          })
    };

    const showSessionDateModal = () => {
        setIsSessionDateModalOpen(true)
      };
      const scheduleHandleOk = () => {
        setIsSessionDateModalOpen(false)
      }
    
      const scheduleHandleCancel = () => {
        setIsSessionDateModalOpen(false)
      };

      const getGoogleToken = (response, signInType) => {
		if (response) {
				let requestParams = {
					type: 'google',
					token: _.get(response, 'credential'),
                    scopes: ['profile', 'email']
				}
				props.login({
					body:requestParams,
					callback: (res,data)=>{
                            
					  if(res==true && _.get(data,'isLoggedIn')==true){
notification.success({
	message:"Success",
	description:'Login successful'
})
// window.location.reload();
					  }
					  
					}
			});
				
			}
		} 

    const showProratedModal = () => {
      setIsProratedModalOpen(true);
    }
  
    const handleProratedOk = () => {
      setIsProratedModalOpen(false)
    }
  
    const handleProratedCancel = () => {
      setIsProratedModalOpen(false)
    }
    const render = () => {
        return (
            <div>
                <div className="banner-container">
                  <div className='container'>
                    
                    
                    <div className='row'>
                        <div className='col-lg-8'>
                            <p className='registration-title'>Registration</p>
                            <p className='registration-title'>Login</p>
                            <p className='login-text'>Please login to proceed:</p>
                            {
                      _.get(getTempData, 'data.details.status') === 0 ? <p className='register-address'>Sorry, this session is unavailable for now</p> : <div>
                      <GoogleLogin
                      size='large'
                      onSuccess={credentialResponse => {
                          getGoogleToken(credentialResponse)
                      }}
                      onError={() => {
                          
                      }}
                      />
                      </div>
                    }
                            
                            <p className='registration-profile-title'>Registration Profile</p>
                            {
                             _.size( _.get(getTempData, 'data.details.template.clauses')) > 0 && <p className='registration-profile-title'>Clauses</p>
                            }
                        </div>
                        <div className="col-lg-4">
            {/* <div style={{marginTop: "16px"}}> */}
              <div>
                {
                  !loading ? <div className="card" style={{boxShadow: "none"}}>
                  <div className="card-body">
                  <p className='register-title'>{_.get(getTempData, 'data.details.template.title')}</p>
                  <p className='register-location'>Location</p>
                  <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none" }}>
                  <p className='register-address'>{_.get(getTempData, 'data.details.address')}</p>
                  </div>
                  <p className='register-location'>Start and end date</p>
                  <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none" }}>
                    {/* moment.tz(_.get(getScheduleDates, "experience_date"), t).format("hh:mm A z") */}
                    <p className='register-address'>{`${moment.tz(_.get(getTempData, 'data.details.experience_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format('MMMM DD, YYYY')}  to  ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format('MMMM DD, YYYY')}`}</p>
                  </div>
                  <p className='register-location'>Start and end time</p>
                  <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none" }}>
                  <p className='register-time'>{`${moment.tz(_.get(getTempData, 'data.details.experience_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'),_.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
                  </div>
                  <p className='register-location'>Session Schedule</p>
                  <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none" }}>
                   
                  <div>
                    {
                      _.get(getTempData, 'data.details.can_late_register') === true  && pastDateCount > 0 ? <div style={{display: "flex", flexDirection: "row"}}>
                        <div className='register-schedule' style={{textDecorationLine: "line-through"}}>
                        { _.size(getScheduledDate) > 1 ? `${_.size(getScheduledDate)} Sessions` : `${_.size(getScheduledDate)} Session`}
                        </div>
                        <div className='register-schedule' style={{marginLeft: "10px"}}>{ _.size(getScheduledDate)-pastDateCount > 1 ? `${_.size(getScheduledDate)-pastDateCount} Sessions` : `${_.size(getScheduledDate)-pastDateCount} Session`}</div><p style={{marginLeft: "8px", marginTop: "5px", cursor: "pointer"}} onClick={() => showProratedModal()}><PiQuestionThin/></p></div>:
                      <p className='register-schedule'>{ _.size(getScheduledDate) > 1 ? `${_.size(getScheduledDate)} Sessions` : `${_.size(getScheduledDate)} Session`}</p>
                    }
                    </div>
                  
                  <div style={{display: "flex", flexDirection: "row"}}>
                  <p className='register-full-schedule' onClick={showSessionDateModal}>See full schedule</p> <div onClick={() => showSessionDateModal()} className='session-info-icon'><CiCircleInfo/></div>
                  </div>
                  </div>
                  <p className='register-location'>Price</p>
                  <div>
                  {
                 !_.isEmpty(_.get(getTempData, 'data.details')) ? _.get(getTempData, 'data.details.can_late_register') === true && pastDateCount > 0 ? <div style={{display: "flex", flexDirection: "row"}}><p className='registration-price-strike'>${`${_.get(getTempData, 'data.details.original_price').toFixed(2)} ${_.get(getTempData, 'data.details.meta_details.currency')}`}</p> <p className='registration-price' style={{marginLeft: "10px"}}>${`${_.get(getTempData, 'data.details.price').toFixed(2)} ${_.get(getTempData, 'data.details.meta_details.currency')}`}</p><p style={{marginLeft: "8px", marginTop: "-5px", cursor: "pointer"}} onClick={() => showProratedModal()}><PiQuestionThin/></p> </div>: <p className='registration-price'>${`${_.get(getTempData, 'data.details.price').toFixed(2)} ${_.get(getTempData, 'data.details.meta_details.currency')}`}</p> : ''
                }
                  </div>
                 
                  <div className="card" style={{ backgroundColor: "#F9FAFB", boxShadow: "none", border: "none", padding: "20px" }}>
                  <Button disabled className='proceed-payment-button'>Pay</Button>
                  </div>
                  </div>
                </div> : <Skeleton active paragraph={{rows: 20}}/>
                }
                
              </div>
              {/* </div> */}
              </div>
                    </div>
                  </div>
              
            </div>
            <div>
    <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
    <p className='register-location'>Session Schedule</p>
                 
                  <div>
      {_.size(getScheduledDate) > 0 && getScheduledDate.map((item, index) => {
        const date = moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone'));
        const isPastDate = date.isBefore(today);
        const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  })}
    </div>
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session has already started and the price has been prorated. View the schedule to see the dates remaining
        </div>
      </Modal>
      </div>
            </div>
          )
    };
    return render();
  
}

const mapStateToProps = state => {
    return {
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
    login: details => dispatch(global.redux.action.account.socialLogin(details))
  });
  export default connect(mapStateToProps, mapDispatchToProps)(SocialAuthentication)