import React, {useState, useEffect} from 'react';
import { Button, Modal, Form, Input, Select, DatePicker, notification, Image } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import {CiCircleInfo} from "react-icons/ci";
import {PiQuestionThin} from "react-icons/pi"
import {SlCheck} from 'react-icons/sl';
import {CiFileOn} from 'react-icons/ci'
import '../../create-profile/style.css'

import "./style.css";
import StickyBox from "react-sticky-box";
import CommonRightContainer from '../../common-right-container';
import AuthFooter from '../../../components/auth-footer'
import { TbArrowBackUp } from 'react-icons/tb';
const { Option } = Select;
const { TextArea } = Input;

const ClauseOperation = (props) => {
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [getTempData, setTempData] = useState({});
    const [getScheduledDate, setScheduledDate] = useState([]);
    const [pastDateCount, setPastDateCount] = useState(0);
    const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
    const [getComputeOrder, setComputeOrder] = useState({});
    const [getProfileDetails, setProfileDetails] = useState({});
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [agreedItems, setAgreedItems] = useState([]);
    const [isContinueEnabled, setIsContinueEnabled] = useState(false);
    const [getClause, setClause] = useState([]);
    const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);


    const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
    const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');

    useEffect(() => {
        getExperienceDetails();
        computeOrder();
        viewGuest();
       
    }, []);

    useEffect(() => {
      if(_.size(props?.defaultsetClause?.clauses)>0){
        setClause(props?.defaultsetClause?.clauses)
      }
    }, [])
    
    useEffect(() => {
        let count = 0;
    
        getScheduledDate.forEach((dateString) => {
          const date = moment.tz(dateString, timeZone);
          if (date.isBefore(today)) {
            count++;
          }
        });
    
        setPastDateCount(count);
      }, [getScheduledDate, today, timeZone]);

      useEffect(() => {
        if(!_.isEmpty(getClause)){
            setIsContinueEnabled(agreedItems.length === getClause.length);
        }
       
      }, [agreedItems]);

    const getExperienceDetails = () => {
        props.viewSessionAPI({
            params: {
              id: searchParams.get("id"),
              expand: "template,template_photos,host",
              is_price_porated: 1
            },
            callback: (res, value) => {
              
              if(res){
                if(_.isEmpty(_.get(value, 'data.details.template.clauses'))){
                  // navigate(`/payment?id=${searchParams.get("id")}&p_id=${props?.orderCreateData?.profile}`)
                }
                setTempData(value)
                if(_.size(_.get(value, 'data.details.template.clauses')) > 0){
                  let a = _.map(_.get(value, 'data.details.template.clauses'),(v,i)=>({...v,checked:false}))
                  if(_.size(props?.defaultsetClause?.clauses)>0){
                    setClause(props?.defaultsetClause?.clauses)
                  }else{
                    setClause( a)
                  }  
                 
                }else{
                  props?.onChangeScreen(4,getClause)
                }
                
                  let start_date_item = _.get(value, 'data.details.experience_date');
                  let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
                  
                  let concated_array = scheduled_date_items
                  setScheduledDate(concated_array.filter(item => item !== "" && item !== null))
                
              }
            }
          })
    };

    const computeOrder = () => {
        props.experienceComputeOrderAPI({
            body:{
                experience: searchParams.get("id"),
                profile: props?.orderCreateData?.profile
            },
            callback: (res, data) => {
                
                if(!_.isEmpty(_.get(data, 'details'))){
                    setComputeOrder(_.get(data, 'details'))
                }
            }
        })
    };

    const showSessionDateModal = () => {
        setIsSessionDateModalOpen(true)
      };
      const scheduleHandleOk = () => {
        setIsSessionDateModalOpen(false)
      }
    
      const scheduleHandleCancel = () => {
        setIsSessionDateModalOpen(false)
      };

      const viewGuest = () => {
        props.viewGuestProfilesAPI({
            params:{
                id: props?.orderCreateData?.profile
            },
            callback: (res, data) => {
                
                if(!_.isEmpty(_.get(data, 'data.details'))){
                    setProfileDetails(_.get(data, 'data.details'))
                }
            }
        })
      };

      const editProfile = () => {
        navigate(`/edit-profile?id=${searchParams.get("id")}&p_id=${_.get(getProfileDetails, 'id')}&from=clauses`);
      }

      const chooseAnotherProfile = () => {
        navigate(`/guest-profiles?id=${searchParams.get("id")}`);
      };

      const handleNameClick = (item,index) => {
        
        if (item.description) {
          setModalContent(item.description);
          setModalVisible(true);
        } else if (item.link) {
          window.open(item.link, '_blank');
        }
      };

      const handleAgreeClick = (item,index) => {
        const clause = [...getClause];
        clause[index].checked = !clause[index].checked;
    setClause(clause);
        
        // setAgreedItems((prevAgreedItems) => {
        //   if (prevAgreedItems.includes(item)) {
        //     return prevAgreedItems.filter((agreedItem) => agreedItem !== item);
        //   } else {
        //     return [...prevAgreedItems, item];
        //   }
        // });
      };

      const proceedNextSection = () => {
        if(searchParams.get("organization")){
          props?.onChangeScreen(4,getClause)
        } else {
          props?.onChangeScreen(4,getClause)
          // navigate(`/payment?id=${searchParams.get("id")}&p_id=${props?.orderCreateData?.profile}&category=${searchParams.get("category")}&type=${searchParams.get("type")}`)
        }
        
      };

      const showProratedModal = () => {
        setIsProratedModalOpen(true);
      }
    
      const handleProratedOk = () => {
        setIsProratedModalOpen(false)
      }
    
      const handleProratedCancel = () => {
        setIsProratedModalOpen(false)
      }

    const render = () => {

      
        return(
          <div>

          <div className="banner-container-profile">
            <div className='container'>
  
              <div className='row'>
                <div className='col-lg-8' style={{marginBottom:'45px'}}>
  
                  
                    <div style={{ display: "flex",flexDirection:'column' }}>
                      <div style={{ display: "flex",marginLeft:'1px' }}>
                        <Image className='step-images-1' style={{ width: '27px', height: '66px' }} preview={false} src={require('../../../assets/img/step-2.png')} />
                        <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                          <div className='profile-heading secondColor-style'>Athlete profile</div>
                           {
                            <div className='profile-description secondColor-style' style={{display:'flex',flexDirection:'row'}}>{_.startCase(_.get(props,'orderCreateData.profile_details.first_name'))+' '+_.startCase(_.get(props,'orderCreateData.profile_details.last_name')) }... <div  onClick={()=>{
                              props?.onChangeScreen(1,getClause)
                             }} style={{textDecorationLine:'underline',cursor:'pointer',marginLeft:'5px'}}>Change</div></div>}
                        </div>
                      </div>
                      {_.size(props?.orderCreateData?.registration_info) >0 &&<div style={{ display: "flex" }}>
                      <Image className='step-images-1' style={{ width: '27px', height: '66px' }} preview={false} src={require('../../../assets/img/step-2.png')} />
                        <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                          <div className='profile-heading secondColor-style'>Additional information</div>
                           {
                            <div
                            onClick={()=>{
                              props?.onChangeScreen(2,getClause)
                             }}
                            className='profile-description secondColor-style' style={{display:'flex',flexDirection:'row'}}>{_.get(_.head(_.get(props,'orderCreateData.registration_info')),'label')}... <div style={{textDecorationLine:'underline',cursor:'pointer',marginLeft:'5px'}}>Change</div></div>}
                        </div>
                      </div>}
                      <div style={{ display: "flex",marginLeft:'-3px' }}>
                        <Image className='step-images-1' style={{ width: '32px', height: '66px' }} preview={false} src={require('../../../assets/img/steps-1.png')} />
                        <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                          <div className='profile-heading secondColor-style'>Clauses</div>
                           {
                            <div className='profile-description secondColor-style'>Please review the clauses below and provide your consent</div>}
                        </div>
                      </div>
                    </div>
                            <div>
                                {
                                    _.size(getClause) > 0 && _.map(getClause,(val, index) => {
                                        return(
                                            <div key={index}>
                                            <div className='question-label-title secondColor-style'>{val.name}</div>
                                            <div className='question-description-des secondColor-style' style={{fontSize:'1rem'}}>Please review the clause below. If you agree to the conditions laid out in the document, please select the “I agree” button to continue.</div>
                                            <div style={{display: "flex", flexDirection: "row",marginTop:'12px',alignItems:'center',cursor:'pointer'}}>
                                            <Image  style={{ width: '40px', height: '40px' }} preview={false} src={require('../../../assets/img/attach-circle.png')} />
                                                <div key={index} onClick={() => handleNameClick(val)} className='clause-mark-details secondColor-style'>{val.name}</div>
                                                </div>
                                                <div style={{marginTop:'12px'}}><Button 
                                                className= {val?.checked ?'agress-btn-active':'agress-btn secondColor-style'}
                                                
                                                onClick={() => handleAgreeClick(val,index)}
                                                // type={}
                                                >{val?.checked ?"Agreed":"I agree"}</Button></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            
                            <div>
                                <Button 
                                style={{
                                width: "100%",
                                height: "50px",
                                marginTop: "8px",
                                backgroundColor: _.size(getClause) == _.size(_.compact(_.map(getClause,'checked')))?"#e85d04": "#F2F4F7", 
                                color:_.size(getClause) == _.size(_.compact(_.map(getClause,'checked')))?"#fff": "#D0D5DD",
                                borderRadius: '12px',
                                fontWeight: 600,
                                fontSize: '1rem'
          
                              }}
                              className='ant-back-button raise-btn'
                               
                                onClick={proceedNextSection}
                                disabled={_.size(getClause) == _.size(_.compact(_.map(getClause,'checked')))?false:true}
                                >Proceed to next section</Button>
                            </div>
                            <Button
className='ant-back-button-container'
style={{
  width: "100%",
  height: "44px",
  backgroundColor: "#FFF",
  color: "#475467",
  borderRadius: '12px',
  fontWeight: 400,
  fontSize: '1rem',
  marginRight: '12px',
  border:'none',
  marginTop:'16px'

}}
                onClick={()=>{
                  props?.onChangeScreen(2,getClause)
                 }}
              ><TbArrowBackUp size={20} color={"#475467"} style={{ marginTop: -3 }} /> back</Button>
                        </div>
                        <div className="col-lg-4">
            {/* <div style={{marginTop: "16px"}}> */}
            <StickyBox offsetTop={16} offsetBottom={20}>
              <CommonRightContainer orderCreateData={props?.orderCreateData}/>
              </StickyBox>
              {/* </div> */}
              </div>
                    </div>
                  </div>
              
            </div>
            <div>
    <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
    <p className='register-location'>Session Schedule</p>
                 
                  <div>
      {_.size(getScheduledDate) > 0 && getScheduledDate.map((item, index) => {
        const date = moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone'));
        const isPastDate = date.isBefore(today);
        const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  })}
    </div>
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session has already started and the price has been prorated. View the schedule to see the dates remaining
        </div>
      </Modal>
      </div>
      <div>
      <Modal
    className='modal-clasuse-style'
        title="Description"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div style={{whiteSpace: "pre-line",fontSize:'1rem',fontFamily: 'Inter, sans-serif',color:'var(--Gray-600, #475467)'}} dangerouslySetInnerHTML={{ __html: modalContent}}/>
        {/* <p style={{whiteSpace: "pre-line"}}>{modalContent}</p> */}
      </Modal>
      </div>
      {/* <AuthFooter/> */}
            </div>
        )
    };
    return render();
 
}

const mapStateToProps = state => {
    return {
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
    experienceComputeOrderAPI: details => dispatch(global.redux.action.experience.computeOrder(details)),
    viewGuestProfilesAPI: details => dispatch(global.redux.action.guest.guestProfilesView(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(ClauseOperation)