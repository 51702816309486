let host = window.location.host;
let hostName = host.split(":");


const baseUrl = `${window.location.protocol}//${hostName[0]}`;

const MODES = {
    DEVELOPMENT: {
        API: {
            BASE_URL: `${baseUrl}`,
            API_URL:'http://3.96.180.154:1337',
            CLIENT_ID: 'J0SOKSPD',
            CLIENT_SECRET: 'btXQd8QthlfFTd29FQFzlMLEK1KxlJh0',
        },
        FACEBOOK: {
            APP_ID: '252849962899986',
            APP_NAME: 'com.studioq.communitiguestapp'
        },
        GOOGLE: {
       GOOGLE_CLIENT_ID:'796497316506-gbvitnnd3n2im3m7p8q04act06en5ebf.apps.googleusercontent.com',
        },
        GOOGLEMAP: {
            LOCATION: 'AIzaSyAIU0XlymmFrHfYvPDn-uPCIcyCdTphABE',
            PRODUCTION_LOCATION_KEY: 'AIzaSyAIU0XlymmFrHfYvPDn-uPCIcyCdTphABE'
        },
        STRIPE: {
            URL: 'https://stripe.com/legal',
            SECRET_KEY: "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",
            PUBLISHABLE_KEY: "pk_live_WZpDEdP2FuPC31AatXEUf7Mp00s2LATvKS",
            KEY: 'pk_live_WZpDEdP2FuPC31AatXEUf7Mp00s2LATvKS',
            BASE_URL: 'https://api.stripe.com',
            SRIPE_MERCHANT_ID:"merchant.com.communitiapp",
            TEST_ENV:false
        },
        COMMUNITI_PRIVACY:{
            URL:'https://www.communiti.app/privacy-policy',
            TERMS:'https://www.communiti.app/terms-of-service'
        },
        CHAT_SYSTEM: {
            CHATKITTY: {
                API_KEY: '47e4b1e4-0c84-4ed5-b2f7-3a679a937ed9',
                CLIENT_ID: '47e4b1e4-0c84-4ed5-b2f7-3a679a937ed9',
                CLIENT_SECRET: 'c132dca0-661d-435a-b381-b3f7d15f1508',
                API_BASE_URL: 'https://api.chatkitty.com/v1/applications/12902',
                API_AUTHORIZATION_URL: 'https://authorization.chatkitty.com/',
            },
            EMAIL_DOMAIN: 'communiti.app',
            CONSTANT_PASSWORD: 'fuwl9VffEp6QZhxW',
            USERNAME_PREFIX: 'communiti_athlete_production'
        },
        FIREBASE: {
            apiKey: "AIzaSyA4C8ji42pcBx83hybwZR4h72tWrzKDqQc",
            authDomain: "communiti-production-app-chat.firebaseapp.com",
            projectId: "communiti-production-app-chat",
            storageBucket: "communiti-production-app-chat.appspot.com",
            messagingSenderId: "170742844089",
            appId: "1:170742844089:android:64637967ff48b0b9ea2968",
            measurementId: "G-Z7S0YTDTVJ"
        },
        ONESIGNAL:{
            APP_ID:"fc0899d8-f39c-458b-a546-14946c3b989f"
        },
        SENTRY_CONFIG:{
            DSN: "https://95066f5e5d64149381e92f168ea1899a@o1397951.ingest.us.sentry.io/4507311738126336",
        }
        
    }
};

const getEnvVariables = ()=>{
    return MODES.DEVELOPMENT;
};

export default {
    ...getEnvVariables()
};