import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import {
	Form,
	Input,
	Button,
	Image,
	notification,
} from "antd";
import {GoogleOutlined} from '@ant-design/icons'
import "./login.css";
import loginBG from "../../assets/img/login-bg.png";
import logo from "../../assets/img/logo.png";
import _ from 'lodash';
import { connect } from "react-redux";
// import GoogleLogin from 'react-google-login'
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import appEnvirontVariables from "../../core_units/config/env-variables";
import queryString from "query-string";
import {FacebookOutlined} from "@ant-design/icons";
import { loadAuth2, loadAuth2WithProps, loadClientAuth2,gapi } from 'gapi-script';
import { GoogleLogin } from '@react-oauth/google';
// import { useGoogleLogin } from '@react-oauth/google';


const LoginPageComponent = (props) => {
	
	let history = useNavigate();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		function start() {
			gapi.client.init({
			  clientId: appEnvirontVariables.GOOGLE.GOOGLE_CLIENT_ID,
			  scope: 'email',
			});
		}
		gapi.load('client:auth2', start);
	  }, []);

	
	
	
	const onLogin = (values) => {
values.username = lowercaseFirstLetter(_.get(values,'username'))
		setLoading(true)
		props.login({
			body: { ...values },
			callback: (response, data) => {
				setLoading(false)
				if (response == true) {
				} else {

				}

			}
		})
		
	}
	const lowercaseFirstLetter = (str) =>{

		// converting first letter to lowerCase
		const lowerCase = str.replace(/^./, `${str[0]}`.toLowerCase());
	
		return lowerCase;
	
	}
	const getGoogleToken = (response, signInType) => {
		// 
		if (response) {
			// 
				let requestParams = {
					type: 'google',
					token: _.get(response, 'credential')
					// token: response.tokenId || response.Zi.id_token
				}
				// 
				props.login({
					body:requestParams,
					callback: (res,data)=>{

					  if(res==true && _.get(data,'isLoggedIn')==true){
						// 
notification.success({
	message:"Success",
	description:'Login successful'
})
window.location.reload();

// history('/communiti/terms-service')
					  }
					  
					}
			});
				
			}
		} 

		// const login = useGoogleLogin({
		// 	onSuccess: tokenResponse => getGoogleToken(tokenResponse),
		//   });

	
	const getFacebookToken = (response, signInType) => {
		if (response.accessToken) {
				let requestParams = {
					type: 'facebook',
					token: response.accessToken
				}
				props.login({
					body:requestParams,
					callback: (res,data)=>{

					  if(res==true && _.get(data,'isLoggedIn')==true){
notification.success({
	message:"Success",
	description:'Login successful'
})
history('/communiti/terms-service')
					  }
					  
					}
			})
				
			}
		
	}
	const getAppleToken = (response, signInType) => {
		if (_.get(response,'getAppleToken.id_token')) {
				let requestParams = {
					type: 'apple',
					token: _.get(response,'getAppleToken.id_token')
				}
				props.login({
					body:requestParams,
					callback: (res,data)=>{
					  

					  if(res==true && _.get(data,'isLoggedIn')==true){
notification.success({
	message:"Success",
	description:'Login successful'
})
// history('/terms-service')
					  }
					  
					}
			})
				
			}
		
	}
	let host = window.location.host;
let hostName = host.split(":");
const baseUrl = `${window.location.protocol}//${hostName[0]}`;

	const render = () => {
		// document.body.style.paddingBottom = "0";
		return (
			<div className="LoginContainer">
				<div className="container">
					<div className="row justify-content-center">
						<div>Social login comes here.</div>
					</div>
				</div>
			</div>
		);
	}
	return render()
}
const mapStateToProps = state => {
	
	return {
	};
};

const mapDispatchToProps = dispatch => {
	return {
		login: details => dispatch(global.redux.action.account.socialLogin(details))

		
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPageComponent)