import firebase from 'firebase/compat/app';
import _ from 'lodash';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import appEnvirontVariables from "../../config/env-variables";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = _.pick(appEnvirontVariables.FIREBASE, ['apiKey', 'authDomain', 'projectId', 'storageBucket', 'messagingSenderId', 'appId', 'measurementId']);


  firebase.initializeApp(firebaseConfig);

export { firebase };