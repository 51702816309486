import { Button as AntButton, Divider, Modal } from 'antd';
import { IoMdClose } from "react-icons/io";
import './style.css'
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { TbArrowBackUp } from 'react-icons/tb';
import { Box, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import EmailOtpVerification from './email-otp'
import ResetPasswordOtp from './reset-password-otp';
const { useState, useEffect } = require('react');

const useStyles = makeStyles({
       helperTextError: {
              color: '#F04438 !important',
              fontFamily: `Inter, sans-serif !important`,
              fontSize: '0.875rem !important',
              fontWeight: 400,
              lineHeight: '16px' /* 114.286% */
       },
       helperText: {
              color: '#475467 !important',
              fontFamily: `Inter, sans-serif !important`,
              fontSize: '0.875rem !important',
              fontWeight: 400,
              lineHeight: '16px' /* 114.286% */
       },
       root: {
              '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
                     borderColor: '#FB2424', // Error border color
                     border: '1px solid var(--Error-500, #F04438)'
              },
              // input label when focused
              "& label.Mui-focused": {
                     color: "#FF692E"
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              "& .MuiInputBase-input.Mui-disabled": {
                     WebkitTextFillColor: "#475467",

                     "& > fieldset": {
                            borderColor: "red"
                     }
              },
              '& .MuiInputBase-input': {

                     '&.Mui-error::placeholder': {
                            color: '#F04438', // Placeholder color in error state
                     },
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                     "&.Mui-focused fieldset": {
                            borderColor: "#FF692E"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#FF692E"
                     },

              },


              '& .MuiInputBase-root.Mui-disabled': {

                     "&:focus fieldset": {
                            borderColor: "#D0D5DD"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#D0D5DD"
                     },
                     backgroundColor: 'var(--Gray-50, #F9FAFB)', // Change to your desired background color
                     borderColor: '#c0c0c0', // Change to your desired border color
              },
              '& .MuiInputBase-root.Mui-disabled:hover': {
                     borderColor: '#c0c0c0', // Change to your desired focused border color
              },
       },


});

const ResetPassword = (props) => {
       const [userDetailsData, setuserDetailsData] = useState({})
       const [showPassword, setShowPassword] = useState(false);
       const classes = useStyles();
       
       const [isModalOpen, setIsModalOpen] = useState(false);
       const [email, setEmail] = useState("");
       const [emailError, setEmailError] = useState({ visible: false, message: '', type: 1 });
       const [formStep, setformStep] = useState(1)
       const [successModal, setsuccessModal] = useState(false)
       const [resetFormStep, setresetFormStep] = useState(1)

       const [passwordCheckList, setpasswordCheckList] = useState([
              {
                     name: 'At least 1 uppercase character',
                     success: false
              },
              {
                     name: 'At least 1 number  ',
                     success: false
              },

              {
                     name: 'At least 1 special character - (!@#$%&*)',
                     success: false
              },
              {
                     name: 'At least 10 characters long',
                     success: false
              },

       ]);
       const [dotPulseBtnLoader, setDotPulseBtnLoader] = useState(false);

       useEffect(() => {
              setIsModalOpen(props?.isModalOpen?.visible)
              setformStep(props?.isModalOpen?.form)
       }, [props?.isModalOpen])

       useEffect(() => {
         if(_.get(props,'userDetails.email')){
              setEmail(props?.userDetails?.email)
         }
       }, [])
       

       const handleClickShowPassword = () => setShowPassword((show) => !show);

       const handleMouseDownPassword = (event) => {
              event.preventDefault();
       };

       const checkProfile = (email) => {
              props.guestProfileCheck({
                     params: {
                            user_type: 0,
                            type: 'email',
                            data: email
                     },
                     callback: (res, data) => {
                            if (_.get(data, 'data.details.id')) {

                            } else if (_.get(data, 'error.error.message')) {

                            }

                     }
              })
       }



       const requestResetPassword = () => {
              setDotPulseBtnLoader(true);
            
              props.requestResetPassword({
                     body: {
                            user_type: 0,
                            email: email,
                     },
                     callback: (res, data) => {
                            setDotPulseBtnLoader(false);
                            
                            if(res==true && _.get(data, 'success')==true){
                                   setresetFormStep(2)
                            } else  {
                                   setEmailError({ visible: true, message: '', type: 2 })
                                   // setpasswordError(true)

                            }

                     }
              })
       }
       const handleEmailChange = e => {

              setEmail(e.target.value);


       };
       const validateEmail = (email) => {

              return email.match(
                     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
       };

       const passwordChange = (e) => {

              let checkList = [...passwordCheckList]
              if (!/[A-Z]/.test(e)) {
                     checkList[0].success = false
              } else {
                     checkList[0].success = true
              }
              if (!/\d/.test(e)) {
                     checkList[1].success = false
              } else {
                     checkList[1].success = true
              }
              if (!/[!@#$%^&*]/.test(e)) {
                     checkList[2].success = false
              } else {
                     checkList[2].success = true
              }
              if (e?.length < 10) {
                     checkList[3].success = false
              } else {
                     checkList[3].success = true
              }


              return checkList

       }


       return successModal == false ?resetFormStep==1?
              <div >
                     <Divider style={{ color: '#EAECF0', width: 1 }} />
                     <div className='email-modal-des' style={{ padding: '24px', paddingTop: '0px' }}>
                     Enter your email below. If you have an account with us we’ll send you a verification code on your email and you will be able to reset your password
                     </div>
                     <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
                            <TextField
                            onChange={(e)=>setEmail(e.target.value)}
                            onKeyDown={(ev) => {
                                   
                                   if (ev.key === 'Enter') {
                                     // Do code here
                                     ev.preventDefault();
                                   }
                                 }}
                                   error={email !=''?validateEmail(email) == null?true: emailError.visible:emailError.visible}

                                   value={email}

                                   inputProps={{className: 'email-input-mui', }} 
                                   required id="outlined-required" label="Email"
                                   className={classes.root}
                                   style={{ width: '100%' }}
                            
                                   FormHelperTextProps={{
                                          classes: {
                                                 root: classes.helperText,
                                          },
                                   }}
                            />
                            {email != '' && (validateEmail(email) == null ? <FormHelperText className={classes.helperTextError} error={true}>
                                   { 'Please enter a valid email'}
                            </FormHelperText> : emailError.type == 2 && <FormHelperText className={classes.helperTextError} error={emailError.visible}>
                                   {emailError.visible ? <div>This email isn’t associated with any account, <span
                                   onClick={()=>{
                                          props?.onOpenLoginModal()
                                   }}
                                   style={{textDecorationLine:'underline',cursor:'pointer'}}
                                   >please create a new account</span></div> : ''}
                            </FormHelperText>)}
                     </div>



                     <div>

                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '24px', paddingLeft: '24px', paddingRight: '24px' }}>
                                   <AntButton
                                          className='ant-back-button'
                                          onClick={() => {
                                                 setresetFormStep(1);
                                                 setDotPulseBtnLoader(false);
                                                 // setEmail('');

                                                 props.onBackForm();
                                                 setDotPulseBtnLoader(false);
                                          }}

                                          style={{
                                                 width: "50%",
                                                 height: "44px",
                                                 backgroundColor: "#FFF",
                                                 color: "#344054",
                                                 borderRadius: '12px',
                                                 fontWeight: 600,
                                                 fontSize: '1rem',
                                                 marginRight: '12px'

                                          }}
                                   ><TbArrowBackUp size={22} color={"#344054"} style={{ marginTop: -3 }} /> back</AntButton>
                                   { !dotPulseBtnLoader && <Button
                                   disabled={(!email || validateEmail(email) == null)?true:false}
                                          style={{
                                                 width: "50%",
                                                 height: "44px",
                                                 backgroundColor:(!email || validateEmail(email) == null)?  "#D0D5DD": "#FF692E",
                                                 color: "#FFFFFF",
                                                 borderRadius: '12px',
                                                 fontWeight: 600,
                                                 fontSize: '1rem',
                                                 textTransform: 'none'


                                          }}
                                          onClick={() => {
                                                 
                                                 requestResetPassword()
                                                 // setEmailError({ visible: true, message: '', type: 2 })

                                          }}

                                          fullWidth

                                   >
                                          Reset password
                                   </Button>}
                                   { dotPulseBtnLoader && <div style={{
                                          width: "50%",
                                          height: '44px'
                                   }} className='otp-loading-btn-container'>
                                          <div className='dot-pulse'></div>
                                          </div>
                                          }
                            </div>
                     </div>


              </div>:<ResetPasswordOtp 
              onOpenLoginModal={()=>{
                     props?.onOpenLoginModal()
              }}
              onBackForm={()=>{
                     setformStep(1)
              }} oncloseAllModal={() => props?.oncloseModal()} userDetails={props?.userDetails} /> :
              <EmailOtpVerification oncloseAllModal={() => props?.oncloseModal()} userDetails={props?.userDetails}

              />


}
const mapStateToProps = state => {
       return {


       }
}

const mapDispatchToProps = dispatch => ({

       guestProfileCheck: details => dispatch(global.redux.action.guest.checkProfile(details)),
       requestResetPassword: details => dispatch(global.redux.action.guest.requestResetPassword(details)),

});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
