import axios from "axios";
import _ from 'lodash';
import {decode, encode} from 'base-64'

import appEnvirontVariables from "../../../config/env-variables";

const baseURL = appEnvirontVariables.CHAT_SYSTEM.CHATKITTY.API_BASE_URL;
const authorizationURL = appEnvirontVariables.CHAT_SYSTEM.CHATKITTY.API_AUTHORIZATION_URL;

const getAccessToken = (callback=()=>{})=>{
    if (!global.btoa) {  global.btoa = encode }

    if (!global.atob) { global.atob = decode }

    axios.request({ 
        url: "/oauth/token", 
        method: "post", 
        baseURL: authorizationURL, 
        auth: { username: appEnvirontVariables.CHAT_SYSTEM.CHATKITTY.CLIENT_ID, password: appEnvirontVariables.CHAT_SYSTEM.CHATKITTY.CLIENT_SECRET }, 
        data: "grant_type=client_credentials"
    })
    .then(response=>{
        
        callback(true, _.get(response, 'data', {}));
    })
    .catch(error=>{
        callback(false, _.get(error, 'response.data', {}));
    });
};

const createChannel = (criteria, callback=()=>{})=>{
    axios.request({ 
        url: "/channels", 
        method: "post", 
        baseURL,
        data: _.get(criteria, 'body', {}),
        headers: {
            Authorization: `Bearer ${_.get(criteria, 'access_token', '')}`
        }
    })
    .then(response=>{
        callback(true, _.get(response, 'data', {}));
    })
    .catch(error=>{
        callback(false, _.get(error, 'response.data', {}));
    });
};

const createChannelMessage = (criteria, callback=()=>{})=>{
    axios.request({ 
        url: `/channels/${_.get(criteria, 'channel_id', '')}/messages`, 
        method: "post", 
        baseURL,
        data: _.get(criteria, 'body', {}),
        headers: {
            Authorization: `Bearer ${_.get(criteria, 'access_token', '')}`
        }
    })
    .then(response=>{
        callback(true, _.get(response, 'data', {}));
    })
    .catch(error=>{
        callback(false, _.get(error, 'response.data', {}));
    });
};

export default {getAccessToken, createChannel, createChannelMessage};