import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateCategoriesState;
// categories actions

export default {
    // get list data of the  categories
    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/categories/list`
                },
                actionType: actionType.categories.list,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    }
}