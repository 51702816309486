import React, {useEffect, useState} from 'react'
import { Button, Image, Modal, Tooltip, Skeleton, Select, notification } from "antd"
import { connect } from 'react-redux'
import _ from 'lodash'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import GoogleMapReact from 'google-map-react';
import { RiHome6Line } from "react-icons/ri";
import {IoLocationOutline} from "react-icons/io5"
import {PiQuestionThin} from "react-icons/pi"
import {CiCircleInfo} from "react-icons/ci";
import {GoLinkExternal} from "react-icons/go"
import moment from 'moment-timezone';
import { GoogleLogin } from '@react-oauth/google';
import { CiMobile3 } from "react-icons/ci";
import SessionCard from '../sticky-container'
import StickyBox from "react-sticky-box";

import global from '../../core_units/config/global'
import BannerBg from "../../assets/img/image 1.png";
import Loader from './loader';
import "./style.css";
import CommonFooter from '../../components/Footer/footer';
import Maps from './map';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

const { Option } = Select;

const ExperienceListing = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const [getTempData, setTempData] = useState({});
  const [getPhoto, setPhoto] = useState();
  const [experiencePhotos, setexperiencePhotos] = useState([])
  const [LocationData, setLocationData] = useState({})
  const [isScheduledModalOpen, setIsScheduledModalOpen] = useState(false);
  const [isLeagueModalOpen, setIsLeagueModalOpen] = useState(false);
  const [isOpenSessionModalOpen, setIsOpenSessionModalOpen] = useState(false);
  const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
  const [isAiModalOpen, setIsAiModalOpen] = useState(false);
  const [getScheduledDate, setScheduledDate] = useState([]);
  const [pastDateCount, setPastDateCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [getExperinceData, setExperinceData] = useState([]);
  const [getAddressData, setAddressData] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [getIsDateEnable, setIsDateEnable] = useState(true);
  const [getSelectedDate, setSelectedDate] = useState(null);
  const [getTime, setTime] = useState(null);
  const [getDate, setDate] = useState(null)
  const [getIsTimeEnable, setIsTimeEnable] = useState(true);
  const [isEnableSchedule, setIsEnableSchedule] = useState(true);
  const [isMultipleLocationModalOpen, setIsMultipleLocationModalOpen] = useState(false)
  const [currentLocalISOString] = useState( moment.tz(new Date(),"America/Toronto").toISOString());
  const [getItems, setItems] = useState(null);
  const [getTimeValue, setTimeValue] = useState("");
  const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [content, setContent] = useState();
  const [getHostData, setHostData] = useState({});
  const [dataFromChild, setDataFromChild] = useState();
  const [latitude, setLatitute] = useState("");
  const [longitude, setLongitude] = useState("");
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });


  const { id } = useParams();
  const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
  
  const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');

  const modalTitle = (
    <div style={{display: "flex", flexDirection: "column"}}>
      <img src={require('../../assets/img/communiti-logo.png')} alt="Your Image" style={{ marginRight: '8px', marginBottom: '4px', width: "200px"}} />
      <div className='continue-with-email' style={{marginBottom: "5px"}}>Please sign-in to complete your purchase</div>
    </div>
  );
  
  let navigate = useNavigate();

  


  const defaultProps = {
    center: {
      lat: 11.937871, lng: 79.819953
    },
    zoom: 20
  };


  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Call handler right away so state gets updated with initial window size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    viewTemplate();
    experienceList();
    hostDetails();
  }, []);

  useEffect(() => {
    compareAddresses();
    handleMapLocation();
  }, [getExperinceData]);

  useEffect(() => {
    handleMapLocation();
  }, [dataFromChild])

  

  const viewTemplate = () => {
    props.viewSessionAPI({
      params: {
        id: id,
        expand: "template,template_photos,host",
        is_price_porated: 1
      },
      callback: (res, value) => {
        
        if(res){
          if(_.get(value, 'error')){
            setErrorMessage("404 Not found")
          }
          setTempData(value)
          
          const content_template = _.get(value, 'data.details.template.description');
          // const updatedTemplateContent = content_template.replace(/<p><br><\/p>/g, '');
          setContent(content_template);
          setPhoto(`${_.get(value, 'data.meta.photo.path')}/${_.get(value, 'data.meta.photo.folders.template')}/${_.get(value, 'data.meta.photo.sizes.large')}/${_.get(value, 'data.details.template.photos[0].photo')}`);
          setLoading(false)
          setexperiencePhotos(_.map(_.get(value, 'data.details.template.photos'),(v)=>({...v,url:`${_.get(value, 'data.meta.photo.path')}/${_.get(value, 'data.meta.photo.folders.template')}/${_.get(value, 'data.meta.photo.sizes.large')}/${_.get(v, 'photo')}`})))
          setLocationData({lat:_.get(value, 'data.details.location.x'), lng:_.get(value, 'data.details.location.y')});
            let start_date_item = _.get(value, 'data.details.experience_date');
            let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
            
            let concated_array = scheduled_date_items
            setScheduledDate(concated_array.filter(item => item !== "" && item !== null))
            
          
        }
      }
    })
  };

  const handleDataFromChild = (data) => {
    console.log('jhgafcfdsgvhfbj', data);
    if(getAddressData == "Multiple Locations" && _.isEmpty(data)){
      setDataFromChild({
        address: "Multiple locations available. Select a start date to view location."
      })
    } else if(getAddressData == "Multiple Locations" && !_.isEmpty(data)) {
      setDataFromChild(data);
      setLatitute(_.get(data, 'location.x'));
      setLongitude(_.get(data, 'location.y'));
     
    }
    
  };

  const handleMapLocation = () => {

   
  }

  const experienceList = () => {
    props.experienceListAPI({
        params: {
            template: searchParams.get("template"),
            is_price_porated: 1,
            min_experience_date: currentLocalISOString,
            min_experience_time: _.chain(currentLocalISOString).split('T').last().value(),
        },
        callback: (res, value) => {
            if(res){
              if(_.get(value, 'error')){
                setErrorMessage("404 Not found")
              }
                
                setExperinceData(_.get(value, 'data.items'))
            }
        }
    })
  };

  const showModal = (type) => {
    
    if(type === 'scheduled'){
      setIsScheduledModalOpen(true)
    } else if(type === 'league'){
      setIsLeagueModalOpen(true)
    } else if(type === 'open'){
      setIsOpenSessionModalOpen(true);
    }
    
  };

  const handleOk = () => {
    if(_.get(getTempData, 'data.details.type') === 'scheduled'){
      setIsScheduledModalOpen(false)
    } else if(_.get(getTempData, 'data.details.type') === 'league'){
      setIsLeagueModalOpen(false)
    } else if(_.get(getTempData, 'data.details.type') === 'open'){
      setIsOpenSessionModalOpen(false);
    }
    
  };

  const handleCancel = () => {
    if(_.get(getTempData, 'data.details.type') === 'scheduled'){
      setIsScheduledModalOpen(false)
    } else if(_.get(getTempData, 'data.details.type') === 'league'){
      setIsLeagueModalOpen(false)
    } else if(_.get(getTempData, 'data.details.type') === 'open'){
      setIsOpenSessionModalOpen(false);
    }
  };

  const showAiModal = () => {
    setIsAiModalOpen(true);
  }

  const handleAIok = () => {
    setIsAiModalOpen(false)
  }

  const handleAICancel = () => {
    setIsAiModalOpen(false)
  }

  const showSessionDateModal = () => {
    setIsSessionDateModalOpen(true)
  };

  const scheduleHandleOk = () => {
    setIsSessionDateModalOpen(false)
  }

  const scheduleHandleCancel = () => {
    setIsSessionDateModalOpen(false)
  };

  const onCloseMultiple = () => {
    setIsMultipleLocationModalOpen(false)
  }

  const AnyReactComponent = () => <div><a href={`https://www.google.com/maps?q=${_.get(getTempData, 'data.details.address')}`} target='_blank'><Image 
  className='orange-image-icon' 
  width={35} 
  preview={false} 
  height={35}
  src={require('../../assets/img/map-marker-icon.png')} /></a></div>;

  const inlineStyle = {
    backgroundImage: !_.isEmpty(_.get(getTempData, 'data.details.template.photos')) ? `url(${getPhoto})` : `url(${require("../../assets/img/communiti-static-image.png")})`,
    backgroundSize: 'cover', // Adjust as needed
    backgroundRepeat: 'no-repeat', // Adjust as needed
    backgroundColor: _.isEmpty(_.get(getTempData, 'data.details.template.photos')) ? "#ccc" : ""
  };

  const proceedPayment = () => {
    // navigate(`/social-login/?id=${getItems.id}`)
    showLoginModal();
  };

  const proceedPaymentOpen = () => {
    navigate(`/social-login/?id=${id}`)
  };

  const compareAddresses = () => {
    if(_.size(getExperinceData) > 0){
        const uniqueAddresses = new Set(getExperinceData.map((item) => item.address));
  
        if (uniqueAddresses.size === 1) {
          
          setAddressData(getExperinceData[0].address)
          setDataFromChild({
            address: getExperinceData[0].address
          });
            setLatitute(getExperinceData[0].location.x);
            setLongitude(getExperinceData[0].location.y);
            
        } else {
          setDataFromChild({
            address: "Multiple locations available. Select a start date to view location."
          });
          setAddressData("Multiple Locations");
        }
    }
   
  };

  const handleSelectChange = (value) => {
    // Find the selected item based on the address
    
    setDate(value)
    setTime(null);
    
    if(_.size(getExperinceData) > 0){
        const selected = getExperinceData.find(item => item.experience_date === value);
        if(selected){
          const mergedDate = [selected.experience_date, ...selected.schedule_dates]
          setItems({
              id: selected.id,
              dates: mergedDate,
              session_dates: mergedDate,
              experience_date: selected.experience_date,
              experience_end_date: selected.experience_end_date,
              meta_details: selected.meta_details,
              can_late_register: selected.can_late_register,
              original_price: selected.original_price,
              price: selected.price,
              today:  moment.tz(_.get(selected, 'meta_details.timezone')),
              address: selected.address
          });
          let count = 0;
            const selectedToday = moment.tz(_.get(selected, 'meta_details.timezone'));
            const selectedTimeZone = _.get(selected, 'meta_details.timezone');
            mergedDate.forEach((dateString) => {
      const date = moment.tz(dateString, selectedTimeZone);
      if (date.isBefore(selectedToday)) {
        count++;
      }
    }) ;
    setPastDateCount(count);
        }
      
    }
   
}

const handleChangeTime = (value) => {
  
        setTime(value);
        // setIsEnableSchedule(false)
    
};

const showProratedModal = () => {
  setIsProratedModalOpen(true);
}

const handleProratedOk = () => {
  setIsProratedModalOpen(false)
}

const handleProratedCancel = () => {
  setIsProratedModalOpen(false)
}

const CustomMapStyles = [
  {
    featureType: 'all',
    elementType: 'all',
    stylers: [{ saturation: -100 }],
  },
];

const showLoginModal = () => {
  setIsModalOpen(true);
};
const handleLoginOk = () => {
  setIsModalOpen(false);
};
const handleLoginCancel = () => {
  setIsModalOpen(false);
};

const getGoogleToken = (response, signInType) => {
  if (response) {
      let requestParams = {
        type: 'google',
        token: _.get(response, 'credential'),
                  scopes: ['profile', 'email']
      }
      props.login({
        body:requestParams,
        callback: (res,data)=>{
                          
          if(res==true && _.get(data,'isLoggedIn')==true){
notification.success({
message:"Success",
description:'Login successful'
})

          }
          
        }
    });
      
    }
  };
  
  const basicExperienceDetailsCard = {
      // width: "616px",
      padding: "16px 16px 16px 32px",
      borderRadius: "10px",
      border: "1px solid var(--Gray-200, #EAECF0)",
      background: "var(--Base-White, #FFF)",
  };

  const experience_details_text = {
    color: 'var(--Gray-700, #344054)',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px', 
    marginLeft: "24px"
  };

  const hostDetails = () => {
    props.getHostDetailsAPI({
      params:{
        handle:searchParams.get("handle")
      },
      callback: (res, val) => {
        
        if(res){
          setHostData(_.get(val, 'data'));
        }
        
      }
    })
  }
let images =["https://s3.ca-central-1.amazonaws.com/communiti-dev/public/images/Templates/1024/photo-1716181600548-972383869.png",
"https://s3.ca-central-1.amazonaws.com/communiti-de…/Hosts/1024/banner-photo-3180.jpg?v=1716361990308"

]
  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === images.length - 1 ? 0 : prevSlide + 1));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? images.length - 1 : prevSlide - 1));
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };
  const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }
  
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '400px'
  }
  const slideImages = [
    {
      url: 'https://s3.ca-central-1.amazonaws.com/communiti-dev/public/images/Templates/1024/photo-1716386751580-8957115041.png',
      caption: 'Slide 1'
    },
    {
      url: 'https://s3.ca-central-1.amazonaws.com/communiti-dev/public/images/Templates/1024/photo-1716386751580-8957115041.png',
      caption: 'Slide 2'
    },
    {
      url: 'https://s3.ca-central-1.amazonaws.com/communiti-dev/public/images/Templates/1024/photo-1716386751580-8957115041.png',
      caption: 'Slide 3'
    },
  ];
  const indicators = (index,data) => {
    
    return <div key={index} className="dot-indicator" style={{marginRight:'6px'}}>
  
    <span
      key={index}
      className={index === currentSlide ? 'dot-another active' : 'dot-another'}
      onClick={() => goToSlide(index)}
    />
  
</div>
  };

  // 1120 to 1000
console.log('kajhfgdsshg8ujhdfbv', dataFromChild);
  const render = () => {
   
  return (
      <div>
       
        {
           loading ? <Loader/> : errorMessage ? <h2 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>{errorMessage}</h2> :
          <div>
          <div className="banner-container">
          <div className='container' style={{height:'405px',width:windowSize.width<550?windowSize.width-50 :"100%"}}>
            {
              !loading ? 
              <Slide
              duration={1000}
              defaultIndex={currentSlide}
              transitionDuration={500} 
              onChange={(from,to)=>{
                
               setCurrentSlide(to)
              }}
              cssClass='slider-image-container'
              indicators={_.size(experiencePhotos)>1? indicators:false}
              autoplay={false}
              
              arrows={_.size(experiencePhotos)>1?true:false}
              nextArrow={
              <button className='nextArrow-class' ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M9 18L15 12L9 6" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg></button>} 
              prevArrow={<button className='preArrow-class' >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M15 18L9 12L15 6" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
                </button>}>
                     
                      {_.map(experiencePhotos,(value,i)=>{
                        return  <div key={i} style={{height:"405px",borderRadius:'10px'}} className="each-slide-effect">
                        <div style={{
                  backgroundImage: `url(${value?.url})`,
                  height:"405px",
                  backgroundSize:"cover",
                  backgroundRepeat:"no-repeat",
                  backgroundPosition:"center",
                  borderRadius:'10px'
                }}>
                            
                        </div>
                    </div>
                      })}
                      
                  </Slide>
              : <Skeleton active paragraph={{
                rows: 7
              }}/>
            }
              
  
          </div>
      
    </div>
    <div className='rating-container'>
      <div className='container'>
      <div >
        <div style={{marginTop: "16px"}}>
        
        
        <div className="row">
        <div className= {windowSize.width<=1120 &&windowSize.width>1000?"col-7":windowSize.width<999?"col-12": `col-8`}>
        <div>
          {
            !loading ? <div className='rating-card'>
            <p style={{marginTop: "16px"}} className='experince-title'>{_.get(getTempData, 'data.details.template.title')}</p>
            <p className='experince-address'>{_.get(getTempData, 'data.details.address')}</p>
            <div style={{display:"flex", flexDirection: "row", marginTop: "4px"}}>
              {
                _.get(getHostData, 'details.average_ratings') ?
                <>
                <Rating initialValue={1} readonly={true} iconsCount={1} size={20} className='experince-rating-star'/>
            <p className='experince-rating'>{_.get(getHostData, 'details.average_ratings') && Math.round(_.get(getHostData, 'details.average_ratings') * 10) / 10}</p>
            <p className='dot'></p>
            <p className='experience-reviews'> {`${_.get(getHostData, 'details.no_of_ratings')} Reviews`}</p>
            </> : ""
              }
            </div>
          </div> : <Skeleton active paragraph={{rows: 3}}/>
          }
        
        </div>
              <div style={{marginTop: "16px"}}>
                <div>
                  
                    <div style={basicExperienceDetailsCard}>
                      <div style={{display: "flex", flexDirection: "row"}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g id="home-line">
                      <path id="Icon" d="M8 17H16M11.0177 2.76403L4.23539 8.03916C3.78202 8.39178 3.55534 8.56809 3.39203 8.78889C3.24737 8.98447 3.1396 9.20481 3.07403 9.43908C3 9.70355 3 9.99073 3 10.5651V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V10.5651C21 9.99073 21 9.70355 20.926 9.43908C20.8604 9.20481 20.7526 8.98447 20.608 8.78889C20.4447 8.56809 20.218 8.39178 19.7646 8.03916L12.9823 2.76403C12.631 2.49078 12.4553 2.35415 12.2613 2.30163C12.0902 2.25529 11.9098 2.25529 11.7387 2.30163C11.5447 2.35415 11.369 2.49078 11.0177 2.76403Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      </svg>
                    <div style={experience_details_text}>{_.startCase(_.get(getTempData, 'data.details.type'))} Session</div>
                     <svg onClick={() => showModal(_.get(getTempData, 'data.details.type'))} style={{marginLeft: "8px", cursor: "pointer", marginTop: "4px"}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Icon" d="M6.672 6.6C6.86008 6.06533 7.23132 5.61449 7.71996 5.32731C8.20861 5.04013 8.78312 4.93515 9.34174 5.03097C9.90037 5.12679 10.4071 5.41722 10.7721 5.85082C11.1371 6.28443 11.3368 6.83322 11.336 7.4C11.336 9 8.936 9.8 8.936 9.8M9 13H9.008M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                    </div>
                    <div style={{display: "flex", flexDirection: "row", marginTop: "16px"}}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="marker-pin-04">
                    <path id="Icon" d="M5 14.2864C3.14864 15.1031 2 16.2412 2 17.5C2 19.9853 6.47715 22 12 22C17.5228 22 22 19.9853 22 17.5C22 16.2412 20.8514 15.1031 19 14.2864M18 8C18 12.0637 13.5 14 12 17C10.5 14 6 12.0637 6 8C6 4.68629 8.68629 2 12 2C15.3137 2 18 4.68629 18 8ZM13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    </svg>

                    <div style={experience_details_text}>{_.get(dataFromChild, 'address')}</div>
                    {getAddressData === "Multiple Locations" && <svg onClick={() => setIsMultipleLocationModalOpen(true)} style={{marginLeft: "8px", cursor: "pointer", marginTop: "4px"}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path id="Icon" d="M6.672 6.6C6.86008 6.06533 7.23132 5.61449 7.71996 5.32731C8.20861 5.04013 8.78312 4.93515 9.34174 5.03097C9.90037 5.12679 10.4071 5.41722 10.7721 5.85082C11.1371 6.28443 11.3368 6.83322 11.336 7.4C11.336 9 8.936 9.8 8.936 9.8M9 13H9.008M17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>}
                    </div>
                    </div>
                  
                  
                  </div>
                  {
                    !loading ? <div className='description-container'>
                    <div className='template-description-title'>
                      About
                    </div>
                    <div className='template-description' dangerouslySetInnerHTML={{__html: content}} />
                    
                    </div> : <Skeleton active paragraph={{rows: 8}}/>
                  }
                  
                  {
                    !_.isEmpty(_.get(getTempData, 'data.details.template.extra_info')) ?
                    <div className='description-container'>
                  <div className='template-description-title'>
                    Additional Information
                  </div>
                  {
                    _.get(getTempData, 'data.details.template.extra_info').map((value, index) => {
                      
                      function addHttpToLink(link) {
                        // Check if the link starts with "http://" or "https://"
                        if (!link.startsWith('http://') && !link.startsWith('https://')) {
                          // If not, prepend "http://"
                          link = 'http://' + link;
                        }
                      
                        return link;
                      }
                      return(
                      <div className='additional-info-container'>
                      <div className='additional-info-title'>
                          {_.get(value, 'name')}
                      </div>
                      <div className='additional-info-description' dangerouslySetInnerHTML={{__html: _.get(value, 'description')}} />
                        
                      {
                        _.get(value, 'link') && <div className='additional-info-link-container'>
                        <div className='link-back'>
                        <GoLinkExternal className='additional-info-link-icon'/>
                        </div>
                        <div onClick={()=>{
                           window.open(addHttpToLink(_.get(value, 'link')), '_blank').focus();
 }} className='additional-info-link' style={{cursor:'pointer'}}>
                        {_.get(value, 'name')}
                        </div>
                      
                      </div>
                      }
                      
                      </div>
                      )
                      
                    })
                  }
                  
                  </div>
                  :""
                  }

                  {
                    !_.isEmpty(_.get(getTempData, 'data.details.host.meta_details.refund_policy')) ?
                    <div className='refund-description-container'>
                  <div className='refund-description-title'>
                    Refund Policy
                  </div>
                      <div style={{whiteSpace: "pre-wrap"}} className='refund-info-description'>
                         {
                          _.get(getTempData, 'data.details.host.meta_details.refund_policy')
                         }
                      </div>
                      
                  
                  
                  </div>
                  :""
                  }
                  {
                    !loading ? <div>
                      {getAddressData === "Multiple Locations" && _.get(dataFromChild, 'location') && <div className='refund-description-container'>
                    <div className='refund-description-title'>
                      Location
                    </div>
                    <a
                    style={{textDecorationColor:'var(--Gray-600, #475467)'}}
                    href={`https://www.google.com/maps?q=${_.get(dataFromChild, 'address')}`} target="_blank" className='experience-location-map secondColor-style'>
                           {
                            _.get(dataFromChild, 'address')
                           }
                        </a>
                        <div style={{marginTop:'8px' }}>
                        <Maps lat={latitude} lng={longitude} />
                    </div>
                    </div>}

                    {
                     getAddressData === "Multiple Locations" && _.isEmpty(_.get(dataFromChild, 'location')) && <div className='refund-description-container'>
                      <div className='refund-description-title'>
                        Location
                      </div>
                      <div style={{fontSize: "1.0rem", color: "#475467", fontWeight: 400}}>Multiple locations available. Select a start date to view location.</div>
                      </div>
                    }

                {/* {_.size(getExperinceData) == 1 && <div className='refund-description-container'>
                    <div className='refund-description-title'>
                      Location
                    </div>
                    <a
                    style={{textDecorationColor:'var(--Gray-600, #475467)'}}
                    href={`https://www.google.com/maps?q=${_.get(dataFromChild, 'address')}`} target="_blank" className='experience-location-map secondColor-style'>
                           {
                            _.get(dataFromChild, 'address')
                           }
                        </a>
                        <div style={{marginTop:'8px' }}>
                        <Maps lat={latitude} lng={longitude} />
                    </div>
                    </div>} */}

                    { getAddressData !== "Multiple Locations" && _.size(getExperinceData) > 0 && <div className='refund-description-container'>
                    <div className='refund-description-title'>
                      Location
                    </div>
                    <a
                    style={{textDecorationColor:'var(--Gray-600, #475467)'}}
                    href={`https://www.google.com/maps?q=${_.get(dataFromChild, 'address')}`} target="_blank" className='experience-location-map secondColor-style'>
                           {
                            _.get(dataFromChild, 'address')
                           }
                        </a>
                        <div style={{marginTop:'8px' }}>
                        <Maps lat={latitude} lng={longitude} />
                    </div>
                    </div>}
                    
                    </div> : <Skeleton active paragraph={{rows: 5}}/>
                  }
                
                  
              </div>
          </div>
          <div className={windowSize.width<999?`col-12`:`col-4`} style={{marginTop: "16px"}}>
      
      <StickyBox offsetTop={16} offsetBottom={10}>
      <SessionCard sendDataToParent={handleDataFromChild}/> 
      </StickyBox>
        </div>
        </div>
          </div>
         
        
         
          
          </div>
      </div>
    </div>
    <div>
    <Modal closable={false} footer={null} open={isScheduledModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
        <p>A</p>&nbsp;<p style={{fontWeight: 600}}>Scheduled</p>&nbsp;<p>listing takes place over a specific sets of dates.</p>
        </div>
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isLeagueModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      A < >League / Tournament</> listing will involve competing against other athletes in a set of games. Scores and results will be recorded.
        </div>
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isOpenSessionModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      An Open session does not have a specified start date or time. You communicate directly with the Club or Coach to pick a date and time that works for you.
        </div>
      </Modal>
      </div>

      <div>
    <Modal closable={false} footer={null} open={isAiModalOpen} onOk={handleAIok} onCancel={handleAICancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      Communiti’s AI will ask you questions upon registration and ensure teams generated in the League or Tournament are balanced for fun, competitive play.
        </div>
      </Modal>
      </div>

      <div>
    <Modal closable={false} footer={null} open={isMultipleLocationModalOpen} onOk={onCloseMultiple} onCancel={onCloseMultiple}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session is held at multiple locations. Select a start date and time to see available locations.
        </div>
      </Modal>
      </div>

      <div>
    <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
    <p className='register-location'>Session Schedule</p>
                 
                  <div>
      { !_.isEmpty(getItems) && _.size(_.get(getItems, 'session_dates')) > 0 && _.get(getItems, 'session_dates').map((item, index) => {
       
        const date = moment.tz(item, _.get(getItems, 'meta_details.timezone'));
        const isPastDate = date.isBefore(_.get(getItems, 'today'));
        const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getItems, 'meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getItems, 'meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getItems, 'experience_end_date'), _.get(getItems, 'meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  })}
    </div>
      </Modal>
      </div>

      <div>
    <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session has already started and the price has been prorated. View the schedule to see the dates remaining
        </div>
      </Modal>
      </div>
      <div>
      <Modal 
      title={modalTitle}
      open={isModalOpen} 
      onOk={handleLoginOk} 
      onCancel={handleLoginCancel}
      footer={null}
      >
        <div>
        <div style={{display: "flex", justifyContent: "center"}}>
                    <GoogleLogin
                      size='large'
                      onSuccess={credentialResponse => {
                          getGoogleToken(credentialResponse)
                      }}
                      onError={() => {
                          
                      }}
                      />
        </div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
        <Button className='continue-with-email' style={{width: "60%", height: "40px"}}><CiMobile3 style={{width: "25px", height: "25px"}}/>Continue with phone number</Button>
        </div>
        <div style={{display: "flex", justifyContent: "center", marginTop: "30px"}}>
        <p className='continue-with-email'>Continue with email</p>
        </div>
        </div>
        
      </Modal>
      </div>
      </div>
       }
       <CommonFooter/>
    </div>
      
  )
  }
  return render();
}

const mapStateToProps = state => {
  
  return {
     

  }
}

const mapDispatchToProps = dispatch => ({
  viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
  experienceListAPI: details => dispatch(global.redux.action.experience.list(details)),
  login: details => dispatch(global.redux.action.account.socialLogin(details)),
  getHostDetailsAPI: details => dispatch(global.redux.action.host.getHostsDetails(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ExperienceListing)