import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateFinancesState;
// host actions

export default {
    // check availablity of user handle
    checkUserHandleAvailablity: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/hosts/check-handle-availability`
                },
                actionType: actionType.userHandle.checkUserHandleAvailablity,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    createOrUpdateUserHandle: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/hosts/update-handle`
                },
                actionType: actionType.userHandle.createOrUpdateUserHandle,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}