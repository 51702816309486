import React, {useEffect, useState} from 'react';
import {useStripe, useElements} from "@stripe/react-stripe-js";
import { PaymentElement } from '@stripe/react-stripe-js'
import { Button, notification } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import _ from 'lodash';


const CheckOutForm = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isProcessing, setIsProcessing] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const [paymentMethod, setpaymentMethod] = useState('')
    const handleSubmit = async(e) => {
        e.preventDefault();

        if(!stripe || !elements){
            return;
        }

        setIsProcessing(true);
        if(props?.redirectId){
            const {error} = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: `${window.location.origin}/success?order_id=${_.get(props,'order_id')}&handle=${_.get(props,'user_handle')}&id=${_.get(props,'experience_id')}`
                }
            });
            if(error){
                api.error({
                    message: _.startCase(_.get(error,'code')),
                    description:_.get(error,'message')
                  });
               
                // setMessage(error.message)
            };
        }else{
            const {error} = await stripe.confirmPayment({
                elements,
                
                confirmParams: {
                   
                    return_url: `${window.location.origin}/success?order_id=${_.get(props,'order_id')}&handle=${_.get(props,'user_handle')}&id=${_.get(props,'experience_id')}`
                }
            });
            if(error){
                api.error({
                    message: _.startCase(_.get(error,'code')),
                    description:_.get(error,'message')
                  });
               
                // setMessage(error.message)
        }
    }
        
        setIsProcessing(false)
    }
  return (
    <>
    {contextHolder}
    
    <form id='payment-form' onSubmit={handleSubmit}>
        <PaymentElement />
        <Button 

key="submit" htmlType="submit"
                                style={{
                                width: "100%",
                                height: "50px",
                                marginTop: "32px",
                                backgroundColor: "#EF6820", 
                                color:"#fff",
                                borderRadius: '12px',
                                fontWeight: 600,
                                fontSize: '1rem'
          
                              }}
                               
                                id='submit'
                                disabled={isProcessing}
                                >{isProcessing ? "Processing" : "Register and pay"}</Button>
        {/* <button
        style={{
            width: "100%",
            height: "50px",
            marginTop: "8px",
            backgroundColor: "#EF6820", 
            color:"#fff",
            borderRadius: '12px',
            fontWeight: 600,
            fontSize: '1rem'

          }}
         disabled={isProcessing} id='submit'>
            <span id='buttom-text'>
                {isProcessing ? "Processing" : "Register and pay"}
            </span>
        </button> */}
    </form></>
  )
}

export default CheckOutForm