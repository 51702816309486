import _ from 'lodash';

import { firebase } from '../firebase';

const register =async(criteria)=>{
    const userData =_.get(criteria, 'userData', {});
    const callback =_.get(criteria, 'callback', ()=>{});
    try {
        await firebase
        .auth()
        .createUserWithEmailAndPassword(_.get(userData, 'email'), _.get(userData, 'password'))
        .then((credential) => {
          credential.user
          .updateProfile({ displayName: _.get(userData, 'displayName') })
          .then(async () => {
            // TODO start a user chat session and log the user in
            callback(true, {});
          });
        });
    } catch (e) {
        callback(false, e);
    }
};

export default { register };