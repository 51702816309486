import instance from './instance';
import session from './session';
import initialyze from './initialyze';
import api from './api';

// Initialyzing chatKitty variables
export default {
  instance,
  session,
  initialyze,
  api
};