// Map.js
import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FaMapMarkerAlt } from "react-icons/fa";

const Maps = ({ lat, lng }) => {
    const [map, setMap] = useState(null);
    const markerRef = useRef(null);
    const mapContainerStyle = {
        width: '100%',
        height: '400px',
    };

    const center = {
        lat: lat,
        lng: lng,
    };

    const staticCoordinates = { lat: 43.651070, lng: -79.347015 };

    const handleMapLoad = (map) => {
        setMap(map);

        const marker = new window.google.maps.Marker({
            position: center,
            map: map,
            icon: {
                url: require('../../assets/img/map-marker-icon.png'),
                scaledSize: new window.google.maps.Size(40, 40), // Adjust size here
            },
        });

        markerRef.current = marker;
    };

    useEffect(() => {
        return () => {
            if (markerRef.current) {
                markerRef.current.setMap(null);
            }
        };
    }, []);

    return (
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={18}
                onLoad={handleMapLoad}
                options={{
                    styles: [
                        {
                            elementType: 'geometry',
                            stylers: [{ color: '#f5f5f5' }],
                        },
                        {
                            elementType: 'labels.icon',
                            stylers: [{ color: '#bdbdbd' }],
                        },
                        {
                            featureType: 'administrative.land_parcel',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#bdbdbd' }],
                        },
                        {
                            featureType: 'poi',
                            elementType: 'geometry',
                            stylers: [{ color: '#eeeeee' }],
                        },
                        {
                            featureType: 'poi',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#757575' }],
                        },
                        {
                            featureType: 'poi.park',
                            elementType: 'geometry',
                            stylers: [{ color: '#e5e5e5' }],
                        },
                        {
                            featureType: 'poi.park',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#9e9e9e' }],
                        },
                        {
                            featureType: 'road',
                            elementType: 'geometry',
                            stylers: [{ color: '#ffffff' }],
                        },
                        {
                            featureType: 'road.arterial',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#757575' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'geometry',
                            stylers: [{ color: '#dadada' }],
                        },
                        {
                            featureType: 'road.highway',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#616161' }],
                        },
                        {
                            featureType: 'road.local',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#9e9e9e' }],
                        },
                        {
                            featureType: 'transit.line',
                            elementType: 'geometry',
                            stylers: [{ color: '#e5e5e5' }],
                        },
                        {
                            featureType: 'transit.station',
                            elementType: 'geometry',
                            stylers: [{ color: '#eeeeee' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'geometry',
                            stylers: [{ color: '#c9c9c9' }],
                        },
                        {
                            featureType: 'water',
                            elementType: 'labels.text.fill',
                            stylers: [{ color: '#9e9e9e' }],
                        },
                    ],
                }}
            >
                <Marker position={center} />
            </GoogleMap>
    );
};

export default Maps;
