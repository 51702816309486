import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import { Avatar, Spin, Button as AntButton } from 'antd';
import moment from 'moment-timezone';
import { LoadingOutlined } from '@ant-design/icons';
import { TbArrowBackUp } from "react-icons/tb";

import "./style.css";

const SeeAllReviews = (props) => {

  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
    const [getRatingList, setRatingList] = useState([]);
    const [getRatingMeta, setRatingMeta] = useState({});
    const [getLoading, setLoading] = useState(true);
    useEffect(() => {
        listReviews();
      }, []);

      const listReviews = () => {
        props.ratingListAPI({
            params:{
              expand:"top_tags,guest,host",
              limit:50,
              host: searchParams.get("host")
            },
            callback: (res, val) => {
              
              if(res){
                
                setLoading(false)
              if(_.get(val, 'data.meta.count') > 0){
                setRatingList(_.get(val, 'data.items'));
                setRatingMeta(_.get(val, 'data.meta'));
              } else {
                setRatingList([]);
              }
            }
            }
          })
      };

      const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 40,
          }}
          spin
        />
      );

  const render = () => {
  return (

      <div>
         
    <div className='rating-container-all' >
      
      <div className='container'>
      <AntButton
className='ant-back-button-container'
                style={{
                  width: "10%",
                  height: "44px",
                  backgroundColor: "#FFF",
                  color: "#475467",
                  borderRadius: '12px',
                  fontWeight: 400,
                  fontSize: '1rem',
                  // marginRight: '12px',
                  border:'none',
                  marginTop:'12px',
                  marginLeft:'-14px'

                }}
                onClick={()=>{
                  // /experience-list/4933?template=4929&handle=iyyappan&id=4933
                  navigate(`/${searchParams.get('handle')}`);
                 }}
              ><TbArrowBackUp size={20} color={"#475467"} style={{ marginTop: -3 }} /> back</AntButton>
              <div class="template-description-title" style={{marginBottom:'0px',marginTop:'0px'}}>All reviews</div>
      <div className="row">
        <div className='col-12'>
        {/* review and address */}
        
        <div style={{marginTop: "16px"}}>
        <Spin spinning={getLoading} style={{display: "flex", alignItems: "center", justifyContent: "center", color: "#e85d04"}} indicator={antIcon} />
            {/* card comes here */}
            <div>
                    {
                     _.size(getRatingList) > 0 && getRatingList.map((v, i)=> {
                      let src = `${_.get(getRatingMeta, 'photo.path')}/${_.get(getRatingMeta, 'photo.folders.guest')}/${_.get(getRatingMeta, 'photo.sizes.medium')}/${_.get(v, 'guest.photo')}`
                     
                      const fullName = `${_.get(v, 'guest.first_name')} ${_.get(v, 'guest.last_name')}`;
                      const initials = fullName.split(' ').map(name => name[0]).join('');
                       
                      return(
                        <div className='card' style={{boxShadow: "none", marginBottom: "10px", borderRadius: "12px", paddingRight: "24px"}}>
                        <div >
                          <div className='rating-container' style={{display: "flex", flexDirection: "row"}}>
                            {
                              !_.isEmpty(_.get(v, 'guest.photo')) ?  <Avatar src={src} size={40}/> :  <Avatar size={40}>{_.upperCase(initials)}</Avatar>
                            }
                          
                           <div className='rating-name'>
                          {
                           
                           `${_.startCase(_.get(v, 'guest.first_name'))} ${_.startCase(_.get(v, 'guest.last_name'))}`
                          }
                          </div>
                          </div>
                          <div className='rating-date'>{!_.isEmpty(_.get(v, 'created_at')) ? moment(_.get(v, 'created_at')).format("MMMM DD, YYYY") : "-"}</div>
                          <div className='rate-star-container'>
                          <Rating initialValue={_.get(v, 'star_rating')} readonly={true} iconsCount={_.get(v, 'star_rating')} size={20} className='experince-rating-star'/>
                          </div>
                          <div className='rate-message'>
                            {_.get(v, 'message')}
                          </div>
                          {_.size(_.get(v, 'tags'))>0 && <div style={{marginTop:'8px',display:'flex',flexWrap:'wrap', marginLeft: "24px"}}>
                            {_.map(_.get(v, 'tags'),(tag,i)=>{
                            return <div key={i} className="rating-container-tags" style={{marginRight:"8px",marginBottom:'8px', borderRadius: "6px", border: "1px solid var(--Gray-300, #D0D5DD)", background: "var(--Base-White, #FFF)", display: "flex", padding: "2px 9px", justifyContent: "center", alignItems: "center"}}>
                                   <div className="rating-container-tags-text">{tag}</div>

                            </div>
                            })}
                          </div>}
                          </div>
                          </div>
                      )
                     })
                    }
                    </div>
        </div>
        </div>
          </div>
      </div>
    </div>
      
    </div>
      
  )
  }
  return render();
}

const mapStateToProps = state => {
  return {
     

  }
}

const mapDispatchToProps = dispatch => ({
  viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
  ratingListAPI: details => dispatch(global.redux.action.experience.experienceRatingList(details)),
  experienceListAPI: details => dispatch(global.redux.action.experience.list(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SeeAllReviews)