import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateExperiencesTemplatesState;
// host actions

export default {
    // get experience templates of logged in host
    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/experiences/templates/list`
                },
                actionType: actionType.experiencesTemplates.list,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    view: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/experiences/templates/view`
                },
                actionType: actionType.experiencesTemplates.view,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    // Create a experience template
    create: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/templates/create`
                },
                actionType: actionType.experiencesTemplates.create,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    // Update
    update: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/templates/update`
                },
                actionType: actionType.experiencesTemplates.update,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    // Add a experience template photo
    addPhoto: (componentRequest) => {

        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/templates/add-photo`,
                    headers: { 'Content-Type': 'multipart/form-data' }
                },
                actionType: actionType.experiencesTemplates.addPhoto,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    // Add a experience template photo
    deletePhoto: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/templates/remove-photos`
                },
                actionType: actionType.experiencesTemplates.deletePhoto,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    changeStatusTemplate: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/experiences/templates/change-status`
                },
                actionType: actionType.experiencesTemplates.changeStatusTemplate,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}