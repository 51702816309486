import { Button as AntButton, Divider, Modal,Image } from 'antd';
import { IoMdClose } from "react-icons/io";
import './style.css'
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { TbArrowBackUp } from 'react-icons/tb';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import EmailOtpVerification from './email-otp'
const { useState, useEffect, useRef, default: React,createRef } = require('react');

const useStyles = makeStyles({
       helperText: {
              color: '#475467 !important',
              fontFamily: `Inter, sans-serif !important`,
              fontSize: '0.875rem !important',
              fontWeight: 400,
              lineHeight: '16px' /* 114.286% */
       },
       root: {
              // input label when focused
              "& label.Mui-focused": {
                     color: "#FF692E"
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              "& .MuiInputBase-input.Mui-disabled": {
                     WebkitTextFillColor: "#475467",

                     "& > fieldset": {
                            borderColor: "red"
                     }
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                     "&.Mui-focused fieldset": {
                            borderColor: "#FF692E"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#FF692E"
                     },

              },


              '& .MuiInputBase-root.Mui-disabled': {

                     "&:focus fieldset": {
                            borderColor: "#D0D5DD"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#D0D5DD"
                     },
                     backgroundColor: 'var(--Gray-50, #F9FAFB)', // Change to your desired background color
                     borderColor: '#c0c0c0', // Change to your desired border color
              },
              '& .MuiInputBase-root.Mui-disabled:hover': {
                     borderColor: '#c0c0c0', // Change to your desired focused border color
              },
       },


});

const ResetPasswordCreate = (props) => {
       const [userDetailsData, setuserDetailsData] = useState({})
       const [showPassword, setShowPassword] = useState(false);
       const [showConfirmPassword, setShowConfirmPassword] = useState(false);
       const classes = useStyles();
       
       const [isModalOpen, setIsModalOpen] = useState(false);
       const [email, setEmail] = useState("");
       const [emailError, setEmailError] = useState(false);
       const [formStep, setformStep] = useState(1)
       const [password, setpassword] = useState('')
       const [confirmPassword, setConfirmPassword] = useState('')
       const [first_name, setfirst_name] = useState('')
       const [last_name, setlast_name] = useState('')
       const [showOTP, setshowOTP] = useState(false)
       const [sucessModal, setsucessModal] = useState(false)
       const [createLoading, setcreateLoading] = useState(false);
       const [passwordCheckList, setpasswordCheckList] = useState([
              {
                     name: 'At least 1 uppercase character',
                     success: false
              },
              {
                     name: 'At least 1 number  ',
                     success: false
              },

              {
                     name: 'At least 1 special character - (!@#$%&*)',
                     success: false
              },
              {
                     name: 'At least 10 characters long',
                     success: false
              },
              {
                     name: 'Passwords match',
                     success: false
              },

       ]);

       // reset password
       const [otp, setOtp] = useState(['', '', '', '', '', '']);
       const [errorOtp, seterrorOtp] = useState(false);
       const inputRefs = useRef([...Array(6)].map(() => createRef()));
       const [seconds, setSeconds] = useState(60);
       const [dotPulseBtnLoader, setDotPulseBtnLoader] = useState(false);




useEffect(() => {
       if (seconds > 0) {
         const intervalId = setInterval(() => {
           setSeconds(prevSeconds => prevSeconds - 1);
         }, 1000);
   
         return () => clearInterval(intervalId);
       }
     }, [seconds]);
       useEffect(() => {
              setIsModalOpen(props?.isModalOpen?.visible)
              setformStep(props?.isModalOpen?.form)
       }, [props?.isModalOpen])

       const handleClickShowPassword = () => setShowPassword((show) => !show);

       const handleMouseDownPassword = (event) => {
              event.preventDefault();
       };

       const guestPasswordCreate = (body) => {
              setDotPulseBtnLoader(true);
              setcreateLoading(true)
              props.resetPassword({
                     body: body,
                     callback: (res, data) => {
                            setDotPulseBtnLoader(false);
                            setcreateLoading(false)
                            
                            if(res==true){
                                   setsucessModal(true);
                                   
                            }
                          
                            // if (_.get(data, 'data.details.id')) {

                            // } else if (_.get(data, 'error.error.message')) {

                            // }

                     }
              })
       }


       const modalTitle = (
              <div style={{ display: "flex", flexDirection: "column", paddingLeft: '24px', paddingRight: '24px' }}>
                     <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                            <img src={require('../../assets/img/communiti-logo.png')} alt="Your Image" style={{ width: "200px" }} />
                            <IoMdClose style={{ height: '24px', width: '24px', cursor: 'pointer' }} onClick={() => {

                            }} color='#667085' />

                     </div>
                     <div className='email-modal-title' >Please login or signup to get started</div>

              </div>
       );


       const handleEmailChange = e => {

              setEmail(e.target.value);


       };
       const validateEmail = (email) => {

              return email.match(
                     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              );
       };

      const passwordChange =(e,confirmPassword)=>{
       
       let checkList =[...passwordCheckList]
       if (!/[A-Z]/.test(e)) {
              checkList[0].success=false
            }else{
              checkList[0].success=true
            }
            if (!/\d/.test(e)) {
              checkList[1].success=false
            }else{
              checkList[1].success=true
            }
            if (!/[!@#$%^&*]/.test(e)) {
              checkList[2].success=false
            }else{
              checkList[2].success=true
            }
       if (e?.length < 10) {
              checkList[3].success=false
            }else{
              checkList[3].success=true
            }

            if (e!=confirmPassword) {
              checkList[4].success=false
            }else if(!e){
              checkList[4].success=false
              
            }else{
              checkList[4].success=true
            }
      
            
      return checkList
      
      } 

      const conditionNext =()=>{
let checkList =_.compact(_.map(passwordChange(password,confirmPassword),'success'))

        if(!password){
              
return true
       } else if(checkList.length!=5){
              
              return true
       } else{
              return false
       }
      };

      const handleChange = (index, value) => {
       seterrorOtp(false)
       if (value !== '') {
         const newOtp = [...otp];
         newOtp[index] = value;
         setOtp(newOtp);
         if (index < 5 && value !== '') {
           inputRefs.current[index + 1].current.focus();
         }
       }else if (value === '' && index >= 0) {
              const newOtp = [...otp];
              newOtp[index] = '';
              setOtp(newOtp);
              
              
              index!=0 &&inputRefs.current[index - 1].current.focus();
            }
     };
   
     const handleKeyUp = (index, event) => {
       if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
              inputRefs.current[index - 1].current.focus();
            }
       // if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
       //        
       //   inputRefs.current[index - 1].current.focus();
       // } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '' && event.key !== 'Shift' &&event.key !== 'ArrowLeft') {
       //        
       //   inputRefs.current[index + 1].current.focus();
       // }
     };
   
     const handleKeyDown = (index, event) => {
       if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
              inputRefs.current[index - 1].current.focus();
            }
       // if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
       //   inputRefs.current[index - 1].current.focus();
       // } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '' && event.key !== 'Shift' &&event.key !== 'ArrowLeft') {
       //   inputRefs.current[index + 1].current.focus();
       // }
     };

     const accountsResendVerification = () => {
       props.requestResetPassword({
              body: {
                     user_type: 0,
                     email: props?.userDetails?.email
              },
              callback: (res, data) => {
                     if(res==true && _.get(data, 'success')==true){
                            setSeconds(60);
                            seterrorOtp(false)
                            setOtp(['', '', '', '', '', ''])

                     }
                    

              }
       })
};

const handlePaste = (event) => {
       event.preventDefault();
       const pastedData = event.clipboardData.getData('Text');
       if (pastedData.length === 6 ) {
         setOtp(pastedData.split('').slice(0, 6));
       }
     };

      
       return  sucessModal==false? 
       <div >
              <Divider style={{ color: '#EAECF0', width: 1 }} />
              <div className='email-modal-des' style={{ padding: '24px', paddingTop: '0px' }}>
              Hey {_.startCase(_.get(props,'userDetails.first_name'))} {_.startCase(_.get(props,'userDetails.last_name'))}! Please reset your password by creating a new password below 
              </div>
              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
                     <TextField
                            disabled={true}

                            value={props?.userDetails?.email}

                            inputProps={{
                                   className: 'email-input-mui',



                            }} required id="outlined-required"
                            className={classes.root}
                            style={{ width: '100%' }}
                            // helperText="We’ll send you a verification code after this step"
                            // InputProps={{ endAdornment: <div className='support-text-email'
                            // onClick={()=>{
                            //        setfirst_name('');
                            //        setlast_name('');
                            //        setpassword('');
                            //        props?.onBackForm()}}
                            // style={{ cursor: 'pointer' }}>Change</div>, }}
                            FormHelperTextProps={{
                                   classes: {
                                          root: classes.helperText,
                                   },
                            }}
                     />
              </div>

              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
              <div className='email-modal-des' style={{ color:'#475467',fontWeight:500,marginBottom:'6px' }}>
              Verification Code
              </div>
                     
              <div className="otp-container">
      {otp.map((digit, index) => (
       <div key={index} style={{display:'flex'}}>
              {index==3 && <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginRight:'8px'}}> 
                     <div style={{width:'28px',height:'10px',backgroundColor:'var(--Gray-300, #D0D5DD)',alignItems:'center'}} />
                     
                     </div>}
               <input
          key={index}
          className={errorOtp==true?"error-otp-text": "otp-input"}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyUp={(e) => handleKeyUp(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={inputRefs.current[index]}
          onPaste={handlePaste}
          style={{
              marginRight:'8px'
          }}
        />
       </div>
      ))}
    </div>
    {errorOtp ==true &&<div className='error-otp-des' style={{marginTop:'6px' }}>
    Incorrect code! Please try again
              </div>}

              <div className='email-resend-otp-text' style={{paddingTop:'24px'}}>
              Didn’t receive your code? {seconds==0?<span 
              onClick={()=>{
accountsResendVerification()
              }}
              style={{textDecorationLine:'underline',cursor:'pointer'}}>Resend code</span>:<span style={{color:'#F04438',fontSize:'1rem'}}>{`(${seconds})`}</span>}
              </div>
              </div>
              
             
              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
                     <FormControl className={classes.root} sx={{ width: '100%' }} variant="outlined" >
                            <InputLabel required htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                   autoComplete="off"
                                   required
onChange={(e)=>setpassword(e.target.value)}
                                   id="outlined-adornment-password"
                                   type={showPassword ? 'text' : 'password'}
                                   inputProps={{ className: 'email-input-mui' }}
                                   endAdornment={
                                          <InputAdornment position="end">
                                                 <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                 >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                 </IconButton>
                                          </InputAdornment>
                                   }
                                   label="Password"
                            />
                     </FormControl>
              </div>
              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
                     <FormControl className={classes.root} sx={{ width: '100%' }} variant="outlined" >
                            <InputLabel required htmlFor="outlined-adornment-confirm-password">Confirm new password</InputLabel>
                            <OutlinedInput
                                   autoComplete="off"
                                   required
onChange={(e)=>setConfirmPassword(e.target.value)}
                                   id="outlined-adornment-confirm-password"
                                   type={showConfirmPassword ? 'text' : 'password'}
                                   inputProps={{ className: 'email-input-mui' }}
                                   endAdornment={
                                          <InputAdornment position="end">
                                                 <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={()=>setShowConfirmPassword(!showConfirmPassword)}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                 >
                                                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                 </IconButton>
                                          </InputAdornment>
                                   }
                                   label="Confirm new password"
                            />
                     </FormControl>
              </div>
             
              <div>
                     <div style={{paddingLeft:'24px',paddingRight:'24px'}}>
                            {_.map(passwordChange(password,confirmPassword),(v,i)=>{

                                   return <div key={i} style={{display:'flex',marginBottom:'6px',alignItems:'center'}}> 
                                          <div style={{marginRight:'8px'}}>{v.success==true? <AiOutlineCheckCircle color='#039855' style={{width:'24px',height:'24px'}} />:
                                          <AiOutlineCloseCircle color='#F04438' style={{width:'24px',height:'24px'}} />
                                          }</div>
                                          <div className='password-checkList-title'>
                                                 {v.name}
                                          </div>

                                   </div>
                            })}

                     </div>
              </div>
              <div>
                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '24px', paddingLeft: '24px', paddingRight: '24px' }}>
                            {/* <AntButton
                            disabled={createLoading==true?true: false}
                                   className='ant-back-button'
                                   onClick={() => {
                                          setfirst_name('');
                                          setlast_name('');
                                          setpassword('');
                                          props.onBackForm()
                                   }}

                                   style={{
                                          width: "90%",
                                          height: "44px",
                                          backgroundColor: "#FFF",
                                          color: "#344054",
                                          borderRadius: '12px',
                                          fontWeight: 600,
                                          fontSize: '1rem',
                                          marginRight: '12px'

                                   }}
                            ><TbArrowBackUp size={22} color={"#344054"} style={{ marginTop: -3 }} /> back</AntButton> */}
                           { !dotPulseBtnLoader && <Button
                            
                                   disabled={conditionNext()==true?true:createLoading==true?true: _.size(_.compact(otp))!==6?true:false}
                                   style={{
                                          width: "100%",
                                          height: "44px",
                                          backgroundColor: conditionNext()==true ? "#D0D5DD" : _.size(_.compact(otp))!==6 ? "#D0D5DD" : "#FF692E",
                                          color: "#FFFFFF",
                                          borderRadius: '12px',
                                          fontWeight: 600,
                                          fontSize: '1rem',
                                          textTransform: 'none'


                                   }}
                                   onClick={() => {
                                         let passwordDetails ={
                                          id:props?.userDetails?.account,
                                          token:otp.join(''),
                                          password:password
                                         }
                                         
                                         guestPasswordCreate(passwordDetails)
                                         
// props?.onNextForm(3)



                                   }}

                                   fullWidth

                            >
                                   Reset password
                            </Button>}

                            { dotPulseBtnLoader && <div style={{
                                          width: "100%",
                                          height: '44px'
                                   }} className='otp-loading-btn-container'>
                                          <div className='dot-pulse'></div>
                                          </div>
                                          }
                     </div>
              </div>


       </div>:
       <div >
       <Divider style={{ color: '#EAECF0', width: 1 }} />
      
       <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '15px',display:'flex',justifyContent:'center' }}>
          <Image preview={false} src={require('../../assets/img/success.png')} style={{width:'48px',height:'48px'}} />    
       </div>
       
       <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
       <div className='email-success-title' >
       Password reset!
       </div>
       <div className='email-success-des' style={{marginTop:'8px'}}>
       Your password was successfully reset. Please <span style={{textDecorationLine:'underline',cursor:'pointer'}} onClick={()=>{
props.onOpenLoginModal()
       }}>login</span> again
       </div>
              
      


      
       </div>
       
       
    


</div>


}
const mapStateToProps = state => {
       return {


       }
}

const mapDispatchToProps = dispatch => ({

       guestProfileCheck: details => dispatch(global.redux.action.guest.checkProfile(details)),
       resetPassword: details => dispatch(global.redux.action.guest.resetPassword(details)),
       accountsResendVerification: details => dispatch(global.redux.action.guest.accountsResendVerification(details)),
       requestResetPassword: details => dispatch(global.redux.action.guest.requestResetPassword(details)),


});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCreate)
