import React, {useState, useEffect} from 'react';
import { Button, Modal, Form, Input, Select, DatePicker, notification, Image } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import {CiCircleInfo} from "react-icons/ci";
import {SlCheck} from 'react-icons/sl';
import {CiFileOn} from 'react-icons/ci';
import {loadStripe} from '@stripe/stripe-js';
import {PiQuestionThin} from "react-icons/pi"
import {Elements} from '@stripe/react-stripe-js'
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import {CloseOutlined} from '@ant-design/icons';
import StickyBox from "react-sticky-box";

import envVariables from '../../core_units/config/env-variables';
import CheckOutForm from './checkout'
import CommonRightContainer from '../common-right-container';
import AuthFooter from '../../components/auth-footer'
import "./style.css";
import '../create-profile/style.css'
import { IconButton, InputAdornment } from '@mui/material';
import { TbArrowBackUp } from 'react-icons/tb';
import { IoClose } from "react-icons/io5";

const { Option } = Select;
const { TextArea } = Input;

const PaymentOperation = (props) => {
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [disbalePromoCode, setdisbalePromoCode] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const [order_id, setorder_id] = useState('')
    const [getTempData, setTempData] = useState({});
    const [getScheduledDate, setScheduledDate] = useState([]);
    const [pastDateCount, setPastDateCount] = useState(0);
    const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
    const [getComputeOrder, setComputeOrder] = useState({});
    const [getProfileDetails, setProfileDetails] = useState({});
    const [getUserDetails, setUserDetails] = useState({});
    const [stripe, setStripe] = useState(null);
    const [getStripeDetails, setStripeDetails] = useState({});
    const [stripePromise, setStripePromise]  = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [getUpdatedQuestion, setUpdatedQuestion] = useState([]);
    const [getRegInfo, setRegInfo] = useState([]);
    const [regInfoFinal, setRegInfoFinal] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);
    const [couponCode, setcouponCode] = useState('')
    const [couponLoading, setcouponLoading] = useState(false)
    const [applyCoupon, setapplyCoupon] = useState(false)
    const [applyCouponDetails, setapplyCouponDetails] = useState({});
    const [storedData, setStoredData] = useState(null);
    const [orderCreateDetails, setorderCreateDetails] = useState({});
    const [isStripeErrorModalOpen, setIsStripeErrorModalOpen] = useState(false);
    const [stripeErrorTxt, setStripeErrorTxt] = useState("");
    const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
    const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');
    

    useEffect(() => {

     
        getExperienceDetails();
        viewUserData();
        computeOrder({
          experience: props?.orderCreateData?.experience,
          profile: props?.orderCreateData?.profile
      });
    
        setStripePromise(loadStripe(envVariables.STRIPE.PUBLISHABLE_KEY, {
          stripeAccount: _.get(getTempData, 'data.details.host.stripe_user_id')
        }));
         handleGetDataFromLocalStorage();
         let dis_id = _.get(storedData, 'discount.id')
         
let datas = {...props?.orderCreateData,
  registration_info: _.size(props?.orderCreateData?.registration_info) > 0 ? ExtractData(props?.orderCreateData?.registration_info,1) : [],
    clauses: _.size(props?.orderCreateData?.clauses) > 0 ?ExtractData(props?.orderCreateData?.clauses,2) : [],
    discount: dis_id,
}
if(!dis_id)(
  delete datas.discount
)
delete datas?.profile_details;
// handlePayment(datas)

setorderCreateDetails(datas)
        

    }, []);
    useEffect(() => {
      
    
      window.addEventListener('storage', handleGetDataFromLocalStorage)
    
      return () => {
        window.removeEventListener('storage', handleGetDataFromLocalStorage)
      }
    }, [])

    const handleGetDataFromLocalStorage = () => {
      // Get the JSON string from localStorage
      const jsonData = localStorage.getItem('discount_key');
  
      // Parse the JSON string back to a JavaScript object
      const parsedData = jsonData ? JSON.parse(jsonData) : null;
  
      // Set the parsed data in the component state
      setStoredData(parsedData);
    };
const extractOthers =(value,type,item) =>{
  if(type=="checkbox"){
  let a = _.includes(value,"#Others")
  if(a==true){
    let filterData = _.filter(value,(v)=>v!="#Others")
    filterData.push(_.get(item,"other_value"))
    return filterData.join(', ')
  }else{
    return [...value].join(', ')
  }

}else {
  if(value =="#Others"){
    return _.get(item,"other_value")
  }else{
    return _.get(item,"value")
  }
}
}
    const ExtractData =(parsing,type=1)=>{
      if(parsing && type==1){
    
        const updatedQuestions2 = parsing.map(item1 => ({
          id: item1.id,
          label: item1.label,
          answer:item1?.type=="checkbox"?extractOthers(item1.values,item1?.type,item1): item1?.type=="multichoice"?extractOthers(item1.value,item1?.type,item1) :item1.value
        }));
        return updatedQuestions2
      }else if(type==2){
        const updatedQuestions2 = parsing.map(item1 => ({
          description: item1.description,
          name: item1.name
        }));
        return updatedQuestions2
      }
     
    }
    useEffect(() => {
        let count = 0;
    
        getScheduledDate.forEach((dateString) => {
          const date = moment.tz(dateString, timeZone);
          if (date.isBefore(today)) {
            count++;
          }
        });
    
        setPastDateCount(count);
      }, [getScheduledDate, today, timeZone]);

      useEffect(() => {
        const parsedGetList = localStorage.getItem('question_redux');
        const parsing = JSON.parse(parsedGetList)
        if(parsing){
          const updatedQuestions2 = parsing.map(item1 => ({
            id: item1.id,
            label: item1.label,
            answer: item1.value
          }));
          setRegInfoFinal(updatedQuestions2)
        }
       
      }, []);

    const getExperienceDetails = () => {
        props.viewSessionAPI({
            params: {
              id: props?.orderCreateData?.experience,
              expand: "template,template_photos,host",
              is_price_porated: 1
            },
            callback: (res, value) => {
              
              if(res){
                setTempData(value);
                
                  let start_date_item = _.get(value, 'data.details.experience_date');
                  let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
                  
                  let concated_array = scheduled_date_items
                  setScheduledDate(concated_array.filter(item => item !== "" && item !== null))
                
              }
            }
          })
    };

    const viewUserData = () => {
        props.guestProfileData({
            callback: (res, data) => {
                
                if(res){
                    setUserDetails(_.get(data, 'data.details'))
                }
            }
        })
    }

    const computeOrder = (body) => {
        props.experienceComputeOrderAPI({
            body:body,
            callback: (res, data) => {
                
                if(!_.isEmpty(_.get(data, 'details'))){
                    setComputeOrder(_.get(data, 'details'))
                }
            }
        })
    };

    const showSessionDateModal = () => {
        setIsSessionDateModalOpen(true)
      };
      const scheduleHandleOk = () => {
        setIsSessionDateModalOpen(false)
      }
    
      const scheduleHandleCancel = () => {
        setIsSessionDateModalOpen(false)
      };

     const conditionDefaultValue=()=>{
      const jsonData = localStorage.getItem('discount_key');
  
        // Parse the JSON string back to a JavaScript object
        const parsedData = jsonData ? JSON.parse(jsonData) : null;

       return  parsedData?.discount?.id?false:true
     }
      const handlePayment = (data) => {
        const jsonData = localStorage.getItem('discount_key');
  
        // Parse the JSON string back to a JavaScript object
        const parsedData = jsonData ? JSON.parse(jsonData) : null;
        
        if(!parsedData?.discount?.id){
          localStorage.setItem('coup_code','')
        }
        
        let body ={...data,discount:parsedData?.discount?.id?parsedData?.discount?.id:''}
          props.exprienceCreateOrderAPI({
            body:body,
            callback: (res, val) => {
              console.log('gafsdh', val);
              if(_.get(val, 'success') == false){
                setIsStripeErrorModalOpen(true);
                setStripeErrorTxt(_.get(val, 'error.message'))
              }
              if(val?.details?.id){
                setorder_id(val?.details?.id)
              }
              if(_.get(val, 'payment_intent.id')){
                setClientSecret(_.get(val, 'payment_intent.client_secret'))
              }
            }
          })
      };

      const handleOk = () => {
        setIsStripeErrorModalOpen(false);
      };
      const handleCancel = () => {
        setIsStripeErrorModalOpen(false);
      };

      const paymentErrorTitle = () => {
        return (
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <div className='payment-error-icon'>
            <img style={{height: "24px", width: "24px"}} src={require('../../assets/img/payment-error.png')}/>
            </div>
            <div>
              <IoClose onClick={handleCancel} style={{width: "24px", height: "24px", cursor: "pointer"}} color='#667085'/>
            </div>
          </div>
        )
      }

      const handleNameClick = (item) => {
        if (item.description) {
          setModalContent(item.description);
          setModalVisible(true);
        } else if (item.link) {
          window.open(item.link, '_blank');
        }
      };

     

      const showProratedModal = () => {
        setIsProratedModalOpen(true);
      }
    
      const handleProratedOk = () => {
        setIsProratedModalOpen(false)
      }
    
      const handleProratedCancel = () => {
        setIsProratedModalOpen(false)
      }


      //
      const onChangeCouponInputText =(value)=> {
        setcouponCode(value)
        if (!_.chain(value).trim().isEmpty().value()) {
          setcouponLoading(true)
            props.applyCoupon({
                body: {
                    coupon_code: value,
                    experience: searchParams.get("id")
                },
                callback: (response, data) => {
                  // setTimeout(() => {
                  //   setcouponLoading(false)
                  // }, 1500);
                    if (response) {
                      setapplyCouponDetails(_.get(data,'details'))
                     
                       setapplyCoupon(true)
                       computeOrder({
                        experience: searchParams.get("id"),
                        profile: props?.orderCreateData?.profile,
                        discount:_.get(data,'details.id')
                    });
                      //  processComputeOrder({
                      //   experience:_.get(props,'getregistrationFlow.defalut_experience.selectedSessionTimeData.id'),
                      //   profile:_.get(props,'getregistrationFlow.profileDetails.id')?_.get(props,'getregistrationFlow.profileDetails.id'):_.get(props,'getregistrationFlow.register_profile.id'),
                      //   discount:_.get(data,'details.id')
                      // })
                    } else if(response==false && _.get(data,'message')) {
                      setapplyCoupon(false)
                      // Alert.alert('Error',_.get(data,'message'))
                      // notification.error({
                      //   message: `Error`,
                      //   description:
                      //     _.get(data,'message'),
                      //   placement:'topRight',
                      // })
                    }else{
                      setapplyCoupon(false)
                    }
                }
            });
        } else {
            
            
        }
      }

    const render = () => {
      
        return(
          <div>

          <div className="banner-container-profile">
            <div className='container'>
  
              <div className='row'>
                <div className='col-lg-8' style={{marginBottom:'45px'}}>
  
                  
                    <div style={{ display: "flex",flexDirection:'column' }}>
                      <div style={{ display: "flex",marginLeft:'1px' }}>
                        <Image className='step-images-1' style={{ width: '27px', height: '66px' }} preview={false} src={require('../../assets/img/step-2.png')} />
                        <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                          <div className='profile-heading secondColor-style'>Athlete profile</div>
                           {
                            <div className='profile-description secondColor-style' style={{display:'flex',flexDirection:'row'}}>{_.startCase(_.get(props,'orderCreateData.profile_details.first_name'))+' '+_.startCase(_.get(props,'orderCreateData.profile_details.last_name'))}... {!clientSecret &&<div style={{textDecorationLine:'underline',cursor:'pointer',marginLeft:'5px'}}
                            onClick={()=>{
                              !clientSecret &&   props?.onChangeScreen(1)
                             }}
                            >Change</div>}</div>}
                        </div>
                      </div>
                      {_.size(props?.orderCreateData?.registration_info) >0 &&<div style={{ display: "flex" }}>
                      <Image className='step-images-1' style={{ width: '27px', height: '66px' }} preview={false} src={require('../../assets/img/step-2.png')} />
                        <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                          <div className='profile-heading secondColor-style'>Additional information</div>
                           {
                            <div className='profile-description secondColor-style' style={{display:'flex',flexDirection:'row'}}>{_.get(_.head(_.get(props,'orderCreateData.registration_info')),'label')}... {!clientSecret &&<div style={{textDecorationLine:'underline',cursor:'pointer',marginLeft:'5px'}}
                            onClick={()=>{
                              !clientSecret && props?.onChangeScreen(2)
                             }}
                            >Change</div>}</div>}
                        </div>
                      </div>}
                     {_.size(props?.orderCreateData?.clauses) >0 && <div style={{ display: "flex" }}>
                      <Image className='step-images-1' style={{ width: '27px', height: '66px' }} preview={false} src={require('../../assets/img/step-2.png')} />
                        <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                          <div className='profile-heading secondColor-style'>Clauses</div>
                           {
                            <div className='profile-description secondColor-style' style={{display:'flex',flexDirection:'row'}}>Agreed to {`(${_.size(_.get(props,'orderCreateData.clauses'))})`} {_.size(_.get(props,'orderCreateData.clauses'))==1?"clause":"clauses"}... {!clientSecret &&<div style={{textDecorationLine:'underline',cursor:'pointer',marginLeft:'5px'}}
                            onClick={()=>{
                              !clientSecret &&    props?.onChangeScreen(3)
                             }}
                            >Change</div>}</div>}
                        </div>
                      </div>}
                      <div style={{ display: "flex" }}>
                      <Image className='step-images-1' style={{ width: '27px', height: '66px' }} preview={false} src={require('../../assets/img/step-2.png')} />
                        <div style={{ marginTop: '4px', marginLeft: '12px' }}>
                          <div className='profile-heading secondColor-style'>Review and payment</div>
                           {
                            <div className='profile-description secondColor-style'>All transactions are secured and encrypted.</div>}
                        </div>
                      </div>
                    </div>

                   {!clientSecret && <div style={{marginTop:'1rem'}}>
                      <div style={{fontSize:'1rem',fontWeight:600,color:'#475467'}}>
                      Please review all your registration details before you proceed to payment. You won’t be able to make any changes to your registration details after this point.
                      </div>
                      <div  style={{fontSize:'1rem',fontWeight:400,marginTop:'8px',color:'var(--Gray-600, #475467)'}}>
                      If you have a promo code you may enter one now. When you’re ready to complete your purchase please click on the button below
                      </div>
                    </div>}
                    <div>
                   {!clientSecret && <Button 
                                style={{
                                width: "100%",
                                height: "56px",
                                marginTop: "32px",
                                backgroundColor: "#e85d04", 
                                color:"#fff",
                                borderRadius: '12px',
                                fontWeight: 600,
                                fontSize: '1rem'
          
                              }}
                              className='ant-back-button raise-btn'
                               
                                onClick={()=>{
                                  handlePayment({...orderCreateDetails})
                                }}
                                
                                >Proceed to payment</Button>}
                                {!clientSecret && <Button
className='ant-back-button-container'
style={{
  width: "100%",
  height: "44px",
  backgroundColor: "#FFF",
  color: "#475467",
  borderRadius: '12px',
  fontWeight: 400,
  fontSize: '1rem',
  marginRight: '12px',
  border:'none',
  marginTop:'16px'

}}
                onClick={()=>{
                  if(_.size(_.get(orderCreateDetails,'clauses'))>0){
                    props?.onChangeScreen(3)
                  }else if(_.size(_.get(orderCreateDetails,'registration_info'))>0){
                    props?.onChangeScreen(2)
                  }else{
                    props?.onChangeScreen(1)
                  }
                 
                 }}
              ><TbArrowBackUp size={20} color={"#475467"} style={{ marginTop: -3 }} /> back</Button>}
                    </div>

                            
                            

                            <div style={{display: "flex", flexDirection: "row",marginTop:'12px'}}>
                                     
                          </div>
                          {/* <Image preview={false} style={{width:'155px',height:'34px',marginBottom:'12px'}} src={require('../../assets/img/stripe-banner.png')} /> */}
                             <div style={{marginTop: "10px"}}>
                   {
                    loadStripe(envVariables.STRIPE.PUBLISHABLE_KEY, {
                      stripeAccount: _.get(getTempData, 'data.details.host.stripe_user_id')
                    }) && clientSecret && (
                      <Elements stripe={loadStripe(envVariables.STRIPE.PUBLISHABLE_KEY, {
                        stripeAccount: _.get(getTempData, 'data.details.host.stripe_user_id')
                      })} options={{clientSecret}}>
                      <CheckOutForm order_id={order_id} user_handle={searchParams.get('handle')} experience_id={_.get(getTempData, 'data.details.id')}
                      
                      
                      />
                    </Elements>
                    )
                   }
                   </div>
                          
                        </div>
                        <div className="col-lg-4">
            {/* <div style={{marginTop: "16px"}}> */}
            <StickyBox offsetTop={16} offsetBottom={20}>
              <CommonRightContainer orderCreateData={props?.orderCreateData} disablePromoCode={clientSecret?true:false} defaultValuePromoCode ={conditionDefaultValue()}/>
              </StickyBox>
              {/* </div> */}
              </div>
                    </div>
                  </div>
              
            </div>
            <div>
    <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
    <p className='register-location'>Session Schedule</p>
                 
                  <div>
      {_.size(getScheduledDate) > 0 && getScheduledDate.map((item, index) => {
        const date = moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone'));
        const isPastDate = date.isBefore(today);
        const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  })}
    </div>
      </Modal>
      </div>
      <div>
      <Modal
        title="Description"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        <div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{ __html: modalContent}}/>
        {/* <p>{modalContent}</p> */}
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session has already started and the price has been prorated. View the schedule to see the dates remaining
        </div>
      </Modal>
      </div>
      {/* Payment error modal */}
      <div>
      <Modal title={paymentErrorTitle()} 
      open={isStripeErrorModalOpen}
       closable={false}
       footer={null}
       >
        <div>
          <div className='payment-error-header-txt'>
          Payment error 
          </div>
          <div className='payment-error-txt'>
          {stripeErrorTxt}
          </div>
          <button onClick={handleOk} className='payment-error-close-btn'>Okay</button>
        </div>
      </Modal>
      </div>
      {/* <AuthFooter/> */}
            </div>
        )
    };
    return render();
 
}

const mapStateToProps = state => {
  
    return {
      filterQuestions: _.get(state, 'app.settings.setQuestionsAnswer'),
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
    experienceComputeOrderAPI: details => dispatch(global.redux.action.experience.computeOrder(details)),
    viewGuestProfilesAPI: details => dispatch(global.redux.action.guest.guestProfilesView(details)),
    guestProfileData: details => dispatch(global.redux.action.guest.profile(details)),
    exprienceCreateOrderAPI: details => dispatch(global.redux.action.experience.experienceCreateOrder(details)),
    applyCoupon: details => dispatch(global.redux.action.coupons.apply(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(PaymentOperation)