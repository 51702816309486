import React from 'react';
import { Image } from 'antd';

const PaymentPendingcreen = () => {
  return (
    <div className='col-8'>
        <div style={{alignItems:'center',display:'flex',flexDirection:'column'}}>
        <Image preview={false} style={{width:'48px',height:'48px'}} src={require('../../assets/img/payment_failed_icon.png')} />
        <div className='order-confirm-title firstColor-style' style={{marginTop:'20px',fontSize:'1.5rem'}}>
        Payment pending
     </div>
     <div className='order-confirm-des secondColor-style' style={{marginTop:'8px',fontSize:'1rem',fontWeight:400,}}>
     It looks like your payment is taking longer than usual to be confirmed. Your bank or credit card is still processing the transaction. Please do not make another purchase
      </div>
      <div className='order-confirm-des secondColor-style' style={{marginTop:'16px',fontSize:'1rem',fontWeight:400,}}>
      No need to worry, simply refresh or reload this page in a few minutes to check if your order is confirmed. We’ll also send you a confirmation email once your payment is successfully processed
      </div>
      <div className='order-confirm-des secondColor-style' style={{marginTop:'16px',fontSize:'1rem',fontWeight:400,}}>
      Thank you for your patience. If this problem still persists, please contact us at support@communiti.app
      </div>
        </div>
    </div>
  )
}

export default PaymentPendingcreen