import React from 'react';


const AlreadyPaidError = () => {
  return (
    <div className='container'>
        <h3 style={{display: "flex", justifyContent: "center", marginTop: "12px"}}>Already Paid</h3>
        <img src={require('../../assets/img/footballnotfound.png')}/>
    </div>
  )
}

export default AlreadyPaidError