import React, {useState, useEffect} from 'react';
import { Button, Modal, Form, Input, Select, DatePicker, notification } from 'antd';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment-timezone';
import {CiCircleInfo} from "react-icons/ci";
import {PiQuestionThin} from "react-icons/pi"
import { geocodeByAddress } from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import dayjs, { Dayjs } from "dayjs";
import StickyBox from "react-sticky-box";

import "./style.css";
import CommonRightContainer from '../common-right-container';
import AuthFooter from '../../components/auth-footer'
const { Option } = Select;

const EditGuestProfile = (props) => {
  const countryList =[{name:'Canada',value:'CAD'},
{name:'United States',value:'USD'}
]
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [getTempData, setTempData] = useState({});
    const [getScheduledDate, setScheduledDate] = useState([]);
    const [pastDateCount, setPastDateCount] = useState(0);
    const [isSessionDateModalOpen, setIsSessionDateModalOpen] = useState(false);
    const [getComputeOrder, setComputeOrder] = useState({});
    const [isCreation, setIsCreation] = useState(false)
    const [isSelectedProfileSelf, setIsSelectedProfileSelf] = useState(searchParams.get("profile")==="self" ? true : false);
    const [isSelectedProfileChild, setIsSelectedProfileChild] = useState(searchParams.get("profile")==="child" ? true : false);
    const [getSelectedCountry, setSelectedCountry] = useState(null)
    const [address, setAddress] = useState('');
    const [address_results, setaddress_results] = useState([]);
    const [address_fetching, setaddress_fetching] = useState(false);
    const [TaxListData, setTaxListData] = useState([]);
    const [getDOB, setDOB] = useState(null);
    const [getGender, setGender] = useState(null);
    const [getProfiles, setProfiles] = useState({});
    const [getProfileDetails, setProfileDetails] = useState({});
    const [getPhone, setPhone] = useState("");
    const [getEmail, setEmail] = useState("");
    const [isProratedModalOpen, setIsProratedModalOpen] = useState(false);
    const [getSelfGender, setSelfGender] = useState('');
    const [isSelfGender, setIsSelfGender] = useState(false);
    const [getSelfGenderValue, setSelfGenderValue] = useState('')

    const today = moment.tz(_.get(getTempData, 'data.details.meta_details.timezone'));
    const timeZone = _.get(getTempData, 'data.details.meta_details.timezone');

    useEffect(() => {
        getExperienceDetails();
        computeOrder();
        listGuestProfile();
        getGuestProfile();
        loadTaxList();
        viewGuest();
    }, []);
    useEffect(() => {
        let count = 0;
    
        getScheduledDate.forEach((dateString) => {
          const date = moment.tz(dateString, timeZone);
          if (date.isBefore(today)) {
            count++;
          }
        });
    
        setPastDateCount(count);
      }, [getScheduledDate, today, timeZone]);

    const getExperienceDetails = () => {
        props.viewSessionAPI({
            params: {
              id: searchParams.get("id"),
              expand: "template,template_photos,host",
              is_price_porated: 1
            },
            callback: (res, value) => {
              
              if(res){
                setSelectedCountry(_.get(value, 'data.details.meta_details.currency'))
                setTempData(value)
                  let start_date_item = _.get(value, 'data.details.experience_date');
                  let scheduled_date_items = _.get(value, 'data.details.schedule_dates');
                  
                  let concated_array = scheduled_date_items
                  setScheduledDate(concated_array.filter(item => item !== "" && item !== null))
                
              }
            }
          })
    };

    const computeOrder = () => {
        props.experienceComputeOrderAPI({
            body:{
                experience: searchParams.get("id")
            },
            callback: (res, data) => {
                
                if(!_.isEmpty(_.get(data, 'details'))){
                    setComputeOrder(_.get(data, 'details'))
                }
            }
        })
    };

    const getGuestProfile = () => {
      props.guestProfileDataAPI({
        callback: (res, data) => {
          
          if(!_.isEmpty(_.get(data, 'data.details'))){
            form.setFieldsValue({
              email: _.get(data, 'data.details.contact_details.email'),
              phone: _.get(data, 'data.details.phone'),
              country: _.upperCase(_.get(data, 'data.details.country')) === "CA" || _.isEmpty(_.get(data, 'data.details.country')) ? "CAD" : "USD",
              address: _.get(data, 'data.details.address'),
              tax_state: _.get(data, 'data.details.tax_state'),
              zip_code: _.get(data, 'data.details.zip_code')
            })
          }
          
        }
      })
    };

    const loadTaxList =()=>{
      props.taxList({
        params:{limit:100},
        callback:(res,data)=>{
      if(res==true && _.get(data,'data.items[0]'))
          setTaxListData(_.get(data,'data.items'))
        }
      })
          }

    const showSessionDateModal = () => {
        setIsSessionDateModalOpen(true)
      };
      const scheduleHandleOk = () => {
        setIsSessionDateModalOpen(false)
      }
    
      const scheduleHandleCancel = () => {
        setIsSessionDateModalOpen(false)
      };

      const listGuestProfile = () => {
        props.guestProfileListAPI({
            params:{
                status: 1
            },
            callback: (res, data) => {
                
            }
        })
      };

      const discardProfile = () => {
        if(searchParams.get("from") === "edit"){
          navigate(`/guest-profiles?id=${searchParams.get("id")}`);
        } else {
          if(_.isEmpty(_.get(getTempData, 'data.details.host.organization')) && searchParams.get("from") === "questions"){
            navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${searchParams.get("category")}&type=${searchParams.get("type")}`)
            // navigate(`/profile-registration?id=${searchParams.get("id")}`)
          } else if(!_.isEmpty(_.get(getTempData, 'data.details.host.organization')) && searchParams.get("from") === "questions") {
            navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${searchParams.get("category")}&type=${searchParams.get("type")}&organization=${_.get(getTempData, 'data.details.host.organization')}`)
          }else {
            if(searchParams.get("from") === "clauses"){
              navigate(`/registration-clause?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}`);
            } else {
              if(searchParams.get("from") === "pay"){
                navigate(`/payment?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}`);
              }
            }
          }
         
        }
        
      }

      const selectProfileSelf = () => {
        setIsSelectedProfileSelf(true);
       
            setIsSelectedProfileChild(false);
        
      }
      const selectProfileChild = () => {
       
            setIsSelectedProfileSelf(false);
        
        setIsSelectedProfileChild(true);
      };

      const handleChange = (value) => {
        
        
        if(value === 'self'){
          setIsSelfGender(true)
          setGender(null);
        } else {
          setIsSelfGender(false);
          setGender(value);
        }
      };
      const handleChangeCountry = (value) => {
        
        setSelectedCountry(value)
        form.setFieldsValue({
          address: ''
        })
      };

      const onDobChange = (date, dateString) => {
        
        setDOB(dateString)
      };

      const handleChange1 = (value) => {
        setAddress(value);
      };

      const fetchPostalCode = address_components => {
        let postal_code = null;
        address_components.forEach(value => {
            value.types.forEach(type => {
                if (type === 'postal_code') {
                    postal_code = value.long_name
                }
            })
        })
        
        if(postal_code){
          form.setFieldsValue({
            zip_code:postal_code
          })
        }
        
        return postal_code;
    };

    const extractStateName = results => {
      for (let i = 0; i < results[0].address_components.length; i++) {
        const component = results[0].address_components[i];
        if (component.types.includes('administrative_area_level_1')) {
          return component.long_name; // Return the state name
        }
      }
      return '';
    };

      const handleSelect = async (value) => {
    
        
        setaddress_results([])
          setaddress_fetching(true);
          geocodeByAddress(value).then(body => {
              
              const address_results = _.map(body, location => ({
                  text: location.formatted_address,
                  value: JSON.stringify({ text: value, lat: location.geometry.location.lat(), lng: location.geometry.location.lng(), postal_code: fetchPostalCode(_.get(location, 'address_components', [])) }),
              }));
      
              setaddress_results(address_results)
              setaddress_fetching(false);
             
            form.setFieldsValue({
                address: value,
            })
            if(_.size(address_results)>0){
              let a = JSON.parse(address_results[0].value)
              form.setFieldsValue({
                zip_code: _.get(a,'postal_code'),
            })
            }
            
            let state =extractStateName(body)
            let ada = _.filter(TaxListData,(v)=>_.lowerCase(v.state)==_.lowerCase(state))
            if(_.size(ada)>0){
              form.setFieldsValue({
                tax_state: _.get(ada[0],'id')
            })
            }
            
            
            setaddress_results(address_results)
            let a = JSON.parse(address_results[0].value)
            // setLocationData({lat:a.lat,lng:a.lng})
          }).catch(error => {
              setaddress_results([])
              setaddress_fetching(true);
          });
      };
      const searchOptions = {
        componentRestrictions: { country: form.getFieldValue('country')=="USD"?['us']:['ca'] }, // Restrict search to United States and Canada
      };

      const getFilterTax = (list,currency)=>{
        let country = currency=='CAD' ? 'CA' :'US'
            let a = _.filter(list,(v,i)=>_.get(v,'country')==country)
        return a
          };

          const formatPhoneNumber=(phoneNumberString)=> {
            
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            
            if (match) {
              
              setPhone(match[1] + '-' + match[2] + '-' + match[3])
              // return   match[1] + '-' + match[2] + '-' + match[3];
              form.setFieldsValue({
                phone:match[1] + '-' + match[2] + '-' + match[3]
              })
            }
            return null;
          }

          const isJson=(str)=> {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
          }

          const onFinish = (e) => {
              
              // let location_parse = JSON.parse(_.get(_.head(address_results),'value'))
              props.updateGuestProfilesAPI({
                body:{
                    id: _.get(getProfileDetails, 'id'),
                  profile_type: _.get(getProfileDetails, 'profile_type'),
                  first_name: e.first_name,
                  last_name: e.last_name,
                  date_of_birth: getDOB ? getDOB : _.get(getProfileDetails, 'date_of_birth'),
                  gender: isSelfGender ? !_.isEmpty(getSelfGenderValue) ? getSelfGenderValue : _.get(getProfileDetails, 'gender'): getGender ? getGender : _.get(getProfileDetails, 'gender')
                },
                callback: (res, data) => {
                  
                  if(_.get(data, 'success')){
                    setProfiles(_.get(data, 'details[0]'))
                    notification.success({
                      message:'Success',
                      description:"Profile Updated Successfully"
                    });
                    // if(!_.isEmpty(_.get(getTempData, 'data.details.host.organization'))){
                    //   navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${_.get(getTempData, 'data.details.template.category')}&organization=${_.get(getTempData, 'data.details.host.organization')}&type=${_.get(getTempData, 'data.details.type')}`);
                    // } else {
                    //   navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${_.get(getTempData, 'data.details.template.category')}&type=${_.get(getTempData, 'data.details.type')}`);
                    // }

                    if(searchParams.get("from") === "pay"){
                      navigate(`/payment?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}`);
                    } else if(searchParams.get("from") === "edit"){
                      navigate(`/guest-profiles?id=${searchParams.get("id")}`);
                    } else if(!_.isEmpty(_.get(getTempData, 'data.details.host.organization')) && searchParams.get("from") === "questions"){
                      navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${searchParams.get("category")}&type=${searchParams.get("type")}&organization=${_.get(getTempData, 'data.details.host.organization')}`)
                    } else if(_.isEmpty(_.get(getTempData, 'data.details.host.organization')) && searchParams.get("from") === "questions") {
                      navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${searchParams.get("category")}&type=${searchParams.get("type")}`)
                    } else if(searchParams.get("from") === "clauses"){
                      navigate(`/registration-clause?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}`);
                    } else {
                    if(!_.isEmpty(_.get(getTempData, 'data.details.host.organization'))){
                  navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${_.get(getTempData, 'data.details.template.category')}&organization=${_.get(getTempData, 'data.details.host.organization')}&type=${_.get(getTempData, 'data.details.type')}`);
                } else {
                  navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${_.get(getTempData, 'data.details.template.category')}&type=${_.get(getTempData, 'data.details.type')}`);
                }
                // navigate(`/registration-clause?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}`);
              }
                    // navigate(`/registration-clause?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}`);
                  }
                }
                
              });

              if(!_.isEmpty(address_results)){
                props.userProfileUpdateAPI({
               
                  body:{
                    address: e.address,
                    location:`${JSON.parse(_.get(_.head(address_results),'value')).lat},${JSON.parse(_.get(_.head(address_results),'value')).lng}`,
                    country: e.country === 'USD' ? 'US' : 'CA',
                    zip_code: e.zip_code,
                    tax_state: e.tax_state
                  },
                  callback: (res, val) => {
                    
                    if(_.get(val, 'success')){
                      // if(!_.isEmpty(_.get(getTempData, 'data.details.host.organization'))){
                      //   navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${_.get(getTempData, 'data.details.template.category')}&organization=${_.get(getTempData, 'data.details.host.organization')}&type=${_.get(getTempData, 'data.details.type')}`);
                      // } else {
                      //   navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${_.get(getTempData, 'data.details.template.category')}&type=${_.get(getTempData, 'data.details.type')}`);
                      // }
                        if(searchParams.get("from") === "pay"){
                            navigate(`/payment?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}`);
                          } else if(searchParams.get("from") === "edit"){
                            navigate(`/guest-profiles?id=${searchParams.get("id")}`);
                          } else if(!_.isEmpty(_.get(getTempData, 'data.details.host.organization')) && searchParams.get("from") === "questions"){
                            navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${searchParams.get("category")}&type=${searchParams.get("type")}&organization=${_.get(getTempData, 'data.details.host.organization')}`)
                          } else if(_.isEmpty(_.get(getTempData, 'data.details.host.organization')) && searchParams.get("from") === "questions") {
                            navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${searchParams.get("category")}&type=${searchParams.get("type")}`)
                          } else if(searchParams.get("from") === "clauses"){
                            navigate(`/registration-clause?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}`);
                          } else {
                          if(!_.isEmpty(_.get(getTempData, 'data.details.host.organization'))){
                        navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${_.get(getTempData, 'data.details.template.category')}&organization=${_.get(getTempData, 'data.details.host.organization')}&type=${_.get(getTempData, 'data.details.type')}`);
                      } else {
                        navigate(`/registration-question?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}&category=${_.get(getTempData, 'data.details.template.category')}&type=${_.get(getTempData, 'data.details.type')}`);
                      }
                      // navigate(`/registration-clause?id=${searchParams.get("id")}&p_id=${searchParams.get("p_id")}`);
                    }
                  }
                  }
                })
              }

              if(!_.isEmpty(getPhone)){
                // props.userUpdatePhoneAPI({
                //   body: {
                //     phone:getPhone
                //   },
                //   callback: (res, val) => {
                //   }
                // })

                props.userUpdateContactDetailsAPI({
                  body: {
                    contact_details:{phone: getPhone}
                  },
                  callback: (res, val) => {
                  
                  }
                })
              }


              if(!_.isEmpty(getEmail)){
                // props.userUpdateEmailAPI({
                //   body: {
                //     email:getEmail
                //   },
                //   callback: (res, val) => {
                //   }
                // })

                props.userUpdateContactDetailsAPI({
                  body: {
                    contact_details:{email: getEmail}
                  },
                  callback: (res, val) => {
                  
                  }
                })
              }

              
          };

          const disabledDate = (current) => {
            // If the current date is after today, disable it
            return current && current > moment().endOf('day');
          };

          const viewGuest = () => {
            props.viewGuestProfilesAPI({
                params:{
                    id: searchParams.get("p_id")
                },
                callback: (res, data) => {
                    if(!_.isEmpty(_.get(data, 'data.details'))){
                        setProfileDetails(_.get(data, 'data.details'));
                        
                        if(_.get(data, 'data.details.gender') !== 'male' && _.get(data, 'data.details.gender') !== 'female'){
                          setIsSelfGender(true);
                          setSelfGender(_.get(data, 'data.details.gender'))
                        } else {
                          setSelfGender('');
                          setIsSelfGender(false);
                        }
                        form.setFieldsValue({
                            first_name: _.startCase(_.get(data, 'data.details.first_name')),
                            last_name: _.startCase(_.get(data, 'data.details.last_name')),
                            gender: _.get(data, 'data.details.gender') !== 'male' && _.get(data, 'data.details.gender') !== 'female' ? "self" : _.get(data, 'data.details.gender'),
                            date_of_birth: dayjs(_.get(data, 'data.details.date_of_birth'), "YYYY-MM-DD"),
                            self_gender: _.get(data, 'data.details.gender') !== 'male' && _.get(data, 'data.details.gender') !== 'female' ? _.get(data, 'data.details.gender') : ""
                        })
                    }
                }
            })
          };

          const changeEmail = (value) => {
            setEmail(value)
          };

          const showProratedModal = () => {
            setIsProratedModalOpen(true);
          }
        
          const handleProratedOk = () => {
            setIsProratedModalOpen(false)
          }
        
          const handleProratedCancel = () => {
            setIsProratedModalOpen(false)
          };

          const onChangeSelfGender = (e) => {
            setSelfGenderValue(e.target.value)
        }

    const render = () => {
        return(
            <div>
                <div className="banner-container">
                  <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div>
                            <p className='registration-title'>Registration</p>

                            <div>
                              <p className='profile-info-title'>Profile information</p>
                              <Form
                              layout='vertical'
                              form={form}
                              onFinish={(e)=>onFinish(e)}
                                >
                                  <Form.Item
                                    label="First Name"
                                    name="first_name"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your first name!',
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>

                                  <Form.Item
                                    label="Last Name"
                                    name="last_name"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your last name!',
                                      },
                                    ]}
                                  >
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Gender"
                                    name="gender"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your gender!',
                                      },
                                    ]}
                                  >
                                    <Select
                                      onChange={handleChange}
                                      options={[
                                        {
                                          value: 'male',
                                          label: 'Male',
                                        },
                                        {
                                          value: 'female',
                                          label: 'Female',
                                        },
                                        {
                                          value: 'self',
                                          label: 'Self-identify',
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                  {
                                    isSelfGender && <Form.Item
                                    name="self_gender"
                                    >
                                    <Input onChange={(e) => onChangeSelfGender(e)}/>
                                    
                                    </Form.Item>
                                  }
                                  
                                  
                                  <Form.Item
                                    label="Date of birth"
                                    name="date_of_birth"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your date of birth!',
                                      },
                                    ]}
                                  >
                                    <DatePicker 
                                    disabledDate={disabledDate}
                                    style={{width: "100%"}} 
                                    onChange={onDobChange}
                                    format={"YYYY-MM-DD"}
                                    />
                                  </Form.Item>
                                  <p className='profile-info-title'>Contact information</p>
                                  <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your email!',
                                      },
                                    ]}
                                  >
                                    <Input onChange={(e) => changeEmail(_.get(e, 'target.value'))}/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Phone number"
                                    name="phone"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your phone number!',
                                      },
                                    ]}
                                  >
                                    <Input onChange={(e)=>formatPhoneNumber(_.get(e,'target.value'))}/>
                                  </Form.Item>
                                  <Form.Item
                                    label="Country"
                                    name="country"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your country!',
                                      },
                                    ]}
                                  >
                                   <Select
                                onChange={(value)=>{handleChangeCountry(value)}}
                                style={{width:'100%'}}
                                  optionFilterProp="children">
                                {_.map(countryList,(v,i)=>{
                                  return  <Option key={i} value={_.get(v,'value')}>{_.startCase(_.get(v,'name'))}</Option>
                                })}
                                </Select>
                                  </Form.Item>
                                  <Form.Item
                                    label="Address"
                                    name="address"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your address!',
                                      },
                                    ]}
                                  >
                                    <PlacesAutocomplete
                              

                              value={address}
                              onChange={handleChange1}
                              onSelect={handleSelect}
                              searchOptions={searchOptions}
                              
                            >
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <Input {...getInputProps({ placeholder: 'Search address' })}  />
                                  {/* <input {...getInputProps({ placeholder: 'Search address' })} /> */}
                                  <div>
                                    {loading ? <div>Loading...</div> : null}
                                    {suggestions.map((suggestion) => {
                                      const style = {
                                        backgroundColor: suggestion.active ? '#e3e3e3' : '#ffffff',
                                        marginTop:10,
                                      };
                                      return (
                                        <div {...getSuggestionItemProps(suggestion, { style })}>
                                          {suggestion.description}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                                  </Form.Item>
                                  <Form.Item
                                    label="Provience/State"
                                    name='tax_state'
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your provience!',
                                      },
                                    ]}
                                  ><Select getPopupContainer={trigger => trigger.parentNode}
                                  placeholder={'Search Province / State...'}
                              >
                                  { _.map(getFilterTax(TaxListData,getSelectedCountry),(d,i) => {
                                    
                                    return(
                                      <Select.Option  key={_.get(d,'id')}>{_.startCase(_.get(d,'state'))}</Select.Option>
                                    )
                                    })}
                              </Select>
                                  </Form.Item>
                                  <Form.Item
                                    label="Postal/Zip code"
                                    name="zip_code"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please input your postal/zip code!',
                                      },
                                    ]}
                                  >
                                    <Input />
                                  </Form.Item>
                                  <Form.Item> 
                            <Button
                            onClick={() => discardProfile()}
                            style={{width: "100%", 
                            height: "40px", 
                            marginTop: "40px",
                             backgroundColor: '#EAECF0',
                              color: "#1D2939",
                            }}
                            >Discard profile</Button>
                           
                            <Button
                            style={{
                                width: "100%", 
                            height: "40px", 
                            marginTop: "8px", 
                            backgroundColor:  "#EF6820",
                             color: "#FFFFFF",
                            }}
                            htmlType="submit"
                            // disabled={_.isEmpty(getComputeOrder) ? true : false}
                            >Save profile</Button>
                            </Form.Item>
                                </Form>
                            </div>

                          
                            
                            </div>
                            <p className='registration-profile-title'>Additional Information</p>
                            {
                             _.size( _.get(getTempData, 'data.details.template.clauses')) > 0 && <p className='registration-profile-title'>Clauses</p>
                            }
                        </div>
                        <div className="col-lg-4">
            {/* <div style={{marginTop: "16px"}}> */}
            <StickyBox offsetTop={16} offsetBottom={20}>
              <CommonRightContainer/>
              </StickyBox>
              {/* </div> */}
              </div>
                    </div>
                  </div>
              
            </div>
            <div>
    <Modal width={300} closable={false} footer={null} open={isSessionDateModalOpen} onOk={scheduleHandleOk} onCancel={scheduleHandleCancel}>
    <p className='register-location'>Session Schedule</p>
                 
                  <div>
      {_.size(getScheduledDate) > 0 && getScheduledDate.map((item, index) => {
        const date = moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone'));
        const isPastDate = date.isBefore(today);
        const dateClassName = isPastDate ? 'register-schedule-strike' : 'register-schedule';
        return(
        <div
          key={index}
          className='card'
          style={{backgroundColor: index % 2 === 0 ? "#F9FAFB" : '#FFFFFF', border: index % 2 !== 0 ? "none" : "", marginTop: "8px", padding: "16px", paddingTop: "5px", boxShadow: "none", borderRadius: '0px'}}
        >
          <div style={{display: "flex", flexDirection: "column"}}>
            <p className={dateClassName}>{moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("dddd, MMM DD, YYYY")}</p>
            <p className={dateClassName}>{`${moment.tz(item, _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A")} to ${moment.tz(_.get(getTempData, 'data.details.experience_end_date'), _.get(getTempData, 'data.details.meta_details.timezone')).format("hh:mm A z")}`}</p>
            </div>
        </div>
        )
  })}
    </div>
      </Modal>
      </div>
      <div>
    <Modal closable={false} footer={null} open={isProratedModalOpen} onOk={handleProratedOk} onCancel={handleProratedCancel}>
      <div style={{display:"flex", flexDirection: "row"}}>
      This session has already started and the price has been prorated. View the schedule to see the dates remaining
        </div>
      </Modal>
      </div>
      {/* <AuthFooter/> */}
            </div>
        )
    };
    return render();
 
}

const mapStateToProps = state => {
    return {
       
  
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
    experienceComputeOrderAPI: details => dispatch(global.redux.action.experience.computeOrder(details)),
    guestProfileListAPI: details => dispatch(global.redux.action.guest.profilesList(details)),
    guestProfileDataAPI: details => dispatch(global.redux.action.guest.profile(details)),
    taxList: details => dispatch(global.redux.action.tax.list(details)),
    guestProfilesCreateAPI: details => dispatch(global.redux.action.guest.guestProfilesCreate(details)),
    userProfileUpdateAPI: details => dispatch(global.redux.action.guest.update(details)),
    viewGuestProfilesAPI: details => dispatch(global.redux.action.guest.guestProfilesView(details)),
    updateGuestProfilesAPI: details => dispatch(global.redux.action.guest.guestProfilesUpdate(details)),
    userUpdatePhoneAPI: details => dispatch(global.redux.action.guest.guestUpdatePhone(details)),
    userUpdateEmailAPI: details => dispatch(global.redux.action.guest.guestUpdateEmail(details)),
    userUpdateContactDetailsAPI: details => dispatch(global.redux.action.guest.updateContactDetails(details)),
  });
  export default connect(mapStateToProps, mapDispatchToProps)(EditGuestProfile)