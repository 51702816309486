import React, {useState, useEffect, useRef} from 'react';
import { Modal, Button, notification } from 'antd';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { IconButton, InputAdornment } from '@mui/material';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import appEnvirontVariables from "../../core_units/config/env-variables";

import './style.css';
import '../session-list/style.css'

const useStyles = makeStyles({
    root: {
      // input label when focused
      "& label.Mui-focused": {
        color: "#FF692E"
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
        borderBottomColor: "#FF692E"
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#FF692E"
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#FF692E"
        }
      }
    },
    focused: {
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#FF692E',  // Change this to the desired focused border color
      },
      "& label.Mui-focused": {
        color: "#FF692E"
      },
      
    },
    hover: {
      // input label when focused
      "& label.Mui-focused": {
        color: "#FF692E"
      },
      // focused color for input with variant='standard'
      "& .MuiInput-underline:after": {
        borderBottomColor: "#FF692E"
      },
      // focused color for input with variant='filled'
      "& .MuiFilledInput-underline:after": {
        borderBottomColor: "#FF692E"
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#FF692E"
        }
      }
    }
  
  });

const LoginWithPhone = ({ sendDataToParent, isModalVisible, handleCancel, length = 6, onChange, props, experience_id, handle }) => {
  const prefix = "+1 ";
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const [forms, setForms] = useState(0);
    const [phoneInputValue, setPhoneInputValue] = useState("+1");
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [otp, setOtp] = useState(Array(length).fill(''));
    const [inValidOtp, setInvalidOtp] = useState(false);
    const inputRefs = useRef([]);
    const classes = useStyles();
    const [timer, setTimer] = useState(30);
    const [userData, setUserData] = useState({});
    const [dotPulseBtnLoader, setDotPulseBtnLoader] = useState(false)

    useEffect(() => {
      let interval;
      if (forms === 3) {
        interval = setInterval(() => {
          setTimer(prevTimer => {
            if (prevTimer === 0) {
              clearInterval(interval); // Stop the timer when it reaches 0
              return 0;
            }
            return prevTimer - 1;
          });
        }, 1000);
      } else {
        setTimer(30); // Reset timer when modal is closed // Reset timer when modal is closed
      }
  
      return () => clearInterval(interval); // Clear interval on unmount or when modal is closed
    }, [forms === 3]);

    useEffect(() => {
      let interval;
      if (forms === 2) {
        interval = setInterval(() => {
          setTimer(prevTimer => {
            if (prevTimer === 0) {
              clearInterval(interval); // Stop the timer when it reaches 0
              return 0;
            }
            return prevTimer - 1;
          });
        }, 1000);
      } else {
        setTimer(30); // Reset timer when modal is closed // Reset timer when modal is closed
      }
  
      return () => clearInterval(interval); // Clear interval on unmount or when modal is closed
    }, [forms === 2]);

   

    const modalTitle = (
        <div style={{display: "flex", flexDirection: "column"}}>
          <img src={require('../../assets/img/communiti-logo.png')} alt="Your Image" style={{ marginRight: '8px', marginBottom: '4px', width: "200px"}} />
           {forms === 0 && <div className='continue-with-email' style={{marginTop: "24px"}}>Login or signup with your phone number</div>}
          {forms===1 && <div className='continue-with-email' style={{marginTop: "24px"}}>Signup with your phone number</div>}
          {forms===2 && <div className='continue-with-email' style={{marginTop: "24px"}}>Signup with your phone number</div>}
          {forms===3 && <div className='continue-with-email' style={{marginTop: "24px"}}>Login with your phone number</div>}
          {forms===4 && <div className='continue-with-email' style={{marginTop: "24px"}}>Signup with your phone number</div>}
          {/* <div style={{ borderBottom: "1px solid var(--Gray-300, #D0D5DD)", width: "100%", marginTop: "24px"}}></div> */}
        </div>
      );

      const login_with_phone_bck_btn  = {
        display: "flex",
        padding: "10px 18px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flex: "1 0 0",
        borderRadius: "8px",
        border: "1px solid var(--Gray-300, #D0D5DD)",
        background: "var(--Base-White, #FFF)",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        height: "44px",
        cursor: "pointer"
      };

      const login_with_phone_nxt_btn  = {
        display: "flex",
        padding: "10px 18px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flex: "1 0 0",
        borderRadius: "8px",
        border: "1px solid var(--Gray-300, #D0D5DD)",
        background: "var(--Orange-dark-400, #FF692E)",
        color: "var(--Base-White, #FFF)", 
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        height: "44px",
        marginLeft: "12px",
        cursor: "pointer"
      };

      const login_with_phone_nxt_btn_disabled  = {
        display: "flex",
        padding: "10px 18px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flex: "1 0 0",
        borderRadius: "8px",
        border: "1px solid var(--Orange-dark-200, #FFD6AE)",
        background: "var(--Orange-dark-200, #FFD6AE)",
        color: "var(--Base-White, #FFF)", 
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        height: "44px",
        marginLeft: "12px",
        cursor: "not-allowed"
      };

      const backBtnDisabled = {
        display: "flex",
        padding:"10px 18px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flex: "1 0 0",
        borderRadius: "8px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        background: "var(--Base-White, #FFF)",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        cursor: "not-allowed"
      }

      const back_btn_text = {
        color: "var(--Gray-700, #344054)",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px"
      };

      const disabledBackBtnText = {
        color:" var(--Gray-300, #D0D5DD)",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        cursor: "not-allowed"
      }

      const nxt_btn_text = {
        color: "var(--Base-White, #FFF)",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px" 
      };

      const verification_code_title  = {
        color: "var(--Gray-600, #475467)",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "20px",
        marginTop: "24px"
      };

      const recive_text = {
        color: "var(--Gray-600, #475467)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
        marginTop: "24px"
      };

      const resend_text = {
        color: "var(--Gray-600, #475467)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
        marginTop: "24px",
        textDecorationLine: "underline",
        cursor: "pointer",
        marginLeft: "2px"
      };

      const phone_txt_style = {
        color: "var(--Gray-600, #475467)",
        textAlign: "center",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "18px"
      };

      const phone_verification_note = {
        color: "#475467",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        marginTop: "3px"
      };

      const inCorrectCodeStyle = {
        color: "var(--Error-500, #F04438)",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "20px",
        marginTop: "6px"
      }

      const proceedForms = (value) => {
        setDotPulseBtnLoader(false);
        if(inValidOtp){
          setInvalidOtp(false);
        };
        setForms(value);
        // setFirstName('');
        // setLastName('');
        setOtp(Array(length).fill(''));
        setInvalidOtp(false);
      };
      
      const handleChange = (index, value) => {
        // if(inValidOtp){
          setInvalidOtp(false)
        // }
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        if (onChange) {
          onChange(newOtp.join('')); // Notify parent component of the OTP
        }
        if (value !== '' && index < length - 1) {
          inputRefs.current[index + 1].focus();
        }
      };
    
      const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
          inputRefs.current[index - 1].focus();
        }
      };

      const isOtpEmpty = otp.some((digit) => digit === '');
      const isOtpFull = !otp.includes('');

      // const handleChangePhone = (event) => {
      //   const newValue = event.target.value;
      //   const regex = /^\+1 \d*$/
      //   if (newValue === "") {
      //     setPhoneInputValue(prefix); // Preserve the prefix when clearing other values
      //   } else if (regex.test(newValue)) {
      //     setPhoneInputValue(newValue); // Update the value with the prefix and numbers
      //   }
      // };

      const handleChangePhone = (event) => {
        const n_value = event.target.value
        const numericValue = n_value.replace(/[^0-9]/g, "");
        
        // If numericValue is empty or consists of only the country code, keep "+1"
        if (numericValue.length === 0 || numericValue === "1") {
            setPhoneInputValue("+1");
        } else {
          // Format the phone number with the country code
          const formattedValue = "+1 " + phoneNumberAutoFormat(numericValue.substring(1));
          
          setPhoneInputValue(formattedValue);
        }
          };

          const phoneNumberAutoFormat = (phoneNumber) => {
            const number = phoneNumber.trim().replace(/[^0-9]/g, "");
          
            if (number.length < 4) return number;
            if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
            if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
            return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
          };

      const handleChangeFirstName = (event) => {
        setFirstName(event.target.value)
      };

      const handleChangeLastName = (event) => {
          setLastName(event.target.value);
      };

      const shouldDisableBtn = () => {
        return _.isEmpty(firstName) || _.isEmpty(lastName)
      };

      const modifiedHandleCancel = (value) => {
        handleCancel();
        setFirstName('');
        setLastName('');
        setPhoneInputValue(prefix);
        setOtp(Array(length).fill(''));
        proceedForms(0);
        setInvalidOtp(false);
        setUserData({});
        if(value === 'first_form'){
          const data = true
          // Call the callback function passed from the parent
            sendDataToParent(data);
        }
       
    };

    const handleClickSignUp = () => {
      setDotPulseBtnLoader(true)
      props.checkProfileAPI({
        params:{
          user_type: 0,
          type: 'phone',
          data: formatPhoneNumber(phoneInputValue)
        },
        callback: (res, val) => {
          setDotPulseBtnLoader(false)
          
          if(_.get(val, 'error.statusCode') === 404){
            proceedForms(1)
          } else {
            setUserData(_.get(val, 'data.details'));
            props.otpLoginAPI({
              body:{
                grant_type: "password",
                client_id: appEnvirontVariables.API.CLIENT_ID,
                client_secret: appEnvirontVariables.API.CLIENT_SECRET,
                user_type: 0,
                country: 'CA',
                phone: formatPhoneNumber(phoneInputValue),
                action: 'request_otp',
              },
              callback: (res, val) => {
                if(_.get(val, 'success')){
                  proceedForms(3);
                }
              }
            })
          }
        }
      })
    };

    const handleSendOtp = () => {
      setDotPulseBtnLoader(true)
      props.otpLoginAPI({
        body:{
          grant_type: "password",
          client_id: appEnvirontVariables.API.CLIENT_ID,
                client_secret: appEnvirontVariables.API.CLIENT_SECRET,
          user_type: 0,
          country: 'CA',
          phone: formatPhoneNumber(phoneInputValue),
          action: 'request_otp',
        },
        callback: (res, val) => {
          setDotPulseBtnLoader(false);
          if(_.get(val, 'success')){
            proceedForms(2);
          }
        }
      })
    };

    const signUpOtpAuthenticate = () => {
      setDotPulseBtnLoader(true)
      // proceedForms(4)
      props.verifyOtpAPI({
        body:{
          grant_type: "password",
          user_type: 0,
          country: 'CA',
          phone: formatPhoneNumber(phoneInputValue),
          action: 'verify_otp',
          otp: otp.join(''),
          first_name: firstName,
          last_name: lastName
        },
        callback: (res, data) => {
          setDotPulseBtnLoader(false)
          if(_.get(data, 'error.code') === 400){
            setInvalidOtp(true)
          };

          if(res==true && _.get(data,'isLoggedIn')==true){
            // modifiedHandleCancel();
            proceedForms(4);
                      }
        }
      })
    };

    const handleVerifyOtp = () => {
        setDotPulseBtnLoader(true)
      props.verifyOtpAPI({
        body:{
          grant_type: "password",
          user_type: 0,
          country: 'CA',
          phone: formatPhoneNumber(phoneInputValue),
          action: 'verify_otp',
          otp: otp.join(''),
          // first_name: firstName,
          // last_name: lastName
        },
        callback: (res, data) => {
          setDotPulseBtnLoader(false)
          if(_.get(data, 'error.code') === 400){
            setInvalidOtp(true)
          }
          if(res==true && _.get(data,'isLoggedIn')==true){
            notification.success({
            message:"Success",
            description:'Login successful'
            });
            if(experience_id && handle){
              localStorage.setItem('login_methods', 'Phone');
              navigate(`/guest-profiles?id=${experience_id}&handle=${handle}`);
            }
            
            modifiedHandleCancel();
                      }
        }
      })
    };

    const handleResendOtp = () => {
      props.otpLoginAPI({
        body:{
          grant_type: "password",
          client_id: appEnvirontVariables.API.CLIENT_ID,
                client_secret: appEnvirontVariables.API.CLIENT_SECRET,
          user_type: 0,
          country: 'CA',
          phone: formatPhoneNumber(phoneInputValue),
          action: 'request_otp',
        },
        callback: (res, val) => {
        }
      })
    };

    const handleGetStarted = (value) => {
      if(_.get(props, 'islogindata.isLoggedIn')){
        if(experience_id && handle){
          localStorage.setItem('login_methods', 'Phone');
          navigate(`/guest-profiles?id=${experience_id}&handle=${handle}`);
        }
        
        modifiedHandleCancel();
      }
    }

    const formatPhoneNumber = (value) => {
      // Remove non-numeric characters
      let strippedNumber = value.replace(/\D/g, '');
      // Remove the country code if it starts with '1'
      if (strippedNumber.startsWith('1')) {
        strippedNumber = strippedNumber.substring(1);
      }
      return strippedNumber
    };
        
  return (
    <div>
        <Modal
      title={modalTitle}
      open={isModalVisible}
      onCancel={modifiedHandleCancel}
      footer={null}
    >
      {/* Your login with phone form or content */}
      <div>
        <hr/>
     { forms===0 && <div>
     <TextField
     style={{width: "100%", marginTop: "24px"}}
     className={classes.root}
     required
     label="Phone number"
     variant="outlined"
     defaultValue={phoneInputValue}
     inputProps={{
        inputMode:'numeric',
        pattern:'[0-9]*',
        maxLength: 15,
     }}
     value={phoneInputValue}
     onChange={handleChangePhone}
     />
     {phoneInputValue.length >=15 &&<div style={phone_verification_note}>We’ll send you a verification code on this number</div>}
     <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "24px"}}>
        <button onClick={() => modifiedHandleCancel('first_form')} style={login_with_phone_bck_btn} className='raise-btn'><img src={require('../../assets/img/login-back.png')} style={{height: "20px", width: "20px"}}/><div style={back_btn_text}>back</div></button>
        {!dotPulseBtnLoader && phoneInputValue.length >=15 && <button onClick={() => handleClickSignUp()} style={login_with_phone_nxt_btn} className='raise-btn'><div style={nxt_btn_text}>Next</div></button>}
        {phoneInputValue.length<15 && <button style={login_with_phone_nxt_btn_disabled}><div style={nxt_btn_text}>Next</div></button>}

        {/* dot pulse button loader */}

        { dotPulseBtnLoader && <div style={{marginLeft: "12px"}} className='otp-loading-btn-container'>
          <div className='dot-pulse'></div>
          </div>}
          {/* dot pulse button loader end */}
     </div>
     </div>}
     {
      forms===1 && <div>
        <div className='template-description' style={{marginTop: "24px"}}>Looks like this is your first time here. Let’s get you started by creating a new account!</div>
        <TextField
        required
     style={{width: "100%", marginTop: "24px"}}
     className={classes.root}
     label="Phone number"
     variant="outlined"
     id='phone-number'
     disabled
     value={phoneInputValue}
     inputProps={{
        inputMode:'numeric',
        pattern:'[0-9]*',
        maxLength: 13,
     }}
     InputProps={{
      endAdornment: (
        <InputAdornment style={{cursor:'pointer'}} position="end">
               { <div style={phone_txt_style} onClick={() => {proceedForms(0);}}>Change</div>}

        </InputAdornment>
      ),
     }}
     />
     <TextField
        required
     style={{width: "100%", marginTop: "24px"}}
     className={classes.root}
     onChange={handleChangeFirstName}
     label="First name"
     variant="outlined"
     id='first-name'
     />
     <TextField
        required
     style={{width: "100%", marginTop: "24px"}}
     className={classes.root}
     onChange={handleChangeLastName}
     label="Last name"
     variant="outlined"
     id='last-name'
     />
    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "24px"}}>
        <button  style={backBtnDisabled}><img src={require('../../assets/img/flip-backward-disabled.png')} style={{height: "20px", width: "20px"}}/><div style={disabledBackBtnText}>back</div></button>
        { !dotPulseBtnLoader && !_.isEmpty(firstName) && !_.isEmpty(lastName) && <button onClick={() => handleSendOtp()} style={login_with_phone_nxt_btn} className='raise-btn'><div style={nxt_btn_text}>Next</div></button>}
        { shouldDisableBtn() && <button style={login_with_phone_nxt_btn_disabled} className='raise-btn'><div style={nxt_btn_text}>Next</div></button>}
        
        { dotPulseBtnLoader && <div style={{marginLeft: "12px"}} className='otp-loading-btn-container'>
          <div className='dot-pulse'></div>
          </div>}
     </div>
    
      </div>
     }

     {
      forms===3 && <div>
        <div className='template-description' style={{marginTop: "24px"}}>Welcome back, {`${_.get(userData, 'first_name')}`}</div>
        <TextField
        required
     style={{width: "100%", marginTop: "24px"}}
     className={classes.root}
     label="Phone number"
     variant="outlined"
     id='phone-number'
     disabled
     value={phoneInputValue}
     inputProps={{
        inputMode:'numeric',
        pattern:'[0-9]*',
        maxLength: 13,
     }}
     InputProps={{
      endAdornment: (
        <InputAdornment style={{cursor:'pointer'}} position="end">
               { <div style={phone_txt_style} onClick={() => {proceedForms(0); setTimer(30);setUserData({});}}>Change</div>}

        </InputAdornment>
      ),
     }}
     />
     <div style={verification_code_title}>Verification Code</div>
     <div style={{ display: 'flex', marginTop: "12px" }}>
      {Array.from({ length }, (_, index) => (
        <React.Fragment key={index}>
        <input
        key={index}
        ref={(ref) => (inputRefs.current[index] = ref)}
        type="text"
        maxLength={1}
        style={{
          width: '64px',
          padding: '2px 8px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '8px',
          borderRadius: '8px',
          border: '1px solid var(--Gray-300, #D0D5DD)',
          background: 'var(--Base-White, #FFF)',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          marginLeft: "8px",
          height: "60px",
          outline: "none",
          color: !inValidOtp ? 'var(--Gray-600, #475467)' : 'var(--Error-500, #F04438)', // Text color
            textAlign: 'center', // Text alignment
            fontFamily: 'Inter', // Font family
            fontSize: '48px', // Font size
            fontStyle: 'normal', // Font style
            fontWeight: 400, // Font weight
            lineHeight: '60px', // Line height
            letterSpacing: '-0.96px', // Letter spacing
        }}
        value={otp[index]}
        onChange={(e) => handleChange(index, e.target.value)}
        onKeyDown={(e) => handleKeyDown(index, e)}
      />
      {index === 2 && <span 
       style={{
        display: 'flex',
        width: '28px',
        height: '64px',
        flexDirection: 'column',
        justifyContent: 'center',
        color: !isOtpEmpty ? 'var(--Gray-600, #475467)':'var(--Gray-300, #D0D5DD)',
        textAlign: 'center',
        fontSize: '60px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '72px',
        letterSpacing: '-1.2px',
        marginLeft: "8px",
      }}
      >-</span>}
      </React.Fragment>
      ))}
    </div>
    {inValidOtp && <div style={inCorrectCodeStyle}>Incorrect code! Please try again</div>}
    <div style={{display:'flex', flexDirection: "row"}}>
    <div style={recive_text}>Didn’t receive your code?</div> {timer === 0 && <div style={resend_text} onClick={() => handleResendOtp()}>Resend verification code</div>} {timer !== 0 && <div style={recive_text}>Resend verification code in {timer}s</div>}
    </div>
    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "24px"}}>
        <button style={backBtnDisabled}><img src={require('../../assets/img/flip-backward-disabled.png')} style={{height: "20px", width: "20px"}}/><div style={disabledBackBtnText}>back</div></button>
        { !dotPulseBtnLoader && !isOtpEmpty && <button onClick={() => handleVerifyOtp()} style={login_with_phone_nxt_btn} className='raise-btn'><div style={nxt_btn_text}>Login</div></button>}
        { isOtpEmpty && <button style={login_with_phone_nxt_btn_disabled} className='raise-btn'><div style={nxt_btn_text}>Login</div></button>}

        { dotPulseBtnLoader && <div style={{marginLeft: "12px"}} className='otp-loading-btn-container'>
          <div className='dot-pulse'></div>
          </div>}
     </div>
      </div>
     }
     {
      forms === 4 && <div>
        <div style={{alignItems:'center',display:'flex',flexDirection:'column' }}>
          <img src={require('../../assets/img/account-created.png')} style={{height: "48px", width: "48px", marginTop: "48px"}}/>
          <div className='continue-with-email' style={{marginTop: "16px"}}>Account created!</div>
          <div className='template-description' style={{marginTop: "8px"}}>Your account was successfully created!</div>
        </div>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "24px"}}>
        <button style={backBtnDisabled}><img src={require('../../assets/img/flip-backward-disabled.png')} style={{height: "20px", width: "20px"}}/><div style={disabledBackBtnText}>back</div></button>
        {<button onClick={() => handleGetStarted()} style={login_with_phone_nxt_btn}><div style={nxt_btn_text}>Get started</div></button>}
        
     </div>
      </div>
     }
     {
      forms === 2 && <div>
      <TextField
      required
   style={{width: "100%", marginTop: "24px"}}
   className={classes.root}
   label="Phone number"
   variant="outlined"
   id='phone-number'
   disabled
   value={phoneInputValue}
   inputProps={{
      inputMode:'numeric',
      pattern:'[0-9]*',
      maxLength: 13,
   }}
   />
   <div style={verification_code_title}>Verification Code</div>
   <div style={{ display: 'flex', marginTop: "12px" }}>
    {Array.from({ length }, (_, index) => (
      <React.Fragment key={index}>
      <input
      key={index}
      ref={(ref) => (inputRefs.current[index] = ref)}
      type="text"
      maxLength={1}
      style={{
        width: '64px',
        padding: '2px 8px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid var(--Gray-300, #D0D5DD)',
        background: 'var(--Base-White, #FFF)',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        marginLeft: "8px",
        height: "60px",
        outline: "none",
        color: !inValidOtp ? 'var(--Gray-600, #475467)' : 'var(--Error-500, #F04438)',
          textAlign: 'center', // Text alignment
          fontFamily: 'Inter', // Font family
          fontSize: '48px', // Font size
          fontStyle: 'normal', // Font style
          fontWeight: 400, // Font weight
          lineHeight: '60px', // Line height
          letterSpacing: '-0.96px', // Letter spacing
      }}
      value={otp[index]}
      onChange={(e) => handleChange(index, e.target.value)}
      onKeyDown={(e) => handleKeyDown(index, e)}
    />
    {index === 2 && <span 
     style={{
      display: 'flex',
      width: '28px',
      height: '64px',
      flexDirection: 'column',
      justifyContent: 'center',
      color: !isOtpEmpty ? 'var(--Gray-600, #475467)':'var(--Gray-300, #D0D5DD)',
      textAlign: 'center',
      fontSize: '60px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '72px',
      letterSpacing: '-1.2px',
      marginLeft: "8px"
    }}
    >-</span>}
    </React.Fragment>
    ))}
  </div>
  {inValidOtp && <div style={inCorrectCodeStyle}>Incorrect code! Please try again</div>}
  <div style={{display:'flex', flexDirection: "row"}}>
  <div style={recive_text}>Didn’t receive your code?</div> {timer === 0 && <div style={resend_text} onClick={() => handleResendOtp()}>Resend verification code</div>} {timer !== 0 && <div style={recive_text}>Resend verification code in {timer}s</div>}
  </div>
  <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "24px"}}>
      <button style={backBtnDisabled}><img src={require('../../assets/img/flip-backward-disabled.png')} style={{height: "20px", width: "20px"}}/><div style={disabledBackBtnText}>back</div></button>
      { !dotPulseBtnLoader && !isOtpEmpty && <button onClick={() => signUpOtpAuthenticate()} style={login_with_phone_nxt_btn} className='raise-btn'><div style={nxt_btn_text}>Signup</div></button>}
      { isOtpEmpty && <button style={login_with_phone_nxt_btn_disabled}><div style={nxt_btn_text}>Signup</div></button>}

      { dotPulseBtnLoader && <div style={{marginLeft: "12px"}} className='otp-loading-btn-container'>
          <div className='dot-pulse'></div>
          </div>}
   </div>
    </div>
     }
     </div>
    </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
     
islogindata: _.get(state, 'app.account.isLogin'),
  }
}

const mapDispatchToProps = dispatch => ({
  viewSessionAPI: details => dispatch(global.redux.action.experience.view(details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginWithPhone)