import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateHostState;
// host actions

export default {
    // get profile data of the current logged in host
    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/state-taxes/list`
                },
                actionType: actionType.host.taxList,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    
}