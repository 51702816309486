import _ from 'lodash';

import actionType from '../actionTypes';
const reducerName = actionType.reduxUpdateNotificationsState;
// host actions

export default {
    // get experience templates of logged in host
    list: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/notifications/list`
                },
                actionType: actionType.notifications.list,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    delete: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/notifications/delete`
                },
                actionType: actionType.notifications.delete,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
    read: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'post',
                    url: `/notifications/read`
                },
                actionType: actionType.notifications.read,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },

    view: (componentRequest) => {
        return dispatch => {
            const requestOptions = {
                axiosParams: {
                    method: 'get',
                    url: `/notifications/view`
                },
                actionType: actionType.notifications.view,
                reducerName,
                dispatch,
                componentRequest,
                authorization: true
            };
            global.api.requestAPI(requestOptions);
        }
    },
}