import { Button as AntButton, Divider, Modal } from 'antd';
import { IoMdClose } from "react-icons/io";
import './style.css'
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { TbArrowBackUp } from 'react-icons/tb';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { connect } from 'react-redux';
import _ from 'lodash'

import { AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai";
import SuccessModal from './success-modal';

const { useState, useEffect, useRef, default: React,createRef } = require('react');

const useStyles = makeStyles({
       helperText: {
              color: '#475467 !important',
              fontFamily: `Inter, sans-serif !important`,
              fontSize: '0.875rem !important',
              fontWeight: 400,
              lineHeight: '16px' /* 114.286% */
       },
       root: {
              // input label when focused
              "& label.Mui-focused": {
                     color: "#FF692E"
              },
              // focused color for input with variant='standard'
              "& .MuiInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              // focused color for input with variant='filled'
              "& .MuiFilledInput-underline:after": {
                     borderBottomColor: "#FF692E"
              },

              "& .MuiInputBase-input.Mui-disabled": {
                     WebkitTextFillColor: "#475467",

                     "& > fieldset": {
                            borderColor: "red"
                     }
              },
              // focused color for input with variant='outlined'
              "& .MuiOutlinedInput-root": {
                     "&.Mui-focused fieldset": {
                            borderColor: "#FF692E"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#FF692E"
                     },

              },


              '& .MuiInputBase-root.Mui-disabled': {

                     "&:focus fieldset": {
                            borderColor: "#D0D5DD"
                     },
                     '&:hover fieldset': {
                            //       border: '#FF692E',
                            borderColor: "#D0D5DD"
                     },
                     backgroundColor: 'var(--Gray-50, #F9FAFB)', // Change to your desired background color
                     borderColor: '#c0c0c0', // Change to your desired border color
              },
              '& .MuiInputBase-root.Mui-disabled:hover': {
                     borderColor: '#c0c0c0', // Change to your desired focused border color
              },
       },


});

const EmailOtpVerification = (props) => {
       const classes = useStyles();
       
       const [isModalOpen, setIsModalOpen] = useState(false);

       const [formStep, setformStep] = useState(1)
       const [otp, setOtp] = useState(['', '', '', '', '', '']);
       const [errorOtp, seterrorOtp] = useState(false)
  const inputRefs = useRef([...Array(6)].map(() => createRef()));
  const [enableResendOtp, setenableResendOtp] = useState(false)
  const [seconds, setSeconds] = useState(60);
  const [successModal, setsuccessModal] = useState(false)
  const [usersDetails, setusersDetails] = useState({});
  const [dotPulseBtnLoader, setDotPulseBtnLoader] = useState(false);

       useEffect(() => {
              setIsModalOpen(props?.isModalOpen?.visible)
              setformStep(props?.isModalOpen?.form)
       }, [props?.isModalOpen])

       useEffect(() => {
              if (seconds > 0) {
                const intervalId = setInterval(() => {
                  setSeconds(prevSeconds => prevSeconds - 1);
                }, 1000);
          
                return () => clearInterval(intervalId);
              }
            }, [seconds]);
       

       const handleChange = (index, value) => {
              
              seterrorOtp(false)
              if (value !== '') {
                const newOtp = [...otp];
                newOtp[index] = value;
                setOtp(newOtp);
                if (index < 5 && value !== '') {
                  inputRefs.current[index + 1].current.focus();
                }
              }else if (value === '' && index >= 0) {
                     const newOtp = [...otp];
                     newOtp[index] = '';
                     setOtp(newOtp);
                     
                     
                     index!=0 &&inputRefs.current[index - 1].current.focus();
                   }
            };
          
            const handleKeyUp = (index, event) => {
              
              if (event.key === '"Shift"' || event.getModifierState('CapsLock')) {
                     event.preventDefault(); // Prevent default behavior (moving focus)
                   } else if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
                inputRefs.current[index - 1].current.focus();
              } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '') {
                inputRefs.current[index + 1].current.focus();
              }
              
            };
          
            const handleKeyDown = (index, event) => {
              if (event.key === '"Shift"' || event.getModifierState('CapsLock')) {
                     event.preventDefault(); // Prevent default behavior (moving focus)
                   } else if (event.key === 'Backspace' && index > 0 && otp[index] === '') {
                inputRefs.current[index - 1].current.focus();
              } else if (event.key !== 'Backspace' && index < 5 && otp[index] !== '') {
                inputRefs.current[index + 1].current.focus();
              }

              
            };
       const accountsResendVerification = () => {
              props.accountsResendVerification({
                     body: {
                            user_type: 0,
                            email: props?.userDetails?.email
                     },
                     callback: (res, data) => {
                            if(res==true && _.get(data, 'details.id')){
                                   setusersDetails({..._.get(data, 'details')})
                                   setSeconds(60);
                                   seterrorOtp(false)
                                   setOtp(['', '', '', '', '', ''])

                            }
                            
                           

                     }
              })
       }

       const  accountsVerification = () => {
              setDotPulseBtnLoader(true);
             
              props.accountsVerify({
                     body: {
                            id: props?.userDetails?.account?.id,
                            token: otp.join('')
                     },
                     callback: (res, data) => {
                            setDotPulseBtnLoader(false);
                            if(res==true && _.get(data, 'success')==true){
                                   setsuccessModal(true)
                                   
                                   
                            }else if(_.get(data,'statusCode')==404){
seterrorOtp(true)
                            }
                            
                           

                     }
              })
       }
       const handlePaste = (event) => {
              
              event.preventDefault();
              const pastedData = event.clipboardData.getData('Text');
              if (pastedData.length === 6 ) {
                setOtp(pastedData.split('').slice(0, 6));
              }
            };

       
       return successModal==true? <SuccessModal onOpenLoginModal={()=>{
              props?.onOpenLoginModal()
       }} oncloseAllModal ={()=>props.oncloseAllModal()} userDetails={props?.userDetails} /> :<div >
              <Divider style={{ color: '#EAECF0', width: 1 }} />
              <div className='email-modal-des' style={{ padding: '24px', paddingTop: '0px' }}>
              Looks like this is your first time here. So let’s get you started by creating a new account!
              </div>
              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
                     <TextField
                            disabled={true}

                            value={props?.userDetails?.email}

                            inputProps={{
                                   className: 'email-input-mui',



                            }} required id="outlined-required" label="Email"
                            className={classes.root}
                            style={{ width: '100%' }}
                            helperText="We’ve sent you a verification code on your email"
                            // InputProps={{ endAdornment: <div className='support-text-email' style={{ cursor: 'pointer' }}>Change</div>, }}
                            FormHelperTextProps={{
                                   classes: {
                                          root: classes.helperText,
                                   },
                            }}
                     />
              </div>
              
              <div style={{ width: '100%', paddingLeft: '24px', paddingRight: '24px', paddingBottom: '24px' }}>
              <div className='email-modal-des' style={{ color:'#475467',fontWeight:500,marginBottom:'6px' }}>
              Verification Code
              </div>
                     
              <div className="otp-container">
      {otp.map((digit, index) => (
       <div key={index} style={{display:'flex'}}>
              {index==3 && <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginRight:'8px'}}> 
                     <div style={{width:'28px',height:'10px',backgroundColor:'var(--Gray-300, #D0D5DD)',alignItems:'center'}} />
                     
                     </div>}
               <input
          key={index}
          className={errorOtp==true?"error-otp-text": "otp-input"}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleChange(index, e.target.value)}
          onKeyUp={(e) => handleKeyUp(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={inputRefs.current[index]}
          onPaste={handlePaste}
          style={{
              marginRight:'8px'
          }}
        />
       </div>
      ))}
    </div>
    {errorOtp ==true &&<div className='error-otp-des' style={{marginTop:'6px' }}>
    Incorrect code! Please try again
              </div>}

              <div className='email-resend-otp-text' style={{paddingTop:'24px'}}>
              Didn’t receive your code? {seconds==0?<span 
              onClick={()=>{
accountsResendVerification()
              }}
              style={{textDecorationLine:'underline',cursor:'pointer'}}>Resend verification code</span>:
              <span style={{fontSize:'1rem'}}>Resend code in {`(${seconds})`}</span>}
              </div>
              </div>
              
              
           
              <div>
                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '24px', paddingLeft: '24px', paddingRight: '24px',marginBottom:'24px' }}>
                            <AntButton
                            disabled={true}
                                   className='ant-back-button'
                                   onClick={() => {
                                         
                                          setDotPulseBtnLoader(false);
                                   }}

                                   style={{
                                          width: "50%",
                                          height: "44px",
                                          // backgroundColor: "#D0D5DD",
                                          color: "#D0D5DD",
                                          borderRadius: '12px',
                                          fontWeight: 600,
                                          fontSize: '1rem',
                                          marginRight: '12px'

                                   }}
                            ><TbArrowBackUp size={22} color={"#D0D5DD"} style={{ marginTop: -3 }} /> back</AntButton>
                            { !dotPulseBtnLoader && <Button
                                  disabled={_.size(_.compact(otp))==6?false:true}
                                   style={{
                                          width: "50%",
                                          height: "44px",
                                          backgroundColor: _.size(_.compact(otp))!=6? "#D0D5DD": "#FF692E",
                                          color: "#FFFFFF",
                                          borderRadius: '12px',
                                          fontWeight: 600,
                                          fontSize: '1rem',
                                          textTransform: 'none'


                                   }}
                                   onClick={() => {
                                          if(_.size(_.compact(otp))==6){
                                                 accountsVerification()
                                          }
                                         
                                          // setSeconds(60)
                                          // seterrorOtp(true)
                                          
                                   //       let userDetails ={
                                   //        email:props?.userDetails?.email,
                                   //        first_name:first_name,
                                   //        last_name:last_name,
                                   //        password:password
                                   //       }

                                   }}

                                   fullWidth

                            >
                                   Next
                            </Button>}

                            { dotPulseBtnLoader && <div style={{
                                          width: "50%",
                                          height: '44px'
                                   }} className='otp-loading-btn-container'>
                                          <div className='dot-pulse'></div>
                                          </div>
                                          }
                     </div>
              </div>


       </div>


}
const mapStateToProps = state => {
       return {


       }
}

const mapDispatchToProps = dispatch => ({

       accountsResendVerification: details => dispatch(global.redux.action.guest.accountsResendVerification(details)),
       accountsVerify: details => dispatch(global.redux.action.guest.accountsVerify(details)),


});
export default connect(mapStateToProps, mapDispatchToProps)(EmailOtpVerification)
