import React, {useEffect, useState} from 'react'
import _ from 'lodash';
import CommonFooter from '../../components/Footer/footer'
const NotFoundPage = (props) => {

    
    
  const render = () => {
  return (
        <div>
      <div className='container'>
        <h3 style={{display: "flex", justifyContent: "center"}}>Page not found</h3>
         <img src={require('../../assets/img/footballnotfound.png')}/>
         
      </div>
      <CommonFooter/>
      </div>
      
  )
  }
  return render();
}


export default NotFoundPage;