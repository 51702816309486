import React, { useState, useEffect } from 'react';
import GuestProfilesList from './guest-profile-list'
import QuestionList from '../questions/question-list'
import ClauseOperation from '../questions/clauses/clause'
import PaymentOperation from '../payment/pay-order'


import _ from 'lodash';
import "./style.css";
import { useSearchParams } from 'react-router-dom';
import AuthFooter from '../../components/auth-footer';
const StepConfiguration = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

 const [screenType, setscreenType] = useState(1)
 const [orderCreateData, setorderCreateData] = useState({
  experience:searchParams.get("id"),
  registration_info: [],
  clauses:  [],
  profile: '',
  profile_details:{}
})

useEffect(() => {
  
  
  localStorage.setItem('discount_key', '');
                    localStorage.setItem('coup_code', '');
}, [])

  const render = () => {
    return (
      <div style={{display:'flex',flexDirection:'column'}}>
        <div>
{screenType==1 &&<GuestProfilesList defaultsetProfile={orderCreateData} orderCreateData={orderCreateData} {...props} screenType={screenType}  onChangeScreen={(type,details,profile)=>{
  setorderCreateData({
    ...orderCreateData,
    profile:details,
    profile_details:profile
  })
       setscreenType(type)

}} />}
{screenType==2 &&<QuestionList  orderCreateData={orderCreateData} {...props} screenType={screenType} onChangeScreen={(type,details)=>{
        setorderCreateData({
          ...orderCreateData,
          registration_info:details
        })
       setscreenType(type)

}} />}
{screenType==3 &&<ClauseOperation defaultsetClause={orderCreateData} orderCreateData={orderCreateData} {...props} screenType={screenType} onChangeScreen={(type,details)=>{
        setorderCreateData({
          ...orderCreateData,
          clauses:details
        })
       setscreenType(type)

}} />}
{screenType==4 &&<PaymentOperation orderCreateData={orderCreateData} {...props} screenType={screenType} onChangeScreen={(type)=>{
       setscreenType(type)

}} />}</div>
<div><AuthFooter /></div>


      </div>
    )
  };
  return render();

}

export default StepConfiguration;